import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { fetchChatRoomList } from '@/api/chat/getChat';
import BasicButton from '@/components/Common/Button/BasicButton';
import PurchaseAccompanyingServiceChatListSkeleton from '@/components/Common/Skeleton/PurchaseAccompanyingServiceChatListSkeleton';
import { RadioCheckIcon } from '@/components/Icon';
import RadioIcon from '@/components/Icon/RadioIcon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface SelectVehicleFromChatListProps {
  updateStep: (step: number) => void;
  formData: PurchaseAccompanyingServicesForm;
  updateFormData: (formData: PurchaseAccompanyingServicesForm) => void;
  isTransferAgencyService: boolean;
  chatList?: ChattingListResponse[];
}

const SelectVehicleFromChatList = ({
  updateStep,
  formData,
  updateFormData,
  isTransferAgencyService,
  chatList,
}: SelectVehicleFromChatListProps) => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const [selectedChatRoomId, setSelectedChatRoomId] = useState<number | null>(formData.chatRoomId || null);
  const [chattingList, setChattingList] = useState<ChattingListResponse[]>(chatList || []);

  const { data, isLoading, isFetching } = useQuery<ChattingListResponse[]>(
    ['get-chat-room-list'],
    () => fetchChatRoomList(),
    {
      onSuccess: (res) => {
        setChattingList(res);
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !isTransferAgencyService,
    },
  );

  const getMessageText = (message: ChattingListResponse) => {
    const lastMessage = JSON.parse(message?.lastMessage);
    return !!message && lastMessage?.text
      ? lastMessage?.text
      : !!message && lastMessage?.images
      ? '사진을 보냈습니다.'
      : '';
  };

  const handleChangeRadio = (item: ChattingListResponse) => {
    setSelectedChatRoomId(item?.chatRoomId);
  };

  const handleClickSelectVehicle = () => {
    updateStep(1);
    const selectedItem = chattingList?.find((data: ChattingListResponse) => data?.chatRoomId === selectedChatRoomId);
    updateFormData({
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      productId: selectedItem?.productId,
      truckName: selectedItem?.truckName,
      chatRoomId: selectedItem?.chatRoomId,
    });
  };

  const handleClickGoToProductList = () => {
    navigate('/products');
  };

  const getGuideInfo = () => {
    if (isTransferAgencyService) {
      return {
        title: '서류 이전 대행 서비스를 신청할 차량을 선택해주세요.',
        content: '채팅 목록에서 서류 이전 대행 서비스를 이용하기 위한 차량을 선택해주세요.',
      };
    } else {
      return {
        title: '구매를 원하는 차량을 선택해주세요.',
        content: '채팅 목록에서 구매 대행 서비스를 이용하기 위한 차량을 선택해주세요.',
      };
    }
  };

  const handleClickSkip = () => {
    updateStep(1);
    setSelectedChatRoomId(null);

    updateFormData({
      name: formData.name,
      phoneNumber: formData.phoneNumber,
    });
  };

  return (
    <>
      <div className="bg-blue-0 rounded-[10px] px-4 py-[19px] mx-4">
        <p className="font-bold text-gray-8">{getGuideInfo().title}</p>
        <div className="text-[14px] mt-4 leading-[18px]">{getGuideInfo().content}</div>
      </div>
      <div className="px-4 py-6">
        {isLoading || isFetching ? (
          <PurchaseAccompanyingServiceChatListSkeleton></PurchaseAccompanyingServiceChatListSkeleton>
        ) : chattingList && chattingList?.length > 0 ? (
          <RadioGroup
            value={selectedChatRoomId || ''}
            name={`radio-buttons-group`}
            sx={{ display: 'flex', gap: '22px' }}
          >
            {chattingList.map((item: ChattingListResponse, index: number) => (
              <FormControlLabel
                key={index}
                value={item?.chatRoomId}
                control={
                  <Radio
                    icon={<RadioIcon color={colors.gray[3]} />}
                    checkedIcon={<RadioCheckIcon color={colors.primary} />}
                    sx={{
                      borderWidth: '4px',
                      fontSize: '14px',
                      borderRadius: '10px',
                      paddingRight: '6px',
                    }}
                  />
                }
                checked={item?.chatRoomId === selectedChatRoomId}
                label={
                  <div className="flex justify-between items-center">
                    <dl className="flex flex-col justify-center mx-3 flex-1">
                      <dt className="leading-3 flex flex-col justify-center">
                        <div className="font-semibold py-1 text-gray-8 mb-1 text-[14px]">{item.truckName}</div>
                        <div className="text-gray-7 font-medium text-[12px] mb-[2px]">
                          {item.memberName} [{item.truckNumber}]
                        </div>
                      </dt>
                      <dd
                        className={`flex-1 max-w-[500px] text-gray-6 font-medium text-[12px] line-clamp-2 text-ellipsis break-all pr-8 leading-[16px]`}
                      >
                        {getMessageText(item)}
                      </dd>
                    </dl>
                    <div className="rounded-[12px] w-12 h-12 min-w-[48px] mx-2 my-1">
                      {item.productRepresentImageUrl && (
                        <img
                          src={item?.productRepresentImageUrl}
                          alt="profile-img"
                          className="rounded-lg w-full h-full object-cover"
                        />
                      )}
                    </div>
                  </div>
                }
                onChange={() => handleChangeRadio(item)}
                componentsProps={{
                  typography: {
                    sx: {
                      width: '100%',
                    },
                  },
                }}
              />
            ))}
          </RadioGroup>
        ) : (
          <>
            <div className="w-full h-full px-4 " style={{ height: `calc(100vh - 300px)` }}>
              <div className="flex flex-col justify-center items-center w-full h-full text-center">
                <p className="text-gray-7 text-[18px] mt-4 text-center mb-[30px]">채팅 내역이 없습니다.</p>
                <BasicButton
                  name="구매할 차량 둘러보기"
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  width={230}
                  height={52}
                  borderRadius={12}
                  fontWeight="semibold"
                  onClick={handleClickGoToProductList}
                ></BasicButton>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col fixed w-full mx-auto my-0 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px]">
        <div className="flex gap-2 bg-white px-3 pt-2 pb-4">
          <BasicButton
            name="건너뛰기"
            bgColor={colors.gray[0]}
            borderColor={colors.gray[3]}
            textColor={colors.gray[6]}
            fontSize={16}
            height={52}
            fontWeight="bold"
            onClick={() => handleClickSkip()}
          ></BasicButton>
          <BasicButton
            name="차량 선택하기"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={52}
            fontWeight="bold"
            isDisabled={!selectedChatRoomId}
            onClick={handleClickSelectVehicle}
          ></BasicButton>
        </div>
      </div>
    </>
  );
};

export default SelectVehicleFromChatList;
