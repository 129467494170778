import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Carousel } from 'react-responsive-carousel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import OutlinedBadge from '@/components/Common/Badge/OutlinedBadge';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import ImageModal from '@/components/Common/ImageModal';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import TooltipComponent from '@/components/Common/Tooltip/TooltipComponent';
import Error from '@/components/Error/Error';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import {
  ChevronRightIcon,
  DocumentTextSolidIcon,
  EnlargementOutlineIcon,
  ErrorCircleIcon,
  HomeOutlineIcon,
} from '@/components/Icon';
import { MoreMenuData } from '@/components/Menu/MoreMenu';
import ProductDetailFooter from '@/components/Products/Purchase/ProductDetailFooter';
import ProductTab from '@/components/Products/Purchase/ProductTab';
import { PRODUCT_DETAIL } from '@/const/banner';
import { colors } from '@/const/colors';
import { ALREADY_SALE_PRODUCTS, DELETE_PRODUCTS } from '@/const/errorCode';
import {
  SALES_TYPE_ASSURANCE,
  SALES_TYPE_CONSIGNMENT,
  SALES_TYPE_NORMAL,
  SALES_TYPE_THIRD_PARTY_DEALER,
} from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import NotFoundPage from '@/pages/Error/NotFoundPage';
import { formatPrice, getFormatDateOnlyNum } from '@/utils/common';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

type LiceseProps = {
  truckNumber: string | undefined;
};

const LicensePlate = ({ truckNumber }: LiceseProps) => {
  return (
    <div className="bg-white rounded-lg m-5">
      <div className="bg-yellow rounded-lg border-2 border-gray-7 p-3 flex items-center justify-center px-0">
        <input
          type="text"
          maxLength={9}
          className="license-plate-input bg-transparent border-none focus:outline-none text-[26px] xxs:text-[22px] font-semibold justify-center text-center w-[200px] text-gray-8"
          value={truckNumber}
          readOnly
        ></input>
      </div>
    </div>
  );
};

const ProductDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { member } = useMemberContext();
  const isRegister = location.state?.isRegisterBtn;
  const isPublicDetail = location.state?.isPublicDetail == null ? true : location.state?.isPublicDetail;
  const [isSeller, setIsSeller] = useState<boolean>();
  const [isShowBasicPopup, setIsShowBasicPopup] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [imageModalIndex, setImageModalIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useState<ProductSearchParams>();
  const productImages: string[] = [];

  const [capitalBanner, setCapitalBanner] = useState<Banner>();

  const [moreMenuData, setMoreMenuData] = useState<MoreMenuData>();
  const [isShowErrorPage, setIsShowErrorPage] = useState(false);
  const [isShowErrorPageForDeleteOrCompletedProduct, setIsShowErrorPageForDeleteOrCompletedProduct] = useState(false);
  const { showToast } = useToastContext();
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    componentContent: null,
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });
  const [isScrolling, setIsScrolling] = useState(false);

  const productDetailPath = isPublicDetail ? '/api/v1/public/products/' : '/api/v1/products/';

  const fetchProductDetail = async (id?: string, memberId?: number) => {
    const queryParams = new URLSearchParams();
    if (memberId) {
      queryParams.append('memberId', String(memberId));
    }

    const response = await apiManager.get(`${productDetailPath}${id}?` + queryParams.toString());
    return response.data;
  };

  const { data, error, isLoading } = useQuery(
    ['productDetail', id, member?.id],
    () => fetchProductDetail(id, member?.id),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (data) {
      setMoreMenuData({
        id: data.id,
        status: data.status.code,
      });

      const tons = data?.tons;
      const year = getFormatDateOnlyNum(data?.firstRegistrationDate, ['year']);
      const model = data?.model;
      const loaded = data?.loaded;
      const loadedInnerLength = data?.loadedInnerLength;
      const axis = data?.axis;
      setSearchParams({ tons, year, model, loaded, loadedInnerLength, axis });
      setIsSeller(data?.sellerId === member?.id);
    }

    if (error) {
      const errorResponse = error as ErrorResponse;
      if (errorResponse.code === ALREADY_SALE_PRODUCTS || errorResponse.code === DELETE_PRODUCTS) {
        setIsShowErrorPage(false);
        setIsShowErrorPageForDeleteOrCompletedProduct(true);
      } else {
        setIsShowErrorPage(true);
        setIsShowErrorPageForDeleteOrCompletedProduct(false);
      }
    }
  }, [data, error]);

  const updateHistory = (path: string) => {
    window.history.replaceState(null, '', path);
  };

  useEffect(() => {
    if (data) {
      if (isRegister) {
        showToast('차량 등록이 완료되었어요.', 'success', 'bottom');

        setTimeout(() => {
          setIsShowBasicPopup(true);
          setPopupInfo({
            isShow: true,
            title: '번호판도 함께<br/>판매하시겠어요?',
            textContent: '',
            componentContent: <LicensePlate truckNumber={data?.truckNumber}></LicensePlate>,
            textRightBtn: '판매할래요',
            onClickRightBtn: onClickSaleLicense,
            textLeftBtn: '아니요',
            onClickLeftBtn: () => {
              setIsShowBasicPopup(false);
            },
          });
        }, 1000);
        queryClient.invalidateQueries({ queryKey: ['product-search'] });
      } else if (isRegister === false) {
        showToast('차량 수정이 완료되었어요.', 'success', 'bottom');
        queryClient.invalidateQueries({ queryKey: ['product-search'] });
      }
    }
  }, [data]);

  useEffect(() => {
    apiManager
      .get(`/api/v1/public/banners?bannerLocation=${PRODUCT_DETAIL}`)
      .then((response) => {
        if (response && response.data) {
          const responseData: Banner[] = response.data;
          setCapitalBanner(responseData[0]);
        }
      })
      .catch((error) => {
        console.log('API 호출 에러');
      });

    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const onClickSaleLicense = () => {
    if (data) {
      setIsShowBasicPopup(false);
      updateHistory(`/products/${data.id}`);
      navigate('/license/form', {
        state: { isModify: false, licenseInfo: { year: data?.year, tons: data?.tons, productId: data.id } },
      });
    }
  };

  if (data?.productsImage) {
    const images = data.productsImage;

    const imageFields = [
      'frontSideImageUrl',
      'frontImageUrl',
      'backSideImageUrl',
      'backImageUrl',
      'tireImageUrl',
      'engineImageUrl',
      'insideImageUrl',
      'dashboardImageUrl',
      'sheetImageUrl',
    ];

    imageFields.forEach((field) => {
      if (images[field] && images[field] !== '') {
        productImages.push(images[field]);
      }
    });

    if (images.optionImageUrl) {
      images.optionImageUrl.forEach((item: any) => {
        if (item !== '') {
          productImages.push(item);
        }
      });
    }
  }

  const onClickImage = (index: number) => {
    setOpenImageModal(true);
    setImageModalIndex(index);
  };

  const menuHeaderIcons: MenuHeaderIcon[] = [
    { icon: <HomeOutlineIcon color={colors.gray[8]}></HomeOutlineIcon>, onClickIcon: () => navigate('/') },
  ];

  const getBadgeColor = (type: string) => {
    switch (type) {
      case SALES_TYPE_NORMAL:
      case SALES_TYPE_CONSIGNMENT:
      case SALES_TYPE_THIRD_PARTY_DEALER:
        return 'primary';
      case SALES_TYPE_ASSURANCE:
        return 'green';
      default:
        return '';
    }
  };

  const getTooltipText = (type: string) => {
    switch (type) {
      case 'NORMAL':
        return '개인 차주가 판매 중인 차량입니다.';
      case SALES_TYPE_ASSURANCE:
        return '직트럭에서 점검 및 수리 후 상품화가 완료된 차량입니다.';
      case SALES_TYPE_CONSIGNMENT:
        return '판매자 요청에 의해 직트럭이 위탁 대행 중인 차량입니다.';
      case SALES_TYPE_THIRD_PARTY_DEALER:
        return '타 매매상사의 딜러가 판매 중인 차량입니다.';
      default:
        return '';
    }
  };

  const handleClickTransferAgencyService = () => {
    navigate('/transfer-agency-service');
  };

  return (
    <>
      {data && (
        <>
          <MenuHeader
            title={data.truckNumber || ''}
            icons={menuHeaderIcons}
            moreMenuIcon={isSeller}
            moreMenuData={moreMenuData}
            onClickPrevBtn={() => navigate(-1)}
          />
          <section className="flex flex-col justify-between w-full pb-28 pt-[60px]">
            <div className="relative">
              <Carousel
                showArrows={false}
                centerMode={false}
                centerSlidePercentage={100}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                infiniteLoop={true}
                emulateTouch={true}
              >
                {productImages.map((imageUrl, index) => (
                  <div
                    className="flex w-full h-[16.875rem]"
                    key={imageUrl}
                    onClick={() => {
                      onClickImage(index);
                    }}
                  >
                    <img src={imageUrl as string} className="w-full h-full object-cover" alt="product_img"></img>
                  </div>
                ))}
              </Carousel>
              <div
                className="absolute bottom-0 left-0 w-full h-[50px] p-3 bg-gradient-to-t from-black to-black-opacity-70"
                onClick={() => {
                  onClickImage(0);
                }}
              >
                <div className="absolute bottom-0 right-0 p-3 z-30">
                  <EnlargementOutlineIcon color={colors.gray[0]}></EnlargementOutlineIcon>
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className="relative flex justify-between items-start">
                <span className="font-semibold text-xl align-middle">{data.truckName}</span>
                <TooltipComponent title={getTooltipText(data?.salesType?.code || '')} content="" position="right" />
              </div>
              <span className="text-gray-6 pt-2">매물번호 {data.productsNumber}</span>
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-end">
                  <p className="font-semibold text-xl">{formatPrice(data.price?.toString())}</p>
                </div>

                {data?.salesType && (
                  <OutlinedBadge color={getBadgeColor(data?.salesType?.code)} text={data?.salesType?.desc} />
                )}
              </div>
              <p className="text-sm text-gray-7">조회수 {data.hit}</p>
            </div>
            <div className="px-4 pt-4 border-t-8 border-gray-50">
              <h4 className="text-lg font-semibold inline-block align-middle">시세 정보</h4>
              <TooltipComponent
                title=""
                content="* 톤수별 평균 키로수 (영업용 기준) <br/>1. 14~25톤 (1년 기준 10만내외) <br/>2. 4.5톤 (1년 기준 8만km내외) <br/>3. 2.5~3.5톤 (1년 기준 6만km 내외) <br/> 4. 1톤 (1년 기준 4만km내외)"
                alignmentTipbox="left"
              />
              <CarPriceTrendInfo
                priceSearchParams={searchParams}
                showPriceComparison={true}
                price={data.price?.toString()}
              />
              <div className="text-sm text-gray-7 mb-6">
                * 차량 주행거리, 차량 상태, 차량 옵션 등으로 시세 차이가 발생할 수 있습니다.
              </div>
            </div>
            <div className="pt-6 border-t-8 border-gray-1">
              <section className="px-4 border-b-8 border-gray-1 pb-6">
                <h1 className="font-semibold text-gray-8 text-lg">금융 상품 이용이 필요하신가요?</h1>
                <div className="flex w-full h-full my-2">
                  <img
                    className="cursor-pointer w-full object-cover rounded-lg"
                    src={capitalBanner?.contents}
                    alt="capital_banner"
                    onClick={() => window.open(capitalBanner?.link)}
                  ></img>
                </div>
                <span className="text-sm text-gray-7">우리금융캐피탈을 이용하시면 금리가 낮아져요!</span>
              </section>
              <section
                className="flex justify-between items-center px-4 py-6 border-b-8 border-gray-1 cursor-pointer"
                onClick={handleClickTransferAgencyService}
              >
                <div className="flex gap-[10px]">
                  <div className="flex justify-center items-center bg-blue-8 rounded-[12px] w-10 h-10">
                    <DocumentTextSolidIcon color={colors.gray[0]}></DocumentTextSolidIcon>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-gray-8 font-semibold">서류 이전 대행 서비스</div>
                    <div className="text-gray-7 text-[12px]">막막한 차량 이전 절차를 한번에 해결하세요</div>
                  </div>
                </div>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </section>
              <ProductTab product={data} />
            </div>
            <ImageModal
              isShow={openImageModal}
              setIsShow={setOpenImageModal}
              imageList={productImages}
              imageIndex={imageModalIndex}
            />
          </section>
          {!isSeller && (
            <div className="flex items-center justify-center fixed w-full mx-auto my-0 bottom-0 left-0 right-0 h-16 max-w-[720px] min-w-[280px] z-40">
              <ProductDetailFooter product={data} isShowBubble={!isScrolling} />
            </div>
          )}
        </>
      )}
      {isShowErrorPage && <NotFoundPage />}
      {isShowErrorPageForDeleteOrCompletedProduct && (
        <div className="w-full flex flex-col justify-center items-center h-screen">
          <Error
            icon={<ErrorCircleIcon color={colors.gray[5]} width={144} height={144}></ErrorCircleIcon>}
            title="삭제된 차량입니다"
            description="판매되었거나</br>삭제된 차량입니다."
          />
        </div>
      )}
      <BasicPopup
        isShow={isShowBasicPopup}
        title={popupInfo?.title}
        textContent={popupInfo?.textContent}
        componentContent={popupInfo?.componentContent}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
      />
    </>
  );
};

export default ProductDetail;
