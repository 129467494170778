import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

import { getLicenseList } from '@/api/public';
import SearchBox from '@/components/Common/Input/SearchBox';
import LicenseListSkeleton from '@/components/Common/Skeleton/LicenseListSkeleton';
import Footer from '@/components/Footer';
import MenuHeader from '@/components/Header/MenuHeader';
import { BuyLicenseBoldIcon, ChevronRightIcon, EmptyLicensePlateIcon, SellLicenseBoldIcon } from '@/components/Icon';
import ResetIcon from '@/components/Icon/ResetIcon';
import LicenseItemView from '@/components/License/LicenseItemView';
import { colors } from '@/const/colors';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useSearchLicenseQuery from '@/hooks/query/useSearchLicenseQuery';
import { licenseSearchParamsAtom } from '@/store/license';
import { compareObjects, getCurrentYear } from '@/utils/common';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const License = () => {
  const currentYear = getCurrentYear();
  const navigate = useNavigate();
  const location = useLocation();
  const { licenseEnum } = useLicenseEnumContext();
  const { member } = useMemberContext();
  const [searchParams] = useAtom(licenseSearchParamsAtom);
  const setSearchParams = useSetAtom(licenseSearchParamsAtom);
  const [initialSearchParams, setInitialSearchParams] = useState<LicenseSearch>({
    useClassification: { code: '', desc: '' },
    minYear: '2000',
    maxYear: (currentYear + 1).toString(),
    minTons: '1',
    maxTons: '27',
    licenseSalesType: '',
    licenseType: { code: '', desc: '' },
    page: 1,
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (member) {
      setSearchParams((prev) => ({ ...prev, memberId: member.id }));
      setInitialSearchParams((prev) => ({ ...prev, memberId: member.id }));
    }
  }, [member]);

  const getLicenseSearchParams = (page: number): URLSearchParams => {
    const params = new URLSearchParams({ page: page.toString() });
    if (searchParams) {
      if (searchParams.minTons) {
        params.set('minTons', searchParams.minTons.toString());
      }
      if (searchParams.maxTons) {
        params.set('maxTons', searchParams.maxTons.toString());
      }
      if (searchParams.minYear) {
        params.set('minYear', searchParams.minYear.toString());
      }
      if (searchParams.maxYear) {
        params.set('maxYear', searchParams.maxYear.toString());
      }
      if (searchParams.licenseSalesType) {
        params.set('licenseSalesType', searchParams.licenseSalesType);
      }
      if (searchParams.licenseType?.code) {
        params.set('licenseType', searchParams.licenseType?.code);
      }
      if (searchParams.useClassification?.code) {
        params.set('useClassification', searchParams.useClassification?.code);
      }
      if (searchParams.memberId) {
        params.set('memberId', searchParams.memberId.toString());
      }
    }
    return params;
  };

  const getLicenseData = async (page: number) => {
    try {
      const response = await getLicenseList(getLicenseSearchParams(page));
      const data: Job[] = response.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { licenseList, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useSearchLicenseQuery({
    rowsPerPage: 10,
    queryFn: ({ queryKey, pageParam = 1 }) => {
      return getLicenseData(pageParam);
    },
    searchParams,
  });

  const handleBackNavigation = () => {
    if (location.state) {
      navigate('/more');
    } else {
      navigate(-1);
    }
  };

  const navigateToPurchase = () => {
    navigate('/license/purchase');
  };

  const navigateToSales = () => {
    navigate('/license/form', { state: licenseEnum });
  };

  const onClickSearch = () => {
    navigate('/license/search');
  };

  const isShowFilterResetBtn = () => {
    return !compareObjects(searchParams, initialSearchParams);
  };

  const onClickFilterClearBtn = () => {
    if (member) {
      setSearchParams({
        useClassification: { code: '', desc: '' },
        minYear: '2000',
        maxYear: (currentYear + 1).toString(),
        minTons: '1',
        maxTons: '27',
        licenseSalesType: '',
        licenseType: { code: '', desc: '' },
        page: 1,
        memberId: member.id,
      });
    } else {
      setSearchParams({
        useClassification: { code: '', desc: '' },
        minYear: '2000',
        maxYear: (currentYear + 1).toString(),
        minTons: '1',
        maxTons: '27',
        licenseSalesType: '',
        licenseType: { code: '', desc: '' },
        page: 1,
      });
    }
  };

  return (
    <>
      <MenuHeader title="번호판 거래" onClickPrevBtn={handleBackNavigation}></MenuHeader>
      <div className="w-full flex flex-col pb-28 pt-[116px]">
        <div className="fixed items-center flex z-30 top-[55px] mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] bg-white">
          <SearchBox name="truckName" placeholder="번호판 검색" value="" onClick={onClickSearch}></SearchBox>
        </div>

        {isShowFilterResetBtn() ? (
          <div className="flex items-center justify-end mr-4">
            <div className="flex items-center justify-center cursor-pointer" onClick={onClickFilterClearBtn}>
              <ResetIcon color={colors.gray[8]}></ResetIcon>
              <span className="text-gray-8 text-sm ml-1">필터해제</span>
            </div>
          </div>
        ) : null}

        <section className="flex flex-col gap-[10px] items-center p-4">
          <div
            className="flex justify-between items-center bg-blue-0 rounded-[12px] p-[18px] xxs:p-3 w-full"
            onClick={navigateToPurchase}
          >
            <div className="flex">
              <BuyLicenseBoldIcon color={colors.primary} width={40} height={40}></BuyLicenseBoldIcon>
              <div className="ml-3 xxs:ml-2">
                <p className="text-primary font-bold xxs:text-[15px]">번호판 구매 문의 남기기</p>
                <p className="text-gray-8 font-medium text-[13px] xxs:text-xs">찾고 있는 번호판이 없나요?</p>
              </div>
            </div>
            <ChevronRightIcon color={colors.primary}></ChevronRightIcon>
          </div>

          <div
            className="flex justify-between items-center bg-blue-0 rounded-[12px] p-[18px] xxs:p-[12px] w-full"
            onClick={navigateToSales}
          >
            <div className="flex">
              <SellLicenseBoldIcon color={colors.primary} width={40} height={40}></SellLicenseBoldIcon>
              <div className="ml-3 xxs:ml-2">
                <p className="text-primary font-bold xxs:text-[15px">번호판 판매하러 가기</p>
                <p className="text-gray-8 font-medium text-[13px] xxs:text-xs">직트럭에서 번호판을 판매해보세요.</p>
              </div>
            </div>
            <ChevronRightIcon color={colors.primary}></ChevronRightIcon>
          </div>
        </section>
        {isFetching || isLoading ? (
          <LicenseListSkeleton></LicenseListSkeleton>
        ) : licenseList?.length === 0 ? (
          <div className="flex flex-col justify-center items-center w-full min-h-[calc(100vh-474px)]">
            <EmptyLicensePlateIcon color={colors.gray[4]} width={120} height={120}></EmptyLicensePlateIcon>
            <span className="text-gray-7 text-lg mt-[30px] text-center text-base">
              판매 중인 번호판이 없습니다.
              <br /> 필터를 다시 설정해주세요.
            </span>
          </div>
        ) : (
          <section className="flex flex-col gap-3 pt-3 w-full px-4 pb-24">
            {licenseList?.map((item, index) => (
              <div key={index}>
                <LicenseItemView data={item} index={index} isShowPurchaseButton></LicenseItemView>
              </div>
            ))}
            <div
              ref={ref}
              className={`h-40 flex flex-col justify-center items-center pb-[76px] ${hasNextPage ? '' : 'hidden'}`}
            >
              <MoreVertIcon sx={{ fontSize: 80, color: colors.gray[2] }}></MoreVertIcon>
            </div>
          </section>
        )}
        <Footer isMain></Footer>
      </div>
    </>
  );
};

export default License;
