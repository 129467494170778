import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import TextInputLabelUp from '../Common/Input/TextInputLabelUp';
import apiManager from '@/api/AxiosInstance';
import MenuHeader from '@/components/Header/MenuHeader';
import { colors } from '@/const/colors';
import {
  NAME_VALIDATION_LENGTH_MESSAGE,
  NAME_VALIDATION_MESSAGE,
  PASSWORD_NOT_MATCH_MESSAGE,
  PASSWORD_VALIDATION_MESSAGE,
} from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useSetMemberData from '@/hooks/useSetMemberData';
import { validateName, validatePassword } from '@/utils/validation';

interface SignUpProps {
  memberInfo: MemberInfo;
  updateMemberInfo: (data: MemberInfo) => void;
  isFirst: boolean;
  setIsFirst: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserRegistrationForm: React.FC<SignUpProps> = ({ memberInfo, updateMemberInfo, setIsFirst }) => {
  const navigate = useNavigate();
  const setMemberData = useSetMemberData('');
  const [nameMessage, setNameMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const { showToast } = useToastContext();
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmedPasswordMessage, setConfirmedPasswordMessage] = useState('');
  const [errorConfirmedPassword, setErrorConfirmedPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [nameInput, setNameInput] = useState<string>('');
  const [passwordInput, setPasswordInput] = useState<string>();

  useEffect(() => {
    updateMemberInfo({ ...memberInfo, name: nameInput });
  }, [nameInput]);

  useEffect(() => {
    updateMemberInfo({ ...memberInfo, password: passwordInput });
  }, [passwordInput]);

  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNameInput(value);

      if (validateName(value) === false) {
        setNameMessage(NAME_VALIDATION_MESSAGE);
        setErrorName(true);
      } else if (value.length > 20) {
        setNameMessage(NAME_VALIDATION_LENGTH_MESSAGE);
        setErrorName(true);
      } else {
        setNameMessage('');
        setErrorName(false);
      }
    },
    [confirmedPassword],
  );

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== null && value !== undefined && value.length > 0) {
      if (validatePassword(value) === false) {
        setPasswordMessage(PASSWORD_VALIDATION_MESSAGE);
        setErrorPassword(true);
      } else {
        setPasswordMessage('');
        setErrorPassword(false);
      }
    }

    if (confirmedPassword === '') {
      setPasswordInput(value);
      return;
    } else {
      if (confirmedPassword === value) {
        setConfirmedPasswordMessage('');
        setErrorConfirmedPassword(false);
      } else {
        setConfirmedPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorConfirmedPassword(true);
      }
    }
    setPasswordInput(value);
  };

  const onChangeConfirmedPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setConfirmedPassword(value);
      if (passwordInput === value) {
        setConfirmedPasswordMessage('');
        setErrorConfirmedPassword(false);
      } else {
        setConfirmedPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorConfirmedPassword(true);
      }
    },
    [passwordInput],
  );

  const isSuccessSignUp = useMemo(() => {
    return nameInput && passwordInput && confirmedPassword && !errorName && !errorPassword && !errorConfirmedPassword;
  }, [nameInput, passwordInput, confirmedPassword]);

  const signUpMember = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const { marketing, phoneNumber } = memberInfo;
    const requestData = { marketing, phoneNumber, name: nameInput, password: passwordInput };
    apiManager
      .post('/api/v1/members/sign-up', requestData, {
        withCredentials: true,
      })
      .then((response) => {
        const { token } = response.data;
        setMemberData(token);
        navigate('/');
      })
      .catch(() => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goPrev = () => {
    setIsFirst(true);
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full p-4">
        <MenuHeader
          title="전화번호로 회원가입"
          onClickPrevBtn={() => {
            goPrev();
          }}
        ></MenuHeader>
        <div className="pb-24">
          <section>
            <p className="text-primary text-base pt-6 mt-6">마지막 단계 입니다!</p>
            <p className="text-[22px] text-gray-8 font-bold mb-[30px] mt-2">정보를 입력해주세요</p>

            <div className="flex flex-col gap-[30px]">
              <TextInputLabelUp
                name="name"
                label="이름"
                placeholder="이름을 입력해주세요."
                value={nameInput || ''}
                onChange={onChangeName}
                error={errorName}
                errorMsg={nameMessage}
                inputMode="text"
                type="text"
              ></TextInputLabelUp>

              <TextInputLabelUp
                name="password"
                label="비밀번호"
                placeholder="영문, 숫자, 특수문자 조합 8~20자리"
                value={passwordInput || ''}
                onChange={onChangePassword}
                error={errorPassword}
                errorMsg={passwordMessage}
                type="password"
              ></TextInputLabelUp>

              <TextInputLabelUp
                name="confirmedPassword"
                label="비밀번호 확인"
                placeholder="비밀번호를 한번 더 입력해주세요."
                value={confirmedPassword}
                onChange={onChangeConfirmedPassword}
                error={errorConfirmedPassword}
                errorMsg={confirmedPasswordMessage}
                type="password"
              ></TextInputLabelUp>
            </div>
          </section>
        </div>
        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-2 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white">
          <BasicButton
            name="완료"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={52}
            borderRadius={12}
            fontWeight="semibold"
            onClick={() => signUpMember()}
            isDisabled={!isSuccessSignUp}
          ></BasicButton>
        </div>
      </div>
    </>
  );
};

export default UserRegistrationForm;
