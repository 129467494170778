import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import SwitchMultiButton from '../Common/Button/SwitchMultiButton';
import SimpleInput from '../Common/Input/SimpleInput';
import DownToUpDetailPopup from '../Common/Popup/DownToUpDetailPopup';
import TransportInfoSearchForm from './TransportInfoSearchForm';
import { PlusSmallIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { nonNumericOrDecimalPattern } from '@/const/regex';
import { DRIVE_HISTORY_TRANSPORT_COMPANY, DRIVE_HISTORY_TRANSPORT_ITEM } from '@/const/searchHistory';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatNumber } from '@/utils/common';
import LensIcon from '@mui/icons-material/Lens';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface TransPortFormProps {
  transportInfoData: TransportInfoData[] | null;
  updateDriveForm: (updateData: DriveHistoryForm) => void;
  onControlTutorial?: () => void;
}

const popupTitle: KeyValueListType = {
  transportCompany: '운송사',
  transportItem: '운송 품목',
};

const searchHistoryTypes: KeyValueListType = {
  transportCompany: DRIVE_HISTORY_TRANSPORT_COMPANY,
  transportItem: DRIVE_HISTORY_TRANSPORT_ITEM,
};

const TransPortForm = ({ transportInfoData, updateDriveForm, onControlTutorial }: TransPortFormProps) => {
  const costRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [isShowTransportInfoSearchPopup, setIsShowTransportInfoSearchPopup] = useState(false);
  const [transportFormPopupTitle, setTransportFormPopupTitle] = useState('');
  const [searchHistoryType, setSearchHistoryType] = useState('');
  const [transportIndex, setTransportIndex] = useState<number>(0);
  const [inputValue, setInputValue] = useState('');

  const [transportForm, setTransportForm] = useState<TransportInfoData[]>(
    transportInfoData
      ? transportInfoData
      : [
          {
            isCancel: false,
            cancelCost: null,
            receivedCost: false,
            transportCompany: '',
            transportCost: null,
            transportItem: null,
          },
        ],
  );

  const { showToast } = useToastContext();

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    const transportIndexValue = index == null ? transportIndex : index;
    const { name, value } = e.target;
    const convertedValue =
      name.startsWith('transportCost') || name.startsWith('cancelCost')
        ? String(value).replace(nonNumericOrDecimalPattern, '')
        : value;

    const convertedName = name.startsWith('transportCost')
      ? 'transportCost'
      : name.startsWith('cancelCost')
      ? 'cancelCost'
      : name;

    const updatedTransportForm = transportForm.map((item, i) => {
      if (i === transportIndexValue) {
        return {
          ...item,
          [convertedName]: convertedValue,
        };
      }
      return item;
    });

    setTransportForm(updatedTransportForm);

    if (!!searchHistoryType) {
      setInputValue(convertedValue);
    }
  };

  const onClickAddTransportForm = () => {
    if (transportForm.length > 20) {
      showToast('운송사 및 운송료는 최대 20개까지 입력 가능합니다.', 'error', 'bottom');
    } else {
      const newItem = { transportCost: null, transportCompany: '', transportItem: '', isCancel: false };
      setTransportForm([...transportForm, newItem]);
    }
  };

  const onClickDeleteTransportForm = (index: number) => {
    const updatedTransport = transportForm.filter((item, i) => i !== index);
    setTransportForm([...updatedTransport]);
  };

  const getPriceValue = (item: TransportInfoData) => {
    if (item.isCancel) {
      return item.cancelCost ? formatNumber(String(item?.cancelCost)) : '';
    } else {
      return item.transportCost ? formatNumber(String(item?.transportCost)) : '';
    }
  };

  const onChangeBtnValue = (index: number, isClickedRight: boolean) => {
    const updatedTransportForm = transportForm.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isCancel: isClickedRight,
        };
      }
      return item;
    });

    setTransportForm(updatedTransportForm);
  };

  useEffect(() => {
    updateDriveForm({ transportInfoData: transportForm });
  }, [transportForm]);

  const onClickType = () => {
    if (localStorage.getItem('driveTutorial') === 'true' && onControlTutorial) {
      onControlTutorial();

      setTimeout(() => {
        onChangeBtnValue(1, false);
      }, 1000);
    }
  };

  const scrollIntoView = (e?: React.FocusEvent<HTMLInputElement>) => {
    if (e) {
      const { name } = e.target;
      const index = parseInt(name.match(/\d+$/)?.[0] || '0', 10);

      if (name.startsWith('transportCost') || name.startsWith('cancelCost')) {
        setTimeout(() => {
          costRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }, 200);
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onClickIsReceivedReceipt = (index: number, value: boolean) => {
    const updatedTransportForm = transportForm.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          isReceivedReceipt: value,
        };
      }
      return data;
    });

    setTransportForm(updatedTransportForm);
  };

  const receivedReceipt = [
    { value: true, desc: '수령' },
    { value: false, desc: '미수령' },
  ];

  const onClosePopup = () => {
    setIsShowTransportInfoSearchPopup(false);
    clearTransportInfoSearchForm();
  };

  const onClickInput = (e: React.MouseEvent, index: number, inputValue?: string | null) => {
    let { name } = e.target as HTMLInputElement;
    setIsShowTransportInfoSearchPopup(true);
    setTransportFormPopupTitle(popupTitle[name]);
    setSearchHistoryType(searchHistoryTypes[name]);
    setTransportIndex(index);

    if (!!inputValue) {
      setInputValue(inputValue);
    }
  };

  const onClickTransportInfoHistory = (searchHistory: SearchHistory) => {
    let name = searchHistoryType === DRIVE_HISTORY_TRANSPORT_COMPANY ? 'transportCompany' : 'transportItem';
    const updatedTransportForm = transportForm.map((item, i) => {
      if (i === transportIndex) {
        return {
          ...item,
          [name]: searchHistory.keyword,
        };
      }
      return item;
    });

    setTransportForm(updatedTransportForm);
    setIsShowTransportInfoSearchPopup(false);
    clearTransportInfoSearchForm();
  };

  const clearTransportInfoSearchForm = () => {
    setTransportFormPopupTitle('');
    setSearchHistoryType('');
    setTransportIndex(0);
    setInputValue('');
  };

  return (
    <>
      {transportForm &&
        transportForm?.map((item, index) => (
          <div key={index}>
            <div className="text-base px-4 pb-2 border-t-8 border-gray-1 ">
              <dl className="border-b border-gray-3 py-5" onClick={onClickType}>
                <div className="flex justify-center items-center">
                  <dt className="min-w-[100px] text-gray-7">분류</dt>
                  <dd className="flex-1 flex items-center gap-4 text-gray-8 px-3">
                    <SwitchMultiButton
                      className="drive-form-type"
                      type="checkBox"
                      buttonInfo={[
                        {
                          title: '일반',
                          isClicked: !item.isCancel,
                          borderColor: 'border-primary',
                          textColor: colors.primary,
                          fontWeight: 'normal',
                          height: 35,
                          onClickBtn: () => onChangeBtnValue(index, false),
                        },
                        {
                          title: '회차',
                          isClicked: !!item.isCancel,
                          borderColor: 'border-green',
                          textColor: colors.green,
                          fontWeight: 'normal',
                          height: 35,
                          onClickBtn: () => onChangeBtnValue(index, true),
                        },
                      ]}
                    ></SwitchMultiButton>
                    <div
                      className={`cursor-pointer  ${index === 0 ? 'hidden' : ''}`}
                      onClick={() => onClickDeleteTransportForm(index)}
                    >
                      <XMarkIcon color={colors.gray[7]}></XMarkIcon>
                    </div>
                  </dd>
                </div>
              </dl>

              <dl className="flex justify-center items-center border-b border-gray-3 py-5">
                <dt className="min-w-[100px] text-gray-7">
                  운송사<span className="font-normal text-red ml-1">(필수)</span>
                </dt>
                <dd className="flex-1 text-gray-8 px-3" onClick={(e) => onClickInput(e, index, item?.transportCompany)}>
                  <SimpleInput
                    placeholder="운송사 입력"
                    name="transportCompany"
                    value={item?.transportCompany || ''}
                    readOnly
                  ></SimpleInput>
                </dd>
              </dl>
              <dl
                className={`flex justify-center items-center ${
                  index + 1 == transportForm.length ? 'border-b border-gray-3' : ''
                } py-5`}
              >
                <dt className="min-w-[100px] text-gray-7">
                  {item.isCancel ? '회차비' : '운송료'}
                  {item.isCancel ? '' : <span className="font-normal text-red ml-1">(필수)</span>}
                </dt>
                <dd className="flex-1 flex justify-between text-gray-8 px-3">
                  <SimpleInput
                    ref={(el) => (costRefs.current[index] = el)}
                    name={item.isCancel ? `cancelCost${index}` : `transportCost${index}`}
                    placeholder={`${item.isCancel ? '회차비' : '운송료'} 입력`}
                    value={getPriceValue(item)}
                    onChange={(e) => onChangeFormInput(e, index)}
                    onFocus={(e) => scrollIntoView(e)}
                    inputMode="numeric"
                  ></SimpleInput>
                  <span className="text-gray-8">원</span>
                </dd>
              </dl>

              {!item.isCancel && (
                <>
                  <dl
                    className={`flex justify-center items-center ${
                      index + 1 == transportForm.length ? 'border-b border-gray-3' : 'border-t border-gray'
                    } py-5`}
                  >
                    <dt className="min-w-[100px] text-gray-7">운송품목</dt>
                    <dd
                      className="flex-1 text-gray-8 px-3"
                      onClick={(e) => onClickInput(e, index, item?.transportItem)}
                    >
                      <SimpleInput
                        placeholder="품목 입력"
                        name="transportItem"
                        value={item?.transportItem ? item?.transportItem : ''}
                        onFocus={(e) => scrollIntoView(e)}
                        readOnly
                      ></SimpleInput>
                    </dd>
                  </dl>
                  <dl
                    className={`flex justify-center items-center ${
                      index + 1 == transportForm.length ? 'border-b border-gray-3' : 'border-t border-gray'
                    } py-5`}
                  >
                    <dt className="min-w-[100px] text-gray-7">인수증</dt>
                    <dd className="flex-1 flex justify-between text-gray-8 px-3">
                      <RadioGroup
                        value={item?.isReceivedReceipt == undefined ? '' : item.isReceivedReceipt}
                        name="radio-buttons-group"
                        row
                        sx={{ justifyContent: 'space-between' }}
                      >
                        {receivedReceipt?.map((data, key) => (
                          <FormControlLabel
                            key={key}
                            value={data.value}
                            control={
                              <Radio
                                icon={<LensIcon sx={{ color: colors.gray[3] }} />}
                                checkedIcon={<TripOriginOutlinedIcon sx={{ color: colors.primary }} />}
                                checked={item.isReceivedReceipt == data.value}
                              />
                            }
                            label={
                              <span style={{ fontSize: '16px', paddingRight: '20px', color: colors.gray[9] }}>
                                {data.desc}
                              </span>
                            }
                            onClick={() => onClickIsReceivedReceipt(index, data.value)}
                          />
                        ))}
                      </RadioGroup>
                    </dd>
                  </dl>
                </>
              )}
            </div>
            <div className={`px-4 ${index + 1 !== transportForm.length ? 'hidden' : ''}`}>
              <div
                className="flex items-center justify-center py-5 text-gray-6"
                onClick={() => onClickAddTransportForm()}
              >
                <PlusSmallIcon color={colors.gray[6]}></PlusSmallIcon>
                <span className="ml-2">운송사 및 운송료 추가</span>
              </div>
            </div>
          </div>
        ))}
      {isShowTransportInfoSearchPopup && (
        <DownToUpDetailPopup
          isShow={isShowTransportInfoSearchPopup}
          onClosePopup={onClosePopup}
          title={transportFormPopupTitle}
          isHeightFull
          isBackdrop={false}
        >
          <TransportInfoSearchForm
            placeholder={transportFormPopupTitle}
            searchHistoryType={searchHistoryType}
            onClickHistory={onClickTransportInfoHistory}
            onChangeFormInput={onChangeFormInput}
            value={inputValue}
            onEnterPress={onClosePopup}
          ></TransportInfoSearchForm>
        </DownToUpDetailPopup>
      )}
    </>
  );
};

export default TransPortForm;
