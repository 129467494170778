import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import BasicButton from '../Common/Button/BasicButton';
import TextInputLabelLeft from '../Common/Input/TextInputLabelLeft';
import BasicPopup from '../Common/Popup/BasicPopup';
import CalendarPopup from '../Common/Popup/CalendarPopup';
import { deleteRepairHistory, patchRepairHistory, postRepairHistory } from '@/api/public';
import { ChevronRightIcon, PlusSmallIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { nonNumericOrDecimalPattern } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { repairHistoryFormAtom } from '@/store/repairHistory';
import { formatNumber, formatYYYYMMDD } from '@/utils/common';

interface RepairHistoryFormProps {
  onClosePopup: () => void;
  selectedDate: Date;
  driveVehicleInfoId: number;
  data?: RepairHistory;
  refetch: () => void;
}

const RepairHistoryForm = ({
  onClosePopup,
  selectedDate,
  driveVehicleInfoId,
  data,
  refetch,
}: RepairHistoryFormProps) => {
  const { showToast } = useToastContext();
  const [repairHistoryForm, setRepairHistoryForm] = useState<RepairForm>({
    driveVehicleInfoId: driveVehicleInfoId,
    repairDay: data?.repairDay || formatYYYYMMDD(selectedDate),
    repairItems: data?.repairItems && data.repairItems.length > 0 ? data?.repairItems : [{ name: '', price: null }],
  });

  const nameRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);

  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);
  const setRepairForm = useSetAtom(repairHistoryFormAtom);

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = e.target;
    setRepairHistoryForm((prev) => {
      return {
        ...prev,
        repairItems: prev.repairItems.map((item, idx) => {
          const newValue = name === 'name' ? value : Number(String(value).replace(nonNumericOrDecimalPattern, ''));
          if (index === idx) {
            return {
              ...item,
              [name]: newValue,
            };
          }
          return item;
        }),
      };
    });
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setRepairHistoryForm({ ...repairHistoryForm, repairDay: date });
    }
  };

  const onClickAddRepairCost = () => {
    if (repairHistoryForm.repairItems.length > 19) {
      showToast('수리비는 최대 20개까지 입력 가능합니다.', 'error', 'bottom');
    } else {
      const newItem = { name: '', price: null };
      setRepairHistoryForm((prev) => {
        return {
          ...prev,
          repairItems: [...prev.repairItems, newItem],
        };
      });
    }
  };

  const postRepairHistoryMutation = useMutation((request: ReapirHistoryRequest) => postRepairHistory(request), {
    onSuccess: (response) => {
      onClosePopup();
      refetch();
      showToast(`수리비가 추가 되었어요.`, 'success', 'bottom');
      setRepairForm({ ...repairHistoryForm, id: response.data.id });
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const patchRepairHistoryMutation = useMutation(
    (request: { id: string; body: RepairForm }) => patchRepairHistory(request),
    {
      onSuccess: () => {
        onClosePopup();
        refetch();
        showToast(`수리비가 수정 되었어요.`, 'success', 'bottom');
        setRepairForm({ ...repairHistoryForm, id: data?.id });
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const onClickSaveBtn = () => {
    if (validateForm()) {
      if (data && data.id) {
        if (patchRepairHistoryMutation.isLoading) {
          return;
        }
        const request = {
          id: data.id.toString(),
          body: repairHistoryForm,
        };
        patchRepairHistoryMutation.mutate(request);
      } else {
        if (postRepairHistoryMutation.isLoading) {
          return;
        }

        if (repairHistoryForm.driveVehicleInfoId) {
          const request: ReapirHistoryRequest = {
            driveVehicleInfoId: driveVehicleInfoId,
            repairDay: repairHistoryForm.repairDay,
            repairItems: repairHistoryForm.repairItems,
          };
          postRepairHistoryMutation.mutate(request);
        }
      }
    }
  };

  const onClickDateSelector = () => {
    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const isDisabledForm = () => {
    return repairHistoryForm.repairItems.some(
      (item) => item.name === '' || item.name === null || item.price === null || item.price === 0,
    );
  };

  const onClickDeleteRepair = (index: number) => {
    const updatedRepairItems: RepairItemsType[] = repairHistoryForm.repairItems.filter((item, i) => i !== index);
    setRepairHistoryForm((prev) => {
      return {
        ...prev,
        repairItems: updatedRepairItems,
      };
    });
  };

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const setPopupInfoForValidForm = (content: string) => {
    setPopupInfo({
      isShow: true,
      textContent: content,
      textRightBtn: '확인',
      onClickRightBtn: closePopup,
    });
  };

  const validateForm = () => {
    let isValid = true;
    repairHistoryForm.repairItems.forEach((item) => {
      if (item.name === null || item.name === '') {
        setPopupInfoForValidForm('수리 내용을 입력해주세요.');
        isValid = false;
      } else if (item.price === null || item.price === 0) {
        setPopupInfoForValidForm('수리비를 입력해주세요.');
        isValid = false;
      }
    });
    return isValid;
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'name') {
        nameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'price') {
        priceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onClickDelete = () => {
    setPopupInfo({
      isShow: true,
      title: '',
      textContent: `${dayjs(selectedDate || new Date()).format('YYYY-MM-DD')} 수리비를 삭제할까요?`,
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '삭제',
      onClickRightBtn: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = () => {
    closePopup();
    if (data?.id) {
      deleteRepairHistoryMutation.mutate(data.id);
    }
  };

  const deleteRepairHistoryMutation = useMutation((id: number) => deleteRepairHistory(id), {
    onSuccess: () => {
      onClosePopup();
      showToast('수리비가 삭제되었어요.', 'success', 'bottom');
      refetch();
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  return (
    <>
      <div className="w-full pb-20">
        <div className="px-4">
          <div onClick={onClickDateSelector}>
            <TextInputLabelLeft
              name="date"
              label="날짜"
              placeholder="날짜 선택"
              value={repairHistoryForm.repairDay}
              suffix={<ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>}
              onChange={(e) => {}}
              readOnly
            ></TextInputLabelLeft>
          </div>
        </div>
        <div>
          {repairHistoryForm.repairItems.map((item, index) => (
            <div key={index}>
              <div className="flex flex-col gap-4 pt-4 px-4">
                <div className="relative">
                  <TextInputLabelLeft
                    ref={nameRef}
                    name="name"
                    label="수리 내용"
                    placeholder="수리 내용 입력"
                    value={item?.name || ''}
                    onChange={(e) => onChangeFormInput(e, index)}
                    onFocus={(e) => scrollIntoView(e)}
                  ></TextInputLabelLeft>
                  <div
                    className={`absolute top-[6px] right-0 flex items-center justify-center  w-6 h-6 bg-white  cursor-pointer ${
                      index === 0 ? 'hidden' : ''
                    }`}
                    onClick={() => onClickDeleteRepair(index)}
                  >
                    <XMarkIcon color={colors.gray[6]}></XMarkIcon>
                  </div>
                </div>

                <TextInputLabelLeft
                  ref={priceRef}
                  name="price"
                  label="수리비"
                  placeholder="수리비 입력"
                  value={item?.price ? formatNumber(String(item?.price)) : ''}
                  onChange={(e) => onChangeFormInput(e, index)}
                  onFocus={(e) => scrollIntoView(e)}
                  inputMode="numeric"
                  maxLength={10}
                  suffix="원"
                ></TextInputLabelLeft>
              </div>
              {index + 1 != repairHistoryForm.repairItems.length && <div className="border-t-8 border-gray-1"></div>}
            </div>
          ))}
        </div>
        <div className={`px-4`}>
          <div className="flex items-center justify-center py-5 text-gray-6" onClick={() => onClickAddRepairCost()}>
            <PlusSmallIcon color={colors.gray[6]}></PlusSmallIcon>
            <span className="ml-2">수리비 추가</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-2 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white shadow-[0_-2px_4px_rgba(0,0,0,0.05)] z-30">
        {data?.id && (
          <div className="w-1/4 min-w-[80px] mr-2">
            <BasicButton
              name={`삭제`}
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[6]}
              fontSize={16}
              fontWeight="bold"
              onClick={() => onClickDelete()}
            ></BasicButton>
          </div>
        )}
        <div className="w-full min-w-[240px]">
          <BasicButton
            name={`저장`}
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            fontWeight="bold"
            onClick={() => onClickSaveBtn()}
          ></BasicButton>
        </div>
      </div>

      <CalendarPopup
        selectedDate={repairHistoryForm.repairDay}
        onChangeSelectedDate={onChangeDate}
        isShow={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        textLeftBtn="닫기"
        onClickLeftBtn={onCloseCalendarPopup}
      ></CalendarPopup>
      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
    </>
  );
};

export default RepairHistoryForm;
