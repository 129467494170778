import React, { useState } from 'react';

import TextInputLabelLeft from '@/components/Common/Input/TextInputLabelLeft';
import { formatNumber } from '@/utils/common';

type CapitalInfoProps = {
  price?: string;
};

interface LoanCalculator {
  principal: string;
  loanTerm?: number;
  downPayment?: string;
  interestRate?: number;
  monthlyPayment: string | null;
}

const CapitalInfo: React.FC<CapitalInfoProps> = ({ price }) => {
  const [state, setState] = useState<LoanCalculator>({
    principal: String(price),
    loanTerm: 0,
    downPayment: '',
    interestRate: 0,
    monthlyPayment: '0',
  });

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    let str = String(value).replace(/[^0-9.]/g, '');

    if (str.lastIndexOf('-') > 0 || str.indexOf('-') > 0 || str.includes('-')) {
      return;
    }
    setState({ ...state, [name]: str });
    calculateMontlyPayment();
  }

  const calculateMontlyPayment = () => {
    setState((prevState) => {
      const { principal, loanTerm, interestRate } = prevState;
      let monthlyPayment = '0';
      if (!!principal && !!loanTerm && !!!!interestRate) {
        if (principal && loanTerm && interestRate) {
          const monthlyInterestRate = interestRate / 12 / 100;
          const loanAmount = Number(principal) * 10000;
          monthlyPayment = String(
            (loanAmount * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -loanTerm)))).toFixed(0),
          );
        }
      }
      return { ...prevState, monthlyPayment };
    });
  };

  return (
    <div className="p-4 my-2 text-gray-8">
      <div className="w-full">
        <h1 className="font-semibold text-lg mb-4">할부 계산기</h1>
        <div className="flex flex-col gap-4">
          <TextInputLabelLeft
            name="principal"
            label="대출 금액"
            placeholder="12,000"
            type="text"
            value={formatNumber(state.principal, true)}
            onChange={handleInputChange}
            inputMode="numeric"
            suffix="만원"
          ></TextInputLabelLeft>
          <TextInputLabelLeft
            name="loanTerm"
            label="할부 기간"
            placeholder="예시) 12개월"
            value={state?.loanTerm || ''}
            onChange={handleInputChange}
            inputMode="numeric"
            suffix="개월"
          ></TextInputLabelLeft>
          <TextInputLabelLeft
            name="interestRate"
            label="할부 금리"
            placeholder="예시) 9.5"
            value={state?.interestRate || ''}
            onChange={handleInputChange}
            inputMode="numeric"
            suffix="%"
          ></TextInputLabelLeft>
          <div className="flex justify-between items-center font-semibold py-4">
            <p className="text-gray-8">월 할부금</p>
            <p className="text-lg text-gray-9">{formatNumber(state.monthlyPayment)}원</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapitalInfo;
