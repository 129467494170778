import React, { useEffect, useState } from 'react';
import { UseMutationOptions } from 'react-query';
import { useNavigate } from 'react-router';

import LoadingComponent from '../Common/LoadingComponent';
import DownToUpDetailPopup from '../Common/Popup/DownToUpDetailPopup';
import ScenarioMessage from './ScenarioMessage';
import ImageModal from '@/components/Common/ImageModal';
import { PhoneIcon, ProfileIcon, ResetIcon, XMarkIcon } from '@/components/Icon';
import { CALL_ATTEMPT, CALL_COMPLETED, IMAGE, SCENARIO, TEXT } from '@/const/chat';
import { colors } from '@/const/colors';
import { TRANSFEREE, TRANSFEROR } from '@/const/contract';
import { useChatContext } from '@/contexts/Chat/ChatContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { getFormatDate, getFormatMessageDateTime } from '@/utils/common';

interface ChatRoomDataProps {
  chatRoomData: ChattingDetailResponse | undefined;
  updateChatRoomId: (id: number) => void;
  message: ChatMessage | undefined;
  scrollBottom: () => void;
  handleSendMessage: (
    data: ChatMessageRequest,
    options?: UseMutationOptions<ChatMessageData, unknown, ChatMessageRequest>,
  ) => void;
  readMessages: ChatMessage[] | undefined;
}

const chatMessageContent = ({
  chatRoomData,
  updateChatRoomId,
  message,
  scrollBottom,
  handleSendMessage,
  readMessages,
}: ChatRoomDataProps) => {
  const { member } = useMemberContext();
  const [chatData, setChatData] = useState<ChatMessage[]>([]);
  const navigate = useNavigate();
  const { newChatMessage, setUnreadMessages, unreadMessages } = useChatContext();
  const contractWriterType = member?.id === chatRoomData?.seller.id ? TRANSFEROR : TRANSFEREE;
  const who = member?.id === chatRoomData?.buyer?.id ? chatRoomData?.seller : chatRoomData?.buyer;
  const [imageList, setImageList] = useState<string[]>([]);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [imageModalIndex, setImageModalIndex] = useState<number>(0);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const [retryMessageData, setRetryMessageData] = useState<ChatMessage>();

  const isMessageReadAll = (unreadMessages: { [chatRoomId: number]: number }): boolean => {
    if (unreadMessages === undefined || Object.keys(unreadMessages).length === 0) {
      return false;
    } else {
      const values = Object.values(unreadMessages);
      const result = values.every((value) => value === 0);
      return result;
    }
  };

  // 새로운 채팅 웹소켓 메시지를 받을 경우, 받은 메시지 세팅
  useEffect(() => {
    if (newChatMessage && chatRoomData?.id === newChatMessage.chatRoomId) {
      const updateSocketMessage = {
        ...newChatMessage,
        sender: {
          id: null,
        },
      };

      setChatData((prev) => [...prev, updateSocketMessage] as ChatMessage[]);
      scrollBottom();
    }

    if (chatRoomData?.id) {
      setUnreadMessages((prev) => ({ ...prev, [chatRoomData?.id]: 0 }));
    }
  }, [newChatMessage]);

  useEffect(() => {
    if (readMessages) {
      const idList = readMessages.map((msg) => msg.id);
      setChatData((prev) => {
        return prev.map((msg) => {
          if (idList.includes(msg.id)) {
            return { ...msg, isRead: true };
          }
          return msg;
        });
      });
    }
  }, [readMessages]);

  useEffect(() => {
    setChatData(() => chatRoomData?.chatMessages || []);
    scrollBottom();
  }, [chatRoomData?.chatMessages]);

  useEffect(() => {
    if (message) {
      if (message.isLoading === true) {
        setChatData((prev) => {
          const prevData = prev.find((item) => item.tempId === message.tempId);
          const updatedChatData = prev.filter((item) => item.tempId !== message.tempId);
          return [...updatedChatData, (prevData ? prevData : message) as ChatMessage];
        });
      } else if (message.isLoading === false) {
        setChatData((prev) => {
          return prev.map((msg) => (msg.id === message.tempId ? message : msg) as ChatMessage);
        });
      }

      scrollBottom();
    }
  }, [message]);

  const renderTextContent = (textContent: string) => {
    const paragraphs = textContent.split('\n');
    return (
      <>
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            {index > 0 && <br />}
            {paragraph}
          </span>
        ))}
      </>
    );
  };

  const onClickImage = (chatIndex: number, imageIndex: number) => {
    setOpenImageModal(true);
    setImageModalIndex(imageIndex);

    const clickedMessage = chatData[chatIndex];
    const clickedImageList = clickedMessage.type?.code === IMAGE ? JSON.parse(clickedMessage.contents).images : [];

    setImageList(clickedImageList);
  };

  const addChatData = (chatMessage: ChatMessage) => {
    if (chatMessage) {
      if (chatMessage.isLoading === true) {
        setChatData((prev) => {
          const prevData = prev.find((item) => item.tempId === chatMessage.tempId);
          const updatedChatData = prev.filter((item) => item.tempId !== chatMessage.tempId);
          return [...updatedChatData, (prevData ? prevData : chatMessage) as ChatMessage];
        });
      } else if (chatMessage.isLoading === false) {
        setChatData((prev) => {
          return prev.map((msg) => (msg.id === chatMessage.tempId ? message : msg) as ChatMessage);
        });
      }
      scrollBottom();
    }
  };

  const isBuyer = member?.id === chatRoomData?.buyer.id;

  const onClickRetryOrCancel = (data: ChatMessage) => {
    setIsShowPopup(true);
    setRetryMessageData(data);
  };

  const onClickRetry = () => {
    setIsShowPopup(false);
    if (chatRoomData && retryMessageData) {
      let type = '';
      const formData = new FormData();

      if (chatRoomData?.id) {
        formData.append('chatRoomId', chatRoomData.id?.toString());
      } else {
        formData.append('productId', chatRoomData.productId?.toString());
      }
      formData.append('type', retryMessageData?.type.code);
      formData.append('contents', JSON.parse(retryMessageData.contents).text);

      const request = {
        formData: formData,
        type: retryMessageData?.type.code,
        contents:
          retryMessageData?.type.code === TEXT
            ? JSON.parse(retryMessageData.contents).text
            : JSON.parse(retryMessageData.contents).images,
        tempId: retryMessageData?.tempId,
      };
      handleSendMessage(request, {
        onSuccess: (data) => {
          if (chatRoomData.id) {
            return;
          } else if (data.chatRoom && data.chatRoom.id) {
            updateChatRoomId(data.chatRoom.id);
            navigate('/chatting/room', { state: { chatRoomId: data.chatRoom.id }, replace: true });
          }
        },
      });
    }
  };

  const onClickDelete = () => {
    setIsShowPopup(false);
    setChatData((prev) => {
      const updatedChatData = prev.filter((item) => item.tempId !== retryMessageData?.tempId);
      return [...updatedChatData];
    });
  };

  return (
    <>
      <div className="w-full pb-0 px-4 min-w-[280px] text-sm">
        {chatData?.map((data, index) => {
          const isLastMessageInBlock =
            index === chatData.length - 1 ||
            data.senderId !== chatData[index + 1]?.senderId ||
            getFormatMessageDateTime(data.createdDate) !== getFormatMessageDateTime(chatData[index + 1]?.createdDate) ||
            data.isRead !== chatData[index + 1]?.isRead;

          const isShowDate =
            index === 0 ||
            getFormatDate(data.createdDate, ['year', 'month', 'day']) !==
              getFormatDate(chatData[index - 1]?.createdDate, ['year', 'month', 'day']);

          return (
            <div key={index}>
              {isShowDate && (
                <div className="text-center text-gray-5 text-[12px] my-[14px]">
                  {getFormatDate(data.createdDate, ['year', 'month', 'day'])}
                </div>
              )}

              {member?.id === data.senderId ? (
                <div key={index} className="my-[14px] text-right">
                  {data.type?.code === TEXT && (
                    <div className="inline-block mx-2 bg-primary ml-6 p-3 rounded-l-[20px] rounded-b-[20px] text-white break-all text-left">
                      {renderTextContent(JSON.parse(data.contents).text)}
                    </div>
                  )}
                  {data.type?.code === IMAGE && (
                    <div className="inline-block mx-2">
                      <div className="flex flex-wrap justify-end">
                        {JSON.parse(data.contents).images.map((img: string, idx: number) => (
                          <div
                            key={idx}
                            className="grow w-[133px] h-[100px] mr-1 mb-1"
                            onClick={() => onClickImage(index, idx)}
                          >
                            <img src={img} alt={`images${idx}`} className="w-full h-full object-cover" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {data.type?.code === CALL_ATTEMPT && (
                    <div className="inline-block mx-2 bg-gray-0 ml-6 p-3 border border-gray-3 rounded-[12px] text-gray-8 break-all text-left">
                      <div className="flex gap-[14px]">
                        <PhoneIcon color={colors.green} width={20} height={20}></PhoneIcon>
                        {renderTextContent(JSON.parse(data.contents).text)}
                      </div>
                    </div>
                  )}
                  {data.type?.code === CALL_COMPLETED && (
                    <div className="inline-block mx-2 bg-gray-0 ml-6 p-3 border border-gray-3 rounded-[12px] text-gray-8 break-all text-left">
                      <div className="flex gap-[14px]">
                        <PhoneIcon color={colors.gray[5]} width={20} height={20}></PhoneIcon>
                        {renderTextContent(JSON.parse(data.contents).text)}
                      </div>
                    </div>
                  )}

                  <div className="mx-2 flex justify-end">
                    {!data.isLoading && data.tempId === data.id && (
                      <div
                        className="flex justify-around rounded-[20px] py-[3px] px-[6px] items-center bg-gray-6 w-[56px] h-[24px] cursor-pointer mt-1"
                        onClick={() => onClickRetryOrCancel(data)}
                      >
                        <ResetIcon color={colors.gray[0]} width={18} height={18}></ResetIcon>
                        <div className="h-full w-[1px] bg-gray-7"></div>
                        <XMarkIcon color={colors.gray[0]}></XMarkIcon>
                      </div>
                    )}
                    {data.tempId !== data.id && isLastMessageInBlock && data.id && (
                      <div className="flex justify-center items-center text-gray-6 text-xs text-right mt-1">
                        {!!data.isLoading ? (
                          <div className="flex justify-center items-center px-1">
                            <LoadingComponent size={16} margin={0}></LoadingComponent>
                          </div>
                        ) : data.isRead ? (
                          '읽음 '
                        ) : (
                          '읽지 않음 '
                        )}
                        {getFormatMessageDateTime(data.createdDate)}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div key={index}>
                  {data.type?.code === SCENARIO ? (
                    <ScenarioMessage
                      chatRoomData={chatRoomData}
                      memberId={member?.id}
                      contractWriterType={contractWriterType}
                      chatMessage={data}
                      productId={chatRoomData?.productId}
                      addChatData={addChatData}
                      handleSendMessage={handleSendMessage}
                      updateChatRoomId={updateChatRoomId}
                      isBuyer={isBuyer}
                    />
                  ) : (
                    <div className="flex my-[14px]">
                      <div className="profile">
                        <div className="rounded-full w-[28px] h-[28px] text-center">
                          {who?.profileImageUrl ? (
                            <img
                              src={who?.profileImageUrl}
                              className="w-full h-full rounded-full object-cover"
                              alt="사용자 프로필"
                              width="26"
                            />
                          ) : (
                            <ProfileIcon color={colors.gray[6]} width={25} height={25} />
                          )}
                        </div>
                      </div>
                      <div className="mx-2">
                        {data.type?.code === 'TEXT' && (
                          <div className="bg-gray-2 mr-6 p-3 rounded-r-[20px] rounded-b-[20px] break-all">
                            {renderTextContent(JSON.parse(data.contents).text)}
                          </div>
                        )}
                        {data.type?.code === 'IMAGE' && (
                          <div className="flex flex-wrap">
                            {JSON.parse(data.contents).images.map((img: string, idx: number) => (
                              <div
                                key={idx}
                                className="grow w-[133px] h-[100px] mr-1 mb-1"
                                onClick={() => onClickImage(index, idx)}
                              >
                                <img src={img} alt={`images${idx}`} className="w-full h-full object-cover" />
                              </div>
                            ))}
                          </div>
                        )}
                        {data.type?.code === CALL_ATTEMPT && (
                          <div className="inline-block mr-6 bg-gray-0 p-3 border border-gray-3 rounded-[12px] text-gray-8 break-all text-left">
                            <div className="flex gap-[14px]">
                              <PhoneIcon color={colors.green} width={20} height={20}></PhoneIcon>
                              {renderTextContent(JSON.parse(data.contents).text)}
                            </div>
                          </div>
                        )}
                        {data.type?.code === CALL_COMPLETED && (
                          <div className="inline-block mr-6 bg-gray-0 p-3 border border-gray-3 rounded-[12px] text-gray-8 break-all text-left">
                            <div className="flex gap-[14px]">
                              <PhoneIcon color={colors.gray[5]} width={20} height={20}></PhoneIcon>
                              {renderTextContent(JSON.parse(data.contents).text)}
                            </div>
                          </div>
                        )}
                        {isLastMessageInBlock && (
                          <p className="text-gray-6 text-xs text-left mt-1">
                            {getFormatMessageDateTime(data.createdDate)}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <ImageModal
        isShow={openImageModal}
        setIsShow={setOpenImageModal}
        imageList={imageList}
        imageIndex={imageModalIndex}
      ></ImageModal>

      <DownToUpDetailPopup isShow={isShowPopup} onClosePopup={() => setIsShowPopup(false)} title={''}>
        <div className="px-4 py-4">
          <div className="py-4" onClick={onClickRetry}>
            재전송
          </div>
          <div className="py-4 text-red" onClick={onClickDelete}>
            삭제하기
          </div>
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default chatMessageContent;
