import React from 'react';

import FloatingIconButton from '@/components/Common/Button/FloatingIconButton';
import {
  CarInspectionColorIcon,
  CarPaymentBlueColorIcon,
  PhoneIcon,
  VehicleInspectionColorIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { phoneCall } from '@/utils/common';

const AccompanyingGuidePopup = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="rounded-[12px] bg-gray-1 py-5 px-[30px] text-center">
        <p className="font-semibold text-[18px] text-gray-8">8월 한달간 무료 프로모션</p>
        <p className="text-[20px] text-gray-6 mt-4">
          <span className="line-through mr-[11px]">100만원 </span> →
          <span className="font-extrabold text-[38px] text-primary ml-[11px]">0원</span>
        </p>
      </div>
      <div className="break-keep text-center mt-[34px] max-w-[300px] font-medium text-gray-8">
        중고화물차를 구매하기 전, 차량 전문가가 동행하여 차량 점검부터 적절한 금액 협상까지 도와드리는 서비스입니다.
      </div>

      <div className="bg-gray-1 rounded-[12px] p-5 mt-[30px]">
        <div className="pb-[22px] border-b border-gray-4">
          <div className="flex items-center">
            <CarInspectionColorIcon width={36} height={36}></CarInspectionColorIcon>
            <div className="text-[18px] font-semibold text-gray-8 ml-2">차량 점검</div>
          </div>
          <dl className="mt-2">
            <dt className="font-medium text-[14px]">사고 여부</dt>
            <dd className="text-gray-7 text-[12px] mb-4">· 차량의 사고 이력을 확인합니다.</dd>
            <dt className="font-medium text-[14px]">각종 누유</dt>
            <dd className="text-gray-7 text-[12px] mb-4">· 엔진 및 기타 부위의 누유 여부를 점검합니다.</dd>
            <dt className="font-medium text-[14px]">엔진 및 미션</dt>
            <dd className="text-gray-7 text-[12px]">· 엔진과 미션 상태를 확인하여 성능을 평가합니다.</dd>
          </dl>
        </div>
        <div className="py-[22px] border-b border-gray-4">
          <div className="flex items-center">
            <VehicleInspectionColorIcon width={36} height={36}></VehicleInspectionColorIcon>
            <div className="text-[18px] font-semibold text-gray-8 ml-2">특장 점검</div>
          </div>
          <dl className="mt-2">
            <dt className="font-medium text-[14px]">제원 상태 측정</dt>
            <dd className="text-gray-7 text-[12px] mb-4">· 차량의 제원 상태를 측정하여 정상 범위 내인지 확인합니다.</dd>
            <dt className="font-medium text-[14px]">특장 하자 체크</dt>
            <dd className="text-gray-7 text-[12px] mb-4">· 특장(적재함) 부분에 문제점이 있는지 확인합니다.</dd>
            <dt className="font-medium text-[14px]">수리 발생 견적</dt>
            <dd className="text-gray-7 text-[12px]">· 필요한 수리 비용을 산정합니다.</dd>
          </dl>
        </div>
        <div className="pt-[22px]">
          <div className="flex items-center">
            <CarPaymentBlueColorIcon width={36} height={36}></CarPaymentBlueColorIcon>
            <div className="text-[18px] font-semibold text-gray-8 ml-2">추가 서비스</div>
          </div>
          <dl className="mt-2">
            <dt className="font-medium text-[14px]">차량 금액 협의</dt>
            <dd className="text-gray-7 text-[12px] mb-4">· 차량을 점검 후 적절한 가격 협상을 도와드립니다.</dd>
            <dt className="font-medium text-[14px]">계약 절차 협의</dt>
            <dd className="text-gray-7 text-[12px] mb-4">· 계약서 작성 및 절차를 도와드립니다.</dd>
            <dt className="font-medium text-[14px]">차량 잔금 처리</dt>
            <dd className="text-gray-7 text-[12px]">· 저당 및 압류 상태를 확인하고 잔금 처리를 지원합니다.</dd>
          </dl>
        </div>
      </div>
      <FloatingIconButton
        bgColor={colors.gray[0]}
        icon={<PhoneIcon color={colors.primary}></PhoneIcon>}
        width={52}
        height={52}
        onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
      ></FloatingIconButton>
    </div>
  );
};

export default AccompanyingGuidePopup;
