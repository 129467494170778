import React, { ReactNode } from 'react';

import { XMarkIcon } from '../Icon';
import { colors } from '@/const/colors';

interface SelectPopupInterface {
  children?: ReactNode;
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  clear?: () => void;
}

const DownToUpPopup = ({ children, isShow, setIsShow, title, clear }: SelectPopupInterface) => {
  const onClickClose = () => {
    setIsShow(false);
    if (clear) {
      clear();
    }
  };
  return (
    <>
      {isShow && (
        <div>
          <div className="fixed bg-black opacity-75 w-full h-full justify-center items-center top-0 left-0 inline-flex z-40"></div>
          <div className="fixed z-50 bottom-0 left-0 w-full rounded-t-xl bg-white flex items-center justify-center shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] max-h-[70vh] overflow-scroll">
            <div className="absolute top-0 left-0 m-4 cursor-pointer" onClick={onClickClose}>
              <XMarkIcon color={colors.gray[9]}></XMarkIcon>
            </div>

            <div className="w-full max-h-[70vh] p-5 rounded-t-xl">
              <h4 className="text-center font-bold">{title ? title : '메뉴'}</h4>
              <div>{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DownToUpPopup;
