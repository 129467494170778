import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { getJobList } from '@/api/public';
import ContentBox, { ContentBoxDetail } from '@/components/Common/Box/ContentBox';
import SearchBox from '@/components/Common/Input/SearchBox';
import JobListSkeleton from '@/components/Common/Skeleton/JobListSkeleton';
import Footer from '@/components/Footer';
import MenuHeader from '@/components/Header/MenuHeader';
import { EmptyJobIcon, ResetIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMPLETED } from '@/const/common';
import useSearchJobQuery from '@/hooks/query/useSearchJobQuery';
import { jobSearchParamsFromAtom } from '@/store/job';
import { compareObjects, formatJobTitle, formatPrice, formatTimeRange } from '@/utils/common';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const JobList = () => {
  const navigate = useNavigate();
  const [jobSearchParams] = useAtom(jobSearchParamsFromAtom);
  const setJobSearchParams = useSetAtom(jobSearchParamsFromAtom);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const convertToJobDetailContent = (item: Job): ContentBoxDetail[] => {
    return [
      { key: '운송 품목', value: item.transportItem },
      { key: '운송 구간', value: item.transportSection },
      { key: '근무 요일', value: item.workingDays.desc },
      { key: '근무 시간', value: formatTimeRange(item.workingStartHour, item.workingEndHour) },
      { key: '급여', value: formatPrice(item.salary.toString()) + ' (' + item.salaryType?.desc + ')' },
    ];
  };

  const getJobSearchParams = (page: number): URLSearchParams => {
    const params = new URLSearchParams({ page: page.toString() });
    if (jobSearchParams) {
      if (jobSearchParams.minTons) {
        params.set('minTons', jobSearchParams.minTons.toString());
      }
      if (jobSearchParams.maxTons) {
        params.set('maxTons', jobSearchParams.maxTons.toString());
      }
      if (jobSearchParams.workingArea) {
        params.set('workingArea', jobSearchParams.workingArea.code);
      }
      if (jobSearchParams.workingDays) {
        params.set('workingDays', jobSearchParams.workingDays.code);
      }
      if (jobSearchParams.workingHours) {
        params.set('workingHours', jobSearchParams.workingHours.code);
      }
    }
    return params;
  };

  const jobDataIsNullText = jobSearchParams
    ? '모집 중인 일자리가 없습니다.<br>필터를 다시 설정해주세요.'
    : '모집 중인 일자리가 없습니다.';

  const getJobData = async (page: number) => {
    try {
      const response = await getJobList(getJobSearchParams(page));
      const data: Job[] = response.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { jobList, isLoading, isFetching, isError, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useSearchJobQuery({
      rowsPerPage: 10,
      queryFn: ({ queryKey, pageParam = 1 }) => {
        return getJobData(pageParam);
      },
      jobSearchParams,
    });

  const isButtonDisabled = (status: string): boolean => {
    return status === COMPLETED;
  };

  const getBadgeTextColor = (status: string): string => {
    return isButtonDisabled(status) ? 'text-gray-6' : 'text-blue-3';
  };

  const goToJobDetail = (id: number) => {
    if (jobList) {
      navigate(`/job/${id}`, { state: { data: jobList.find((item: Job) => item.id === id) } });
    }
  };

  const onClickSearch = () => {
    navigate('/job/search');
  };

  const onClickFilterClear = () => {
    setJobSearchParams({ minTons: 1, maxTons: 27 });
  };

  const isShowFilterResetBtn = () => {
    return !compareObjects(jobSearchParams, { minTons: 1, maxTons: 27 });
  };

  return (
    <>
      <MenuHeader title="일자리 구하기"></MenuHeader>

      <div className="flex flex-col w-full pb-28 pt-[116px]">
        <div className="fixed items-center flex z-30 top-[55px] mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] bg-white">
          <SearchBox name="job" placeholder="일자리 검색" value="" onClick={onClickSearch}></SearchBox>
        </div>
        {isShowFilterResetBtn() ? (
          <div className="flex items-center justify-end mr-4">
            <div className="flex items-center justify-center cursor-pointer" onClick={onClickFilterClear}>
              <ResetIcon color={colors.gray[8]}></ResetIcon>
              <span className="text-gray-8 text-sm ml-1">필터해제</span>
            </div>
          </div>
        ) : null}
        {isLoading || isFetching ? (
          <JobListSkeleton></JobListSkeleton>
        ) : jobList && jobList.length > 0 ? (
          <div className="flex flex-col gap-3 px-4 py-6">
            {jobList &&
              jobList.map((item, key) => (
                <div key={key}>
                  <ContentBox
                    id={item.id}
                    title={formatJobTitle(item)}
                    detailContents={convertToJobDetailContent(item)}
                    badgeLabel={item.status.desc}
                    badgeTextColor={getBadgeTextColor(item.status.code)}
                    button={'상세 내용 보기'}
                    buttonDisabled={isButtonDisabled(item.status.code)}
                    buttonOnClick={goToJobDetail}
                  ></ContentBox>
                </div>
              ))}
            <div
              ref={ref}
              className={`h-40 flex flex-col justify-center items-center pb-[76px] ${hasNextPage ? '' : 'hidden'}`}
            >
              <MoreVertIcon sx={{ fontSize: 80, color: colors.gray[2] }}></MoreVertIcon>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col" style={{ height: `calc(100vh - 182px)` }}>
            <EmptyJobIcon color={colors.gray[4]} width={120} height={120}></EmptyJobIcon>
            <span
              className="text-lg text-gray-7 font-normal pt-8 pb-24 text-center"
              dangerouslySetInnerHTML={{ __html: jobDataIsNullText }}
            ></span>
          </div>
        )}
      </div>
      <Footer isMain></Footer>
    </>
  );
};

export default JobList;
