import React, { useState } from 'react';

import BasicButton from './Button/BasicButton';
import TextInputLabelUp from './Input/TextInputLabelUp';
import apiManager from '@/api/AxiosInstance';
import Timer from '@/components/Common/Timer';
import { colors } from '@/const/colors';
import { PHONE_NUMBER_VALIDATION_MESSAGE } from '@/const/errorMessage';
import { validateNumber } from '@/utils/validation';

interface PhoneNumberAuthProps {
  isSign: boolean;
  prevPhoneNumber?: string;
  memberInfo: MemberInfo;
  updateMemberInfo: (data: MemberInfo) => void;
}

const PhoneAuth = ({ isSign, prevPhoneNumber, memberInfo, updateMemberInfo }: PhoneNumberAuthProps) => {
  const [verificationCodeMessage, setVerificationCodeMessage] = useState('');
  const [errorVerificationCode, setErrorVerificationCode] = useState(false);

  const [timerKey, setTimerKey] = useState('0');
  const [timeIsOver, setTimeIsOver] = useState(false);

  const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);

  const onClickAuthenticationCode = () => {
    const queryParams = new URLSearchParams();
    queryParams.append('phoneNumber', memberInfo.phoneNumber);

    apiManager
      .get('/api/v1/members/authentication?' + queryParams.toString())
      .then((response) => {
        updateMemberInfo({
          ...memberInfo,
          isSentCode: true,
        });

        setTimerKey(String(parseInt(timerKey) + 1));
        setTimeIsOver(false);
      })
      .catch((error) => {
        setErrorPhoneNumber(() => true);
        setPhoneNumberMessage((prev) => `${error.message}`);
      });
  };

  const handleAuthenticationConfirm = () => {
    const queryParams = new URLSearchParams();
    queryParams.append('phoneNumber', memberInfo.phoneNumber);
    queryParams.append('authNumber', memberInfo.authNumber);

    apiManager
      .get('/api/v1/members/authentication/confirm?' + queryParams.toString())
      .then((response) => {
        setVerificationCodeMessage((prev) => '');
        updateMemberInfo({
          ...memberInfo,
          isSentCode: false,
          isAuthenticateCode: true,
        });
      })
      .catch((error) => {
        setErrorVerificationCode((prev) => true);
        setVerificationCodeMessage((prev) => `${error.message}`);
      });
  };

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validateNumber(value).isValid) {
      setErrorPhoneNumber((prev) => false);
      setPhoneNumberMessage((prev) => '');
    } else {
      setErrorPhoneNumber((prev) => true);
      setPhoneNumberMessage((prev) => PHONE_NUMBER_VALIDATION_MESSAGE);
    }
    updateMemberInfo({
      ...memberInfo,
      phoneNumber: value,
      authNumber: '',
      isSentCode: false,
      isAuthenticateCode: false,
    });
  };

  const onChangeVerificationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validateNumber(value).isValid) {
      setErrorVerificationCode((prev) => false);
      setVerificationCodeMessage((prev) => '');
    } else {
      setErrorVerificationCode((prev) => true);
      setVerificationCodeMessage((prev) => PHONE_NUMBER_VALIDATION_MESSAGE);
    }
    updateMemberInfo({ ...memberInfo, authNumber: value });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-[30px]">
        {!isSign && (
          <div className="w-full">
            <div className="flex justify-between align-center">
              <TextInputLabelUp
                name="password"
                label="현재 휴대폰 번호"
                placeholder="'-'를 제외 숫자만 입력"
                value={prevPhoneNumber || ''}
                onChange={onChangePhoneNumber}
                inputMode="numeric"
                type="number"
                disabled={true}
              ></TextInputLabelUp>
            </div>
          </div>
        )}
        <div className="relative w-full flex justify-between align-center">
          <TextInputLabelUp
            name="password"
            label={!isSign ? '변경할 휴대폰 번호' : '휴대폰 번호'}
            placeholder="'-'를 제외 숫자만 입력"
            value={memberInfo.phoneNumber || ''}
            onChange={onChangePhoneNumber}
            error={errorPhoneNumber}
            errorMsg={phoneNumberMessage}
            inputMode="numeric"
            type="number"
          ></TextInputLabelUp>
          <div className="absolute right-0 bottom-2">
            <BasicButton
              name={memberInfo.isSentCode ? '재전송' : '인증요청'}
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={14}
              width={80}
              height={30}
              borderRadius={12}
              fontWeight="semibold"
              onClick={() => onClickAuthenticationCode()}
              isDisabled={errorPhoneNumber || !memberInfo.phoneNumber || memberInfo.isAuthenticateCode}
            ></BasicButton>
          </div>
        </div>
        <div className="relative w-full">
          <span className="absolute left-16 text-base text-blue-3">
            {memberInfo.isSentCode && !memberInfo.isAuthenticateCode && (
              <Timer key={timerKey} updateIsOver={(isOver) => setTimeIsOver(isOver)} />
            )}
          </span>
          <div className="flex justify-between align-center">
            <TextInputLabelUp
              name="authNumber"
              label="인증번호"
              placeholder="인증번호 입력"
              value={memberInfo.authNumber || ''}
              onChange={onChangeVerificationCode}
              error={errorVerificationCode}
              errorMsg={verificationCodeMessage}
              inputMode="numeric"
              type="number"
              disabled={!memberInfo.isSentCode || memberInfo.isAuthenticateCode || timeIsOver}
            ></TextInputLabelUp>
            <div className="absolute right-0 bottom-2">
              <BasicButton
                name={memberInfo?.isAuthenticateCode ? '인증완료' : '인증하기'}
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={14}
                width={80}
                height={30}
                borderRadius={12}
                fontWeight="semibold"
                onClick={() => handleAuthenticationConfirm()}
                isDisabled={errorVerificationCode || !memberInfo.authNumber || memberInfo.isAuthenticateCode}
              ></BasicButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneAuth;
