import React, { useEffect, useState } from 'react';
import Tour, { ReactourStep } from 'reactour';

import BasicButton from '../Common/Button/BasicButton';
import { MoneyCircleBoldIcon, PlusSmallIcon } from '../Icon';
import DriveItemView from './DriveItemView';
import FuelingItemView from './FuelingItemView';
import RepairItemView from './RepairItemView';
import { colors } from '@/const/colors';
import { DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT } from '@/const/drive';
import { PopupInfo } from '@/pages/Drive/DriveHome';
import { formatNumber } from '@/utils/common';
import { Stack } from '@mui/material';

interface DriveViewProps {
  selectedDate: string;
  driveVehicleInfoId: number;
  data?: DriveInfoResponse;
  driveHistoryList: DriveHistoryList[];
  refetch: () => void;
  onClickItem: (type: string, name: string, index?: number) => void;
  onClickAddBtn: (popupInfo: PopupInfo) => void;
}

const DriveListView = ({
  selectedDate,
  driveVehicleInfoId,
  data,
  driveHistoryList,
  refetch,
  onClickItem,
  onClickAddBtn,
}: DriveViewProps) => {
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const getTagList = (item: DriveHistoryList) => {
    const tagList: OptionDataType[] = [];
    if (item.toll !== null && item.toll > 0) {
      tagList.push({ id: 1, code: 'toll', desc: `${formatNumber(String(item.toll))}원`, key: '통행료' });
    }
    if (item.fuelCost !== null && item.fuelCost > 0) {
      tagList.push({ id: 3, code: 'fuelCost', desc: `${formatNumber(String(item.fuelCost))}원`, key: '예상 주유비' });
    }
    return tagList;
  };

  const steps: ReactourStep[] = [
    {
      selector: '.drive-item-checkbox',
      content: () => (
        <div className="flex justify-center items-center">
          <MoneyCircleBoldIcon color={colors.gray[0]} width={30} height={30}></MoneyCircleBoldIcon>
          <div className="flex-1 ml-[10px] break-keep">
            수금이 완료되었다면 미수금을 클릭해 수금완료로 변경해주세요.
          </div>
        </div>
      ),
      position: 'top',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
    {
      selector: '.drive-item-checkbox',
      content: () => (
        <div className="flex justify-center items-center">
          <MoneyCircleBoldIcon color={colors.gray[0]} width={30} height={30}></MoneyCircleBoldIcon>
          <div className="flex-1 ml-[10px] break-keep">
            아직 미수금 상태라면 한번 더 클릭해 미수금으로 변경해주세요.
          </div>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
  ];

  const onControlTutorial = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      localStorage.setItem('driveTutorialReceivedCheck', String(false));
      setIsTourOpen(false);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem('driveTutorialReceivedCheck') ||
      localStorage.getItem('driveTutorialReceivedCheck') === 'true'
    ) {
      if (driveHistoryList && !!driveVehicleInfoId) {
        localStorage.setItem('driveTutorialReceivedCheck', String(true));
        const driveItemCheckbox = document.querySelector('.drive-item-checkbox');
        if (
          driveItemCheckbox &&
          driveHistoryList.length !== 0 &&
          driveHistoryList[0].transferStartDate === selectedDate &&
          driveHistoryList[0].transportInfos[0].isReceivedCost === false
        ) {
          setTimeout(() => {
            setIsTourOpen(true);
          }, 400);
        }
      }
    }
  }, [driveHistoryList]);

  const isEmpty = (data: DriveHistoryList) => {
    return data?.driveHistoryType?.code === DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT;
  };

  const fuellingHistoryBorder = driveHistoryList.length > 0 ? 'border-t-8 border-gray-1' : '';
  const repairHistoryBorder =
    driveHistoryList.length > 0 || (data?.fuelingHistories && data?.fuelingHistories.length > 0)
      ? 'border-t-8 border-gray-1'
      : '';

  return (
    <>
      {(!driveHistoryList || driveHistoryList.length === 0) &&
      (!data?.fuelingHistories || data.fuelingHistories.length === 0) &&
      !data?.repairHistoryResponse ? (
        <div className="flex justify-center items-center h-[67px]">
          <div className="text-gray-7">내역이 없습니다.</div>
        </div>
      ) : (
        <>
          {driveHistoryList?.map((item, index) => (
            <div className="px-4" key={index}>
              <div className={`${index != driveHistoryList.length - 1 ? 'border-b border-gray-4' : ''}`}>
                <DriveItemView
                  driveVehicleInfoId={driveVehicleInfoId}
                  key={index}
                  data={item}
                  isEmpty={isEmpty(item)}
                  detailContents={item?.transportInfos}
                  tagList={getTagList(item)}
                  refetch={refetch}
                  onClickItem={() =>
                    onClickItem('drive', isEmpty(item) ? '공차 수정' : item.title || '일지 추가', index)
                  }
                  onControlTutorial={onControlTutorial}
                ></DriveItemView>
              </div>
            </div>
          ))}
          {data?.fuelingHistories && data?.fuelingHistories.length > 0 && (
            <div className={`${fuellingHistoryBorder}  py-6 px-4`}>
              <div className="flex justify-between items-center pb-3">
                <div className="flex items-center">
                  <span className="text-gray-8 font-semibold mr-[6px] text-lg">주유비</span>
                </div>
              </div>
              <Stack spacing={2}>
                {data?.fuelingHistories?.map((fuelingHistory, index) => (
                  <div className="cursor-pointer" onClick={() => onClickItem('fuel', '주유비', index)} key={index}>
                    <FuelingItemView fuelingHistory={fuelingHistory}></FuelingItemView>
                  </div>
                ))}
              </Stack>
            </div>
          )}
          {data?.repairHistoryResponse && (
            <div
              className={`${repairHistoryBorder} py-6 px-4 cursor-pointer`}
              onClick={() => onClickItem('repair', '수리비')}
            >
              <RepairItemView repairHistory={data?.repairHistoryResponse}></RepairItemView>
            </div>
          )}
        </>
      )}
      <div className="px-4">
        <div className="border-t border-gray-4">
          <div className="flex justify-between items-center">
            <BasicButton
              className="addDriveFormBtn"
              name={'일지추가'}
              icon={
                <div className="rounded-full bg-gray-2 w-[20px] h-[20px] flex items-center justify-center">
                  <PlusSmallIcon color={colors.gray[7]} strokeWidth={0.5}></PlusSmallIcon>
                </div>
              }
              bgColor={colors.gray[0]}
              borderColor={colors.gray[4]}
              textColor={colors.gray[7]}
              fontSize={15}
              fontWeight="medium"
              onClick={() => onClickAddBtn({ name: '일지 추가', type: 'drive' })}
              height={80}
              border="none"
            ></BasicButton>
            <BasicButton
              name={'주유비추가'}
              icon={
                <div className="rounded-full bg-gray-2 w-[20px] h-[20px] flex items-center justify-center">
                  <PlusSmallIcon color={colors.gray[7]} strokeWidth={0.5}></PlusSmallIcon>
                </div>
              }
              bgColor={colors.gray[0]}
              borderColor={colors.gray[4]}
              textColor={colors.gray[7]}
              fontSize={15}
              fontWeight="medium"
              onClick={() => onClickAddBtn({ name: '주유비 추가', type: 'fuel' })}
              height={80}
              border="none"
            ></BasicButton>
            <BasicButton
              name={'수리비추가'}
              icon={
                <div className="rounded-full bg-gray-2 w-[20px] h-[20px] flex items-center justify-center">
                  <PlusSmallIcon color={colors.gray[7]} strokeWidth={0.5}></PlusSmallIcon>
                </div>
              }
              bgColor={colors.gray[0]}
              borderColor={colors.gray[4]}
              textColor={colors.gray[7]}
              fontSize={15}
              fontWeight="medium"
              onClick={() => onClickAddBtn({ name: '수리비 추가', type: 'repair' })}
              height={80}
              border="none"
            ></BasicButton>
          </div>
        </div>
      </div>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => {}}
        goToStep={step}
        closeWithMask={false}
        rounded={10}
        showButtons={false}
        showNavigationNumber={false}
        showNavigation={false}
        showCloseButton={false}
        disableKeyboardNavigation={true}
        maskSpace={0}
      ></Tour>
    </>
  );
};

export default DriveListView;
