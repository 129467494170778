import React, { memo, useEffect, useState } from 'react';
interface TimerProps {
  updateIsOver: (isOver: boolean) => void;
}

const Timer = memo(({ updateIsOver }: TimerProps) => {
  const MINUTES_IN_MS = 5 * 60 * 1000;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(MINUTES_IN_MS);

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0');
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    if (timeLeft <= 0) {
      updateIsOver(true);
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return timeLeft > 0 ? (
    <span className="font-normal">
      {minutes} : {second}
    </span>
  ) : (
    <span className="text-sm text-red">인증시간이 만료되었습니다.</span>
  );
});

export default Timer;
