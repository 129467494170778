import React from 'react';
import Stack from '@mui/material/Stack';

type ProductHistoryProps = {
  product?: ProductDetailResponse;
};

interface Data {
  key: string;
  title: string;
  value?: HistoryType | undefined;
  values?: HistoryType[] | undefined;
}

export default function ProductHistory({ product, ...props }: ProductHistoryProps) {
  const productHistory: Data[] = [
    { key: 'LAST_OWNER', title: '소유자 정보 (현물출자 이력)', value: product?.lastOwnerInfo },
    { key: 'TRADING', title: '거래 이력', values: product?.tradingHistory },
    { key: 'INSPECTION', title: '자동차 검사 이력', values: product?.inspectionHistory },
    { key: 'TUNING', title: '변경 이력', values: product?.tuningHistory },
  ];

  return (
    <div className="py-2 my-2 text-gray-8">
      {productHistory.map((history, index) => (
        <div key={index} className={`${index !== 0 ? 'border-t-8 border-gray-1' : ''}`}>
          <div className="px-4">
            {history.key === 'LAST_OWNER' && (
              <div className="pt-2 pb-6">
                <p className="font-semibold text-lg pb-3">{history.title}</p>
                {history.value ? (
                  <dl className="text-base bg-gray-1 rounded-lg p-4">
                    <dt className="font-semibold mb-1">{history.value.date}</dt>
                    <dd>{history.value.content}</dd>
                  </dl>
                ) : (
                  <div className="text-base bg-gray-1 text-gray-6 rounded-lg p-4">소유자 정보 없음</div>
                )}
              </div>
            )}
            <dl className="text-base">
              {history.key !== 'LAST_OWNER' && (
                <div className="py-6">
                  <p className="text-lg font-semibold pb-3">{history.title}</p>
                  <div className="p-4 bg-gray-1 rounded-lg">
                    {history.values && history.values.length > 0 ? (
                      history.values.map((item, index) => (
                        <div key={index} className={`text-base ${index !== 0 ? 'border-t border-gray-3 mt-5' : ''}`}>
                          {history.key === 'TUNING' ? (
                            <>
                              <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.date}</dt>
                              <dd className="text-gray-8">
                                <ul>
                                  <li>・ 구조변경 : {item.before}</li>
                                  <li>・ 구조변경 후 내역 : {item.after}</li>
                                </ul>
                              </dd>
                            </>
                          ) : (
                            <>
                              <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.date}</dt>
                              <dd className="pt-3">{item.content}</dd>
                            </>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-6">{history.title} 없음</div>
                    )}
                  </div>
                </div>
              )}
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
}
