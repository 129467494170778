import { useAtom, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { colors } from '@/const/colors';
import { productsSearchParamsAtom } from '@/store/products';
import { formatPrice, getCurrentYear } from '@/utils/common';
import { Button, Stack } from '@mui/material';

interface RecommendProductsProps {
  products: ProductsList[];
}

export default function RecommendProducts({ products }: RecommendProductsProps) {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState(getCurrentYear());
  const [productSearchParams] = useAtom(productsSearchParamsAtom);
  const setProductSearchParams = useSetAtom(productsSearchParamsAtom);

  const onClickProduct = (id: number) => {
    navigate(`/products/${id}`);
  };

  const onClickSeeAll = () => {
    navigate('/products');
    setProductSearchParams({
      keyword: '',
      manufacturerCategoriesId: new Set([]),
      minYear: '2000',
      maxYear: (currentYear + 1).toString(),
      minTons: '1',
      maxTons: '27',
      minDistance: '',
      maxDistance: '',
      axis: '',
      transmission: '',
      loaded: new Set([]),
      minLoadedInnerLength: '',
      maxLoadedInnerLength: '',
      salesType: 'ALL',
      productsSortType: { code: 'CREATED_DATE', desc: '기본 정렬순' },
      page: 1,
    });
  };

  return (
    <>
      <div className="w-full bg-[#414141]">
        <div className="p-4">
          <h2 className="text-lg font-semibold text-gray-700 text-white">추천 차량</h2>
          <span className="text-base text-gray-400 pb-2 text-gray-50">혹시 이 차량을 찾고 있나요?</span>
          <div className="bg-white rounded-md mt-2">
            {products.map((item, index) => (
              <div className="px-4 pt-4" key={index}>
                <Stack spacing={2} onClick={() => onClickProduct(item.id)} className="cursor-pointer">
                  <div className="flex flex-row items-center">
                    <div className="border border-gray-100 rounded-lg w-20 h-20 min-w-[80px] mr-4" key={index}>
                      <img
                        src={item?.representImageUrl}
                        alt="represent_img"
                        className="rounded-lg w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex flex-col text-base font-semibold break-keep">
                      <span>{item.truckName}</span>
                      <span>{formatPrice(String(item.price))}</span>
                    </div>
                  </div>
                  {index !== products.length - 1 && <hr className="my-2" />}
                </Stack>
              </div>
            ))}
            <div className="p-4">
              <Button
                className="w-full"
                onClick={() => onClickSeeAll()}
                sx={{
                  fontSize: '18px',
                  color: colors.gray[8],
                  bgcolor: colors.gray[0],
                  borderRadius: '8px',
                  border: `1px solid  ${colors.gray[3]}`,
                  '&:hover': {
                    backgroundColor: colors.gray[0],
                  },
                }}
              >
                차량 전체보기
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
