import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AutoPlay } from '@egjs/flicking-plugins';
import Flicking, { EVENTS } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

interface BannerCarouselProps {
  banners: Banner[];
}

export default function BannerCarousel({ banners }: BannerCarouselProps) {
  const navigate = useNavigate();
  const [bulletList, setBulletList] = useState(banners.map((item, index) => ({ isNow: index === 0 })));
  const flickingRef = useRef<Flicking | null>(null);

  const plugins = [new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: false })];

  const onClickBanner = (item: Banner) => {
    if (item.link) {
      if (item.type.code === 'INTERNAL') {
        navigate(item.link);
      } else {
        window.open(item.link);
      }
    }
  };

  useEffect(() => {
    const bullet = banners.map((item, index) => ({ isNow: index === 0 }));
    setBulletList(bullet);
    if (flickingRef.current) {
      flickingRef.current.on(EVENTS.CHANGED, (event) => {
        const updatedBullet = banners.map((_, index) => ({ isNow: index === event.index }));
        setBulletList(updatedBullet);
      });
    }
  }, [banners]);

  const onClickBullet = (index: number) => {
    if (flickingRef.current) {
      flickingRef.current.moveTo(index).catch(() => void 0);
    }
  };

  return (
    <div className="relative">
      {!!banners.length && (
        <Flicking
          ref={flickingRef}
          align="center"
          circular={true}
          renderOnlyVisible={true}
          className="z-0"
          moveType="strict"
          plugins={plugins}
        >
          {banners.map((item, index) => (
            <div className="flex w-full mx-2" key={index} onClick={() => onClickBanner(item)}>
              {item.contentsType.code === 'IMAGE' && (
                <img
                  src={item.contents}
                  alt="배너 이미지"
                  className="rounded-lg w-full h-full object-center"
                  style={{ pointerEvents: 'none' }}
                />
              )}
              {item.contentsType.code === 'HTML' && (
                <div
                  className="w-full"
                  dangerouslySetInnerHTML={{ __html: item.contents }}
                  style={{ pointerEvents: 'none' }}
                />
              )}
            </div>
          ))}
        </Flicking>
      )}
      <div className="absolute bottom-[-20px] left-[50%] transform -translate-x-1/2 flex items-end justify-center py-8 cursor-pointer z-50">
        {bulletList.map((data, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full cursor-pointer m-1 bg-gray-0 ${data.isNow ? '' : 'opacity-60'}`}
            onClick={() => onClickBullet(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}
