import React, { useCallback, useEffect, useState } from 'react';

import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import {
  NAME_VALIDATION_LENGTH_MESSAGE,
  NAME_VALIDATION_MESSAGE,
  PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE,
} from '@/const/errorMessage';
import { Validation, validateName, validatePhoneNumber } from '@/utils/validation';

interface ApplyServiceFormProps {
  updateStep: (step: number) => void;
  formData: PurchaseAccompanyingServicesForm;
  updateFormData: (formData: PurchaseAccompanyingServicesForm) => void;
}

const ApplyServiceForm = ({ updateStep, formData, updateFormData }: ApplyServiceFormProps) => {
  const [name, setName] = useState(formData.name || '');
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || '');
  const [selectedVehicle, setSelectedVehicle] = useState(formData?.truckName || '');

  useEffect(() => {
    if (formData) {
      setName(formData.name || '');
      setPhoneNumber(formData.phoneNumber || '');
      setSelectedVehicle(formData?.truckName || '');
    }
  }, [formData]);

  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

  const handleClickVehicleSelect = () => {
    updateStep(2);
  };

  const validateNameAndPhoneNumber = (name?: string, phoneNumber?: string) => {
    if (name) {
      if (name.length > 20) {
        setNameErrorMessage(NAME_VALIDATION_LENGTH_MESSAGE);
        setNameError(true);
      } else if (validateName(name) === false) {
        setNameErrorMessage(NAME_VALIDATION_MESSAGE);
        setNameError(true);
      } else {
        setNameErrorMessage('');
        setNameError(false);
      }
    }

    if (phoneNumber) {
      if (phoneNumber.length > 11) {
        setPhoneNumberErrorMessage(PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE);
        setPhoneNumberError(true);
      } else {
        const phoneNumberValidation = validatePhoneNumber(phoneNumber) as Validation;
        if (phoneNumberValidation.isValid === false) {
          setPhoneNumberErrorMessage(phoneNumberValidation.errorMessage);
          setPhoneNumberError(true);
        } else {
          setPhoneNumberErrorMessage('');
          setPhoneNumberError(false);
        }
      }
    }
  };

  const onChangeRequestInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {
      validateNameAndPhoneNumber(value, phoneNumber);
      setName(value);
    } else {
      validateNameAndPhoneNumber(name, value);
      setPhoneNumber(value);
    }
  }, []);

  useEffect(() => {
    updateFormData({ ...formData, name, phoneNumber });
  }, [name, phoneNumber]);

  return (
    <>
      <div className="px-4 py-[30px]">
        <div className="flex flex-col gap-[30px]">
          <TextInputLabelUp
            name="name"
            label="신청자명"
            placeholder="신청자명을 입력해주세요."
            value={name || ''}
            onChange={onChangeRequestInput}
            error={nameError}
            errorMsg={nameErrorMessage}
          ></TextInputLabelUp>

          <TextInputLabelUp
            name="phoneNumber"
            label="휴대폰 번호"
            placeholder="휴대폰 번호를 입력해주세요."
            value={phoneNumber || ''}
            onChange={onChangeRequestInput}
            error={phoneNumberError}
            errorMsg={phoneNumberErrorMessage}
          ></TextInputLabelUp>

          <div onClick={handleClickVehicleSelect}>
            <TextInputLabelUp
              name="차량"
              label="구매할 차량 선택하기"
              placeholder="차량 선택"
              value={selectedVehicle || ''}
              onChange={() => {}}
              suffix={<ChevronRightIcon color={colors.gray[8]}></ChevronRightIcon>}
              readOnly
            ></TextInputLabelUp>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyServiceForm;
