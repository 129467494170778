import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MyProductPrice from '../MyProductPrice';
import apiManager from '@/api/AxiosInstance';
import DraftListingBox from '@/components/Common/DraftListingBox';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MoreMenu from '@/components/Menu/MoreMenu';
import ProductStatus from '@/components/More/ProductStatus';
import ProductType from '@/components/More/ProductType';
import ProductTypeChangeButton from '@/components/More/ProductTypeChangeButton';
import { COMPLETED, SALE } from '@/const/productStatus';
import { useToastContext } from '@/contexts/Common/ToastContext';

const MyListing = () => {
  const navigate = useNavigate();
  const [myProductData, setMyProductData] = useState<ProductDetailResponse[]>([]);
  const [menuStates, setMenuStates] = useState<boolean[]>([]);
  const { showToast } = useToastContext();
  const deleteId = useRef<number>();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState<string | undefined>('');

  const toggleMenu = (index: number) => {
    const newMenuStates = [...menuStates];
    for (let i = 0; i < newMenuStates.length; i++) {
      if (i !== index) {
        newMenuStates[i] = false;
      }
    }
    newMenuStates[index] = !newMenuStates[index];
    setMenuStates(newMenuStates);
  };

  const onClickProductList = (item: ProductDetailResponse) => {
    const { status, id } = item;
    if ([SALE, COMPLETED].includes(status.code)) {
      navigate(`/products/${id}`, { state: { isPublicDetail: false } });
    }
  };

  useEffect(() => {
    apiManager
      .get('/api/v1/products/my')
      .then((response) => {
        if (response) {
          const responseData: ProductDetailResponse[] = response.data;
          setMyProductData(responseData);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const onClickDelete = (item: ProductDetailResponse) => {
    if (item) {
      setIsShowPopup(true);
      setPopupTitle(item.truckNumber);
      deleteId.current = item.id;
    }
  };

  const updateMyProducts = () => {
    setMyProductData;
  };

  const deleteProduct = () => {
    apiManager
      .delete(`/api/v1/products/${deleteId.current}`)
      .then((response) => {
        if (response) {
          setMyProductData((prev) => prev.filter((item) => item.id !== deleteId.current));
        }
        showToast('차량이 삭제되었어요.', 'success', 'bottom');
        setIsShowPopup(false);
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const isShowDivider =
    myProductData.some((item) => item.status.code === 'BEFORE_SALE') &&
    myProductData.some((item) => item.status.code !== 'BEFORE_SALE');

  const onClickMore = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {myProductData.length > 0 && (
        <div>
          <div className="h-2 w-full bg-gray-1 mb-5 my-20"></div>
          <section className="flex flex-col justify-between w-full pt-6 p-4">
            <h2 className="text-xl font-bold ">내가 등록한 차량</h2>
            <div>
              <>
                {myProductData.map((item, index) => (
                  <div key={item.id} className="mb-4">
                    {item.status.code === 'BEFORE_SALE' && (
                      <DraftListingBox
                        data={item}
                        onClickDelete={onClickDelete}
                        updateList={updateMyProducts}
                      ></DraftListingBox>
                    )}
                  </div>
                ))}
              </>

              {!!isShowDivider && <div className="h-[1px] w-full bg-gray-4 mb-5"></div>}

              <>
                {myProductData.map((item, index) => (
                  <ul key={item.id} className="mb-3.5 xxs:text-sm">
                    {item.status.code !== 'BEFORE_SALE' && (
                      <>
                        <li className="flex relative rounded-lg border border-gray-3 p-4">
                          <div className="h-[100px] w-[100px] min-w-[80px] min-h-[80px]">
                            <img
                              src={
                                item.productsImage?.frontSideImageUrl ||
                                'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/truck_img.svg'
                              }
                              alt="트럭이미지"
                              className="rounded-lg w-full h-full object-cover"
                            ></img>
                          </div>
                          <div
                            className={`px-3 flex-1 min-w-[160px] ${
                              item.status.code === SALE || item.status.code === COMPLETED ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => onClickProductList(item)}
                          >
                            <div className="flex flex-wrap gap-1">
                              <ProductStatus status={item.status}></ProductStatus>
                              <ProductType
                                type={item.type?.code === 'SPEED' ? item.statusOfSpeedProduct : item.type}
                              ></ProductType>
                            </div>

                            <dt className="font-medium pt-2 text-gray-8">{item?.truckNumber}</dt>
                            <span className="font-semibold text-gray-9 text-lg">
                              <MyProductPrice product={item}></MyProductPrice>
                            </span>

                            <ProductTypeChangeButton
                              id={item.id}
                              type={item.type}
                              status={item.status}
                              setProductList={setMyProductData}
                              statusOfSpeedProduct={item.statusOfSpeedProduct}
                            ></ProductTypeChangeButton>
                            <div className="absolute right-2 top-[60px]" onClick={onClickMore}>
                              <MoreMenu
                                id={item.id}
                                status={item.status.code}
                                price={Number(item.price)}
                                setProductList={setMyProductData}
                                confirmTitle={item.truckNumber}
                                isShow={menuStates[index]}
                                onToggleMenu={() => toggleMenu(index)}
                              ></MoreMenu>
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                ))}
              </>
            </div>
          </section>
        </div>
      )}

      <BasicPopup
        isShow={isShowPopup}
        title={popupTitle}
        textContent="차량을 정말 삭제하시겠어요?"
        textLeftBtn="취소"
        onClickLeftBtn={() => setIsShowPopup(false)}
        textRightBtn="삭제"
        onClickRightBtn={() => deleteProduct()}
      ></BasicPopup>
    </>
  );
};

export default MyListing;
