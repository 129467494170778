import { PHONE_NUMBER_VALIDATION_MESSAGE } from '@/const/errorMessage';
import { nameRegex, numberRegex, passwordRegex, phoneNumberRegex, positiveIntegerRegex } from '@/const/regex';

export interface Validation {
  isValid: boolean;
  errorMessage: string;
}

export const validateName = (name: string) => {
  return nameRegex.test(name);
};

export const validatePassword = (password: string) => {
  return passwordRegex.test(password);
};

export const validateNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }
  return { isValid: numberRegex.test(number) } as Validation;
};

export const validatePositiveInteger = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }
  if (number && !positiveIntegerRegex.test(number)) {
    return { isValid: false, errorMessage: '0이상의 숫자를 입력해주세요.' } as Validation;
  }
  return { isValid: positiveIntegerRegex.test(number) } as Validation;
};

export const validateContractRegistractionNumber = (number: string | null | undefined) => {};

export const validateRegistrationNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }

  const registrationNumber: string = number.replace(/-/g, '');
  if (registrationNumber.length < 10 || registrationNumber.length > 13) {
    return {
      isValid: false,
      errorMessage: '주민등록번호는 13자리, 사업자등록번호는 10자리 또는 13자리로 입력해주세요.',
    } as Validation;
  }

  // 생년월일 유효성 검사
  const year = parseInt(registrationNumber.substring(0, 2), 10);
  const month = parseInt(registrationNumber.substring(2, 2), 10);
  const day = parseInt(registrationNumber.substring(4, 2), 10);

  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return { isValid: false, errorMessage: '올바른 생년월일이 아닙니다.' };
  }
  return { isValid: true } as Validation;
};

export const validateBusinessNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }

  const businessNumber: string = number.replace(/-/g, '');
  if (businessNumber.length !== 10) {
    return { isValid: false, errorMessage: '사업자 등록번호는 10자리로 입력해주세요.' } as Validation;
  }

  const regionCode = parseInt(businessNumber.substring(0, 3), 10);
  if (regionCode < 10 || regionCode > 980) {
    return { isValid: false, errorMessage: '올바른 지역 코드가 아닙니다.' } as Validation;
  }

  // 사업자 등록번호 유효성 검사 알고리즘
  const weights = [1, 3, 7, 1, 3, 7, 1, 3, 5];
  let sum = 0;

  const businessNumberList = businessNumber.split('').map((i) => Number(i));

  weights.forEach(function (d, i) {
    sum += d * businessNumberList[i];
  });

  sum += Math.floor((weights[8] * businessNumberList[8]) / 10);
  const checkDigit = Math.floor(businessNumberList[9]) === (10 - (sum % 10)) % 10;
  if (!checkDigit) {
    return { isValid: false, errorMessage: '올바른 사업자 등록번호가 아닙니다.' } as Validation;
  }

  return { isValid: true } as Validation;
};

export const validatePhoneNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false, errorMessage: PHONE_NUMBER_VALIDATION_MESSAGE } as Validation;
  }
  if (!phoneNumberRegex.test(number)) {
    return { isValid: false, errorMessage: '올바른 휴대폰 번호가 아닙니다.' } as Validation;
  }
  return { isValid: phoneNumberRegex.test(number) } as Validation;
};
