import React, { Dispatch, useEffect, useState } from 'react';

import FilledBadge from '../Common/Badge/FilledBadge';
import BasicButton from '../Common/Button/BasicButton';
import apiManager from '@/api/AxiosInstance';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MoreMenu from '@/components/Menu/MoreMenu';
import ProductStatus from '@/components/More/ProductStatus';
import { colors } from '@/const/colors';
import { LICENSE_PATH } from '@/const/common';
import { COMPLETED } from '@/const/license';
import { BEFORE_SALE, SALE } from '@/const/productStatus';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { formatNumber } from '@/utils/common';

type LicenseProps = {
  data: License;
  index: number;
  setLicenseList?: Dispatch<React.SetStateAction<License[]>> | undefined;
  isMyPage?: boolean;
  isShowPurchaseButton?: boolean;
};

interface LicenseInquiryCreateRequest {
  licenseId: number;
}
const LicenseItemView = ({ data, index, setLicenseList, isMyPage, isShowPurchaseButton }: LicenseProps) => {
  const authRedirect = useAuthRedirect();
  const { showToast } = useToastContext();
  const [isShow, setIsShow] = useState(false);
  const [menuStates, setMenuStates] = useState<boolean[]>([]);
  const [popupProps, setPopupProps] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textLeftBtn: '',
    onClickLeftBtn: () => {},
    textRightBtn: '',
    onClickRightBtn: () => {},
  });
  const [purchaseButtonStates, setPurchaseButtonStates] = useState<{ [key: number]: boolean }>({});

  const close = () => {
    setIsShow(false);
  };

  const isCOMPLETED = (status: EnumPresenter) => {
    if (status == null) {
      return false;
    }

    if (status.code === COMPLETED) {
      return true;
    } else {
      return false;
    }
  };

  const isShowCompletedBadge = (status: EnumPresenter) => {
    return isCOMPLETED(status) && !isMyPage;
  };

  const onClickRequestPurchase = (license: License) => {
    if (!authRedirect(LICENSE_PATH)) {
      return;
    }

    setIsShow(true);
    setPopupProps({
      ...popupProps,
      isShow: true,
      title: `${license.tons}톤 ${license.year}년 ${license.licenseType?.desc || ''}`,
      textContent: '번호판을 구매 요청할까요?',
      textLeftBtn: '닫기',
      onClickLeftBtn: close,
      textRightBtn: '요청',
      onClickRightBtn: () => {
        setIsShow(false);
        requestPurchase(license);
      },
    });
  };

  const requestPurchase = (license: License) => {
    const request: LicenseInquiryCreateRequest = { licenseId: license.id };
    apiManager
      .post('/api/v1/license-inquiry', request)
      .then((response) => {
        showToast('번호판 구매를 요청했어요.', 'success', 'bottom');
        setPurchaseButtonStates((prevStates) => ({ ...prevStates, [license.id]: true }));
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const toggleMenu = (index: number) => {
    const newMenuStates = [...menuStates];
    for (let i = 0; i < newMenuStates.length; i++) {
      if (i !== index) {
        newMenuStates[i] = false;
      }
    }
    newMenuStates[index] = !newMenuStates[index];
    setMenuStates(newMenuStates);
  };

  useEffect(() => {
    if (data) {
      const purchaseButtonStatesObject = Object.fromEntries([[data.id, data.requested]]);
      setPurchaseButtonStates(purchaseButtonStatesObject);
    }
  }, [data, setPurchaseButtonStates]);

  const licenseStatus = (status: EnumPresenter, licenseCounselStatus: EnumPresenter) => {
    if (status.code === BEFORE_SALE) {
      return licenseCounselStatus;
    } else {
      return status;
    }
  };

  return (
    <ul className="grid grid-cols-1 gap-4 w-full">
      <li key={data.id} className="p-[18px] border border-gray-4 rounded-xl">
        {isShowCompletedBadge(data.status) && (
          <div className="mt-1 mb-3">
            <FilledBadge
              textColor="text-gray-0"
              bgColor="bg-gray-8"
              rounded="rounded-lg"
              fontWeight="font-semibold"
              text={'거래완료'}
            ></FilledBadge>
          </div>
        )}
        <div className="flex items-center">
          <span className="flex flex-1 flex-col">
            {isMyPage && (
              <p className="min-w-[65px] pb-3">
                <ProductStatus status={licenseStatus(data.status, data.licenseCounselStatus)}></ProductStatus>
              </p>
            )}

            <p className="text-lg xxs:text-base font-semibold text-gray-8 text-ellipsis break-all line-clamp-1">
              {data.tons}톤 {data.year}년 {data.licenseType?.desc}
            </p>
          </span>
          {isMyPage && (
            <>
              <MoreMenu
                isLicense
                id={data.id}
                status={data.status.code}
                setLicenseList={setLicenseList}
                confirmTitle={`${data.tons}톤 ${data.year}년 ${data.licenseType?.desc || ''}`}
                isShow={menuStates[index]}
                onToggleMenu={() => toggleMenu(index)}
              ></MoreMenu>
            </>
          )}
        </div>
        <hr className="my-3 text-gray-4"></hr>
        <div className="flex flex-col text-gray-8 text-base xxs:text-sm font-normal gap-2">
          <div className="flex items-center justify-between">
            <span className="text-gray-7 pr-4">거래 방식</span>
            <span className="flex-1 font-medium text-right">{data.licenseSalesType?.desc}</span>
          </div>

          {data.licenseSalesType.code === 'TRADE' ? (
            <>
              <div className="flex items-center justify-between">
                <span className="text-gray-7 pr-4 break-word">번호판 종류</span>
                <span className="flex-1 font-medium text-right">{data.licenseType?.desc}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-7 pr-4 break-word">번호판 용도</span>
                <span className="flex-1 font-medium text-right">{data.useClassification?.desc}</span>
              </div>
              {data.locate && (
                <div className="flex items-center justify-between">
                  <span className="w-24 text-gray-7 pr-4">거래 지역</span>
                  <span className="flex-1 font-medium text-right">{data.locate.desc}</span>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex items-center justify-between">
                <span className="w-24 text-gray-7 pr-4">보험요율</span>
                <span className="font-medium">{data.insuranceRate}%</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="w-24 text-gray-7 pr-4">지입료</span>
                <span className="font-medium">{formatNumber(String(data.fee))}만원</span>
              </div>
            </>
          )}

          {(data.status.code === SALE || isMyPage) && (
            <div className="flex items-center justify-between">
              <span className="w-24 text-gray-7 pr-4">가격</span>
              <span className="font-medium">{formatNumber(String(data.price))}만원</span>
            </div>
          )}

          {!isShowCompletedBadge(data.status) && !!isShowPurchaseButton && (
            <div className="mt-2">
              <BasicButton
                name={`구매 요청 ${purchaseButtonStates[data.id] ? ' 완료' : ''}`}
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={16}
                height={52}
                borderRadius={12}
                fontWeight="bold"
                isDisabled={purchaseButtonStates[data.id]}
                onClick={() => onClickRequestPurchase(data)}
              ></BasicButton>
            </div>
          )}
        </div>
      </li>

      <BasicPopup
        isShow={isShow}
        title={popupProps.title}
        textContent={popupProps.textContent}
        textLeftBtn={popupProps.textLeftBtn}
        onClickLeftBtn={popupProps.onClickLeftBtn}
        textRightBtn={popupProps.textRightBtn}
        onClickRightBtn={popupProps.onClickRightBtn}
      ></BasicPopup>
    </ul>
  );
};

export default LicenseItemView;
