export const REPRESENTATIVE_NUMBER = '1599-6249';
export const CEO_NUMBER = '01064798670';
export const COMPLETED = 'COMPLETED';
export const PRODUCT_PURCHASE_INQUIRY_PATH = '/products/purchase';
export const PRODUCT_DETAIL = (productId: number) => `/products/${productId}`;
export const LICENSE_PATH = '/license';
export const PRODUCT_SERARCH_PATH = '/products/search';

export const PAGE_NAMES = {
  model: 1,
  tons: 2,
  loaded: 3,
  axis: 4,
  'price-trend': 5,
  'additional-info': 6,
  'detail-info': 7,
  photo: 8,
  price: 9,
};
