import React from 'react';

import { CheckCircleOutlineIcon, CheckCircleSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

export interface OptionType {
  code: string;
  desc: string;
  count?: number;
}

interface RadioBoxListProps {
  optionData: any[];
  value: string;
  onClickItem: (item: OptionType) => void;
}

const RadioBoxList = ({ optionData, value, onClickItem }: RadioBoxListProps) => {
  return (
    <>
      <RadioGroup value={value || ''} name="radio-buttons-group" row>
        <div className="flex justify-around sm280:flex-col sm280:gap-2 w-full">
          {optionData?.map((item, index) => (
            <div
              className={`border w-full ${
                value === item.code ? 'border-primary text-primary font-bold' : 'border-gray-3 text-gray-8 font-normal'
              } rounded-lg py-0 pl-4 flex-1 ${index === 2 ? 'mr-0 sm280:mr-5' : 'mr-5'}`}
              key={index}
              onClick={(e) => onClickItem(item)}
            >
              <FormControlLabel
                key={index}
                value={item.code}
                control={
                  <Radio
                    icon={<CheckCircleOutlineIcon color={colors.gray[3]} />}
                    checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                  />
                }
                sx={{
                  '.MuiTypography-root': {
                    fontWeight: value === item.code ? '700' : '400',
                  },
                }}
                label={item.desc}
              />
            </div>
          ))}
        </div>
      </RadioGroup>
    </>
  );
};

export default RadioBoxList;
