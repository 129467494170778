import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { NavigationType, useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';

import { deleteRepairHistory, patchRepairHistory, postRepairHistory } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import BasicFooterButton from '@/components/Common/Button/BasicFooterButton';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import CalendarPopup from '@/components/Common/Popup/CalendarPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon, PlusSmallIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { nonNumericOrDecimalPattern } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { repairHistoryFormAtom } from '@/store/repairHistory';
import { formatNumber, formatYYYYMMDD, now } from '@/utils/common';

const RepairForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [repairForm] = useAtom(repairHistoryFormAtom);
  const setRepairForm = useSetAtom(repairHistoryFormAtom);
  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);

  const data: RepairHistory = location.state?.data;
  const { id } = useParams();
  const { showToast } = useToastContext();
  const selectedDate = location?.state?.date || now();
  const { member } = useMemberContext();
  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);
  const queryClient = useQueryClient();

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      navigate(-2);
    }
  }, [navigationType]);

  useEffect(() => {
    if (member) {
      setDriveVehicleInfoId(member?.driveVehicleInfo?.id);
    }
  }, [member]);

  const [repairHistoryForm, setRepairHistoryForm] = useState<RepairForm>({
    repairDay: data?.repairDay || formatYYYYMMDD(new Date()),
    repairItems: data?.repairItems || [{ name: '', price: null }],
  });

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = e.target;
    setRepairHistoryForm((prev) => {
      return {
        ...prev,
        repairItems: prev.repairItems.map((item, idx) => {
          const newValue = name === 'name' ? value : Number(String(value).replace(nonNumericOrDecimalPattern, ''));
          if (index === idx) {
            return {
              ...item,
              [name]: newValue,
            };
          }
          return item;
        }),
      };
    });
  };

  const isDisabledForm = () => {
    return repairHistoryForm.repairItems.some(
      (item) => item.name === '' || item.name === null || item.price === null || item.price === 0,
    );
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setRepairHistoryForm((prev) => {
        return {
          ...prev,
          repairDay: date,
        };
      });
    }
  };

  const postRepairHistoryMutation = useMutation((request: ReapirHistoryRequest) => postRepairHistory(request), {
    onSuccess: (response) => {
      showToast(`수리비가 추가 되었어요.`, 'success', 'bottom');
      setRepairForm({ ...repairHistoryForm, id: response.data.id });
      if (location.state?.searchParams) {
        queryClient.invalidateQueries({ queryKey: ['get-drive-info', location.state.searchParams] });
      }

      navigate('/drive/repair', { state: { selectedDate: repairHistoryForm.repairDay } });
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const patchRepairHistoryMutation = useMutation(
    (request: { id: string; body: RepairForm }) => patchRepairHistory(request),
    {
      onSuccess: () => {
        showToast(`수리비가 수정 되었어요.`, 'success', 'bottom');
        setRepairForm({ ...repairHistoryForm, id: Number(id) });

        navigate('/drive/repair', { state: { selectedDate: repairHistoryForm.repairDay } });
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const onClickSaveBtn = () => {
    if (validateForm() && driveVehicleInfoId) {
      if (id) {
        if (patchRepairHistoryMutation.isLoading) {
          return;
        }
        const request = {
          id: String(id),
          body: repairHistoryForm,
        };
        patchRepairHistoryMutation.mutate(request);
      } else {
        if (postRepairHistoryMutation.isLoading) {
          return;
        }
        const request: ReapirHistoryRequest = {
          driveVehicleInfoId: driveVehicleInfoId,
          repairDay: repairHistoryForm.repairDay,
          repairItems: repairHistoryForm.repairItems,
        };
        postRepairHistoryMutation.mutate(request);
      }
    }
  };

  const onClickAddRepairCost = () => {
    if (repairHistoryForm.repairItems.length > 19) {
      showToast('수리비는 최대 20개까지 입력 가능합니다.', 'error', 'bottom');
    } else {
      const newItem = { name: '', price: null };
      setRepairHistoryForm((prev) => {
        return {
          ...prev,
          repairItems: [...prev.repairItems, newItem],
        };
      });
    }
  };

  const onClickDelete = (index: any) => {
    console.log(index);
    const updateRepairItem = repairHistoryForm.repairItems.filter((item, i) => i !== index);
    console.log(updateRepairItem);
    setRepairHistoryForm((prev) => {
      return {
        ...prev,
        repairItems: [...updateRepairItem],
      };
    });
  };

  useEffect(() => {
    if (!repairHistoryForm.repairDay) {
      setRepairHistoryForm((prev) => {
        return {
          ...prev,
          repairDay: selectedDate,
        };
      });
    }
  }, [selectedDate]);

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const setPopupInfoForValidForm = (content: string) => {
    setPopupInfo({
      isShow: true,
      textContent: content,
      textRightBtn: '확인',
      onClickRightBtn: closePopup,
    });
  };

  const validateForm = () => {
    let isValid = true;
    repairHistoryForm.repairItems.forEach((item) => {
      if (item.name === null || item.name === '') {
        setPopupInfoForValidForm('수리 내용을 입력해주세요.');
        isValid = false;
      } else if (item.price === null || item.price === 0) {
        setPopupInfoForValidForm('수리비를 입력해주세요.');
        isValid = false;
      }
    });
    return isValid;
  };

  const onClickDateSelector = () => {
    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const onClickDeleteButton = () => {
    setPopupInfo({
      isShow: true,
      title: '',
      textContent: `${repairHistoryForm.repairDay}<br/>수리비를 삭제할까요?`,
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '삭제',
      onClickRightBtn: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = () => {
    closePopup();
    if (data?.id) {
      deleteRepairHistoryMutation.mutate(data.id);
    }
  };

  const deleteRepairHistoryMutation = useMutation((id: number) => deleteRepairHistory(id), {
    onSuccess: () => {
      showToast('수리비가 삭제되었어요.', 'success', 'bottom');
      navigate('/drive/repair');
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  return (
    <>
      <MenuHeader title={`수리비 ${id ? '수정' : '추가'}`}></MenuHeader>
      <div className="w-full pt-[60px] pb-24">
        <div className="flex flex-col gap-[30px]">
          <div className="px-4" onClick={onClickDateSelector}>
            <TextInputLabelUp
              name="repairDay"
              label="날짜"
              placeholder="날짜 선택"
              value={repairHistoryForm.repairDay}
              suffix={<ChevronRightIcon color={colors.gray[8]}></ChevronRightIcon>}
              readOnly
            />
          </div>

          {repairHistoryForm.repairItems.map((item, index) => (
            <div
              key={index}
              className={`relative flex flex-col gap-[30px] px-4 pb-[30px] ${
                index + 1 !== repairHistoryForm.repairItems.length ? 'border-b-8 border-gray-1' : ''
              }`}
            >
              <div
                className={`absolute right-4 cursor-pointer z-10 ${index === 0 ? 'hidden' : ''} `}
                onClick={() => onClickDelete(index)}
              >
                <XMarkIcon color={colors.gray[7]}></XMarkIcon>
              </div>

              <TextInputLabelUp
                name="name"
                label="수리 내용"
                placeholder="수리 내용"
                value={item?.name || ''}
                onChange={(e) => onChangeFormInput(e, index)}
              />

              <TextInputLabelUp
                name="price"
                label="수리비"
                placeholder="수리비"
                value={item?.price ? formatNumber(String(item?.price)) : ''}
                onChange={(e) => onChangeFormInput(e, index)}
                suffix="원"
                maxLength={10}
                inputMode="numeric"
              />

              <div className={`${index + 1 !== repairHistoryForm.repairItems.length ? 'hidden' : ''}`}>
                <BasicButton
                  name={'수리비 추가'}
                  icon={<PlusSmallIcon color={colors.gray[6]} width={24} height={24}></PlusSmallIcon>}
                  bgColor={colors.gray[0]}
                  borderColor={colors.gray[4]}
                  textColor={colors.gray[6]}
                  fontSize={15}
                  fontWeight="normal"
                  onClick={() => onClickAddRepairCost()}
                  height={24}
                  border="none"
                ></BasicButton>
              </div>
            </div>
          ))}
        </div>
      </div>

      {!id ? (
        <BasicFooterButton text="저장" onClick={() => onClickSaveBtn()}></BasicFooterButton>
      ) : (
        <DualFooterButton
          leftButtonText="삭제"
          onClickLeftButton={() => {
            onClickDeleteButton();
          }}
          rightButtonText="저장"
          onClickRightButton={() => {
            onClickSaveBtn();
          }}
        ></DualFooterButton>
      )}

      <CalendarPopup
        selectedDate={repairHistoryForm.repairDay}
        onChangeSelectedDate={onChangeDate}
        isShow={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        textLeftBtn="닫기"
        onClickLeftBtn={onCloseCalendarPopup}
      ></CalendarPopup>
      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
    </>
  );
};

export default RepairForm;
