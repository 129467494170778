import React from 'react';
import { useNavigate } from 'react-router-dom';

import { XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { Dialog, DialogContent } from '@mui/material';

interface HomePopup {
  isShow: boolean;
  image: Banner;
  textRightBtn?: string;
  onClickRightBtn?: (() => void) | undefined;
  textLeftBtn?: string;
  onClickLeftBtn?: (() => void) | undefined;
  isShowBottomBtn?: boolean;
  onClickCloseBtn?: (() => void) | undefined;
}

const HomePopup: React.FC<HomePopup> = ({
  isShow,
  image,
  textRightBtn,
  onClickRightBtn,
  textLeftBtn,
  onClickLeftBtn,
  isShowBottomBtn = true,
  onClickCloseBtn,
}) => {
  const navigate = useNavigate();
  const onClickImage = (item: Banner) => {
    if (item?.link) {
      if (item.type.code === 'INTERNAL') {
        navigate(item.link);
      } else {
        window.open(item.link);
      }
    }
    if (!isShowBottomBtn && onClickCloseBtn) {
      onClickCloseBtn();
    }
  };
  return (
    <>
      <Dialog
        open={isShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="min-w-[280px]"
      >
        <DialogContent
          sx={{
            minWidth: '216px',
            maxWidth: '360px',
            padding: '0',
            marginBottom: `${isShowBottomBtn ? '50px' : '0'}`,
            textAlign: 'center',
            fontSize: '18px',
          }}
        >
          <div className="max-h-[840px] min-w-[216px] h-full">
            <img
              src={image.contents}
              alt="popup_image"
              className="h-full w-full object-cover"
              width={'100%'}
              onClick={() => onClickImage(image)}
            />

            {isShowBottomBtn ? (
              <>
                <p
                  className="absolute bottom-0 left-0 p-4 text-[#BDBDBD] text-sm cursor-pointer"
                  onClick={onClickLeftBtn}
                >
                  {textLeftBtn}
                </p>
                <p className="absolute bottom-0 right-0 p-3 text-[#1E42A6] cursor-pointer" onClick={onClickRightBtn}>
                  {textRightBtn}
                </p>
              </>
            ) : (
              <div className={`cursor-pointer absolute top-3 right-3 w-6 h-6 p-2`} onClick={onClickCloseBtn}>
                <XMarkIcon color={colors.gray[0]}></XMarkIcon>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HomePopup;
