import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioBoxList from '@/components/Common/Input/Radio/RadioBoxList';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { PRODUCT_SALE_URL } from '@/const/url';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom } from '@/store/products';

const AxisForm = () => {
  const { productEnum } = useProductEnumContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
    if (Number(productFormData.tons) <= 4) {
      setProductFormData({ ...productFormData, axis: { code: 'NONE', desc: '없음' } });
    }
  }, []);

  useBlockNavigation(setIsShowExitConfirmPopup);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({
    url: PRODUCT_SALE_URL,
  });

  const handleClickExitBtn = () => {
    setIsShowExitConfirmPopup(false);

    const hasChanges = prevData?.axis?.code !== productFormData?.axis?.code;

    if (hasChanges) {
      const request = { id: Number(productFormData?.id), axis: productFormData?.axis?.code };
      updateProductTemporarySaveMutation.mutate(request);
    } else {
      navigate(PRODUCT_SALE_URL, { replace: true });
    }
  };

  const handleCloseExitPopup = () => {
    setIsShowExitConfirmPopup(false);
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/price-trend/${id}`,
  });

  const handleClickNext = () => {
    const hasChanges = prevData?.axis?.code !== productFormData?.axis?.code;

    if (hasChanges) {
      const request = { id: Number(productFormData?.id), axis: productFormData?.axis?.code };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/price-trend/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/loaded/${id}`, { replace: true });
  };

  const handleClickOption = (item: OptionDataType, key: string) => {
    setProductFormData({ ...productFormData, [key]: item } as ProductDetailResponse);
  };

  return (
    <>
      <MenuHeader title="내차판매" />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="pt-6 text-[#414141]">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">가변축을 선택해주세요.</h3>
            <RoundBadge text="4/9" />
          </div>

          <div className="text-base pt-[30px] pb-24">
            <RadioBoxList
              optionData={productEnum?.axis || []}
              value={productFormData?.axis?.code || ''}
              onClickItem={(item) => handleClickOption(item, 'axis')}
            />
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="시세 조회하기"
          disabledRight={!productFormData?.axis}
          onClickRightButton={handleClickNext}
        />
      </div>
      <BasicPopup
        isShow={isShowExitConfirmPopup}
        title="정말 나가시겠어요?"
        textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleCloseExitPopup}
        textRightBtn="나가기"
        onClickRightBtn={handleClickExitBtn}
      />
    </>
  );
};

export default AxisForm;
