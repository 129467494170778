import { AxiosError } from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import React, { Dispatch, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import apiManager from '@/api/AxiosInstance';
import BasicButton from '@/components/Common/Button/BasicButton';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import CheckBoxList, { OptionType } from '@/components/Common/Input/CheckBox/CheckBoxList';
import RadioList from '@/components/Common/Input/Radio/RadioList';
import MultiSelectBox from '@/components/Common/Input/SelectBox/MultiSelectBox';
import SliderComponent from '@/components/Common/Input/Slider/SliderComponent';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ClickedToast from '@/components/Common/Popup/ClickedToast';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import { BellSolidIcon, ChevronLeftIcon, SearchOutlineIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { PRODUCT_SERARCH_PATH } from '@/const/common';
import { ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS } from '@/const/errorCode';
import {
  COMMON_TOAST_ERROR,
  DISTANCE_IS_LESS_THAN_2_MILLION,
  LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE,
  MAX_IS_BIGGER_ERROR,
  MIN_IS_SMALLER_ERROR,
  TONS_IS_LESS_THAN_27,
  TONS_IS_OVER_1,
  YEAR_IS_LESS_THAN_CURRENT,
  YEAR_IS_OVER_2000,
} from '@/const/errorMessage';
import { positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { productsSearchParamsAtom } from '@/store/products';
import { getCurrentYear, updateSearchParams } from '@/utils/common';

const ProductsSearch = () => {
  const navigate = useNavigate();
  const [filterInfo, setFilterInfo] = useState<ProductsFilterInfo>({
    axis: [{ axis: { code: '', desc: '' }, count: 0 }],
    loaded: [{ loaded: { code: '', desc: '' }, count: 0 }],
    manufacturerCategories: [{ manufacturerCategories: { id: 0, code: '', name: '' }, count: 0 }],
    transmission: [{ transmission: { code: '', desc: '' }, count: 0 }],
  });

  const { showToast } = useToastContext();
  const authRedirect = useAuthRedirect();

  const [searchParams] = useAtom(productsSearchParamsAtom);
  const setSearchParams = useSetAtom(productsSearchParamsAtom);

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [currentYear, setCurrentYear] = useState(getCurrentYear());
  const [isShow, setIsShow] = useState(false);
  const [isShowToast, setIsShowToast] = useState(false);
  const [isShowBasicPopup, setIsShowBasicPopup] = useState(false);
  const [basicPopupContent, setBasicPopupContent] = useState('');

  const [optionData, setOptionData] = useState<OptionType[]>([]);

  const [axisOptionData, setAxisOptionData] = useState<OptionType[]>([]);
  const [transmissionOptionData, setTransmissionOptionData] = useState<OptionType[]>([]);

  const [title, setTitle] = useState('');
  const [count, setCount] = useState<number>(0);
  const [loadedMinInnerLengthError, setMinLoadedInnerLengthError] = useState(false);
  const [loadedMaxInnerLengthError, setLoadedMaxInnerLengthError] = useState(false);
  const [loadedMinInnerLengthErrorMsg, setMinLoadedInnerLengthErrorMsg] = useState('');
  const [loadedMaxInnerLengthErrorMsg, setLoadedMaxInnerLengthErrorMsg] = useState('');

  const [minYearError, setMinYearError] = useState(false);
  const [maxYearError, setMaxYearError] = useState(false);
  const [minTonsError, setMinTonsError] = useState(false);
  const [maxTonsError, setMaxTonsError] = useState(false);
  const [minDistanceError, setMinDistanceError] = useState(false);
  const [maxDistanceError, setMaxDistanceError] = useState(false);

  const [minYearErrorMsg, setMinYearErrorMsg] = useState('');
  const [maxYearErrorMsg, setMaxYearErrorMsg] = useState('');
  const [minTonsErrorMsg, setMinTonsErrorMsg] = useState('');
  const [maxTonsErrorMsg, setMaxTonsErrorMsg] = useState('');
  const [minDistanceErrorMsg, setMinDistanceErrorMsg] = useState('');
  const [maxDistanceErrorMsg, setMaxDistanceErrorMsg] = useState('');

  const [selectedManufacturerCategories, setSelectedManufacturerCategories] = useState<OptionDataType[]>([]);
  const [selectedLoadeds, setSelectedLoadeds] = useState<OptionDataType[]>([]);

  const keyValueList: KeyValueListType = {
    manufacturerCategories: '제조사',
    loaded: '적재함 종류',
  };

  const filterData = (dataArray: any[], searchParams: any[] | Set<string | number>, isLoadedData: boolean) => {
    const filteredArray = dataArray.map((item) => ({
      code: isLoadedData ? item.loaded.code : item.manufacturerCategories.code || '',
      id: isLoadedData ? undefined : item.manufacturerCategories.id || 0,
      desc: isLoadedData ? item.loaded.desc : item.manufacturerCategories.name || '',
      count: item.count,
    }));

    const resultArray: { code: any; desc: any; count: any }[] = [];

    if (isLoadedData) {
      searchParams.forEach((param) => {
        const foundItem = filteredArray.find((item) => item.code === param);
        if (foundItem) {
          resultArray.push(foundItem);
        }
      });
    } else {
      searchParams.forEach((param: string | number) => {
        const foundItem = filteredArray.find((item) => item.id === param);
        if (foundItem) {
          resultArray.push(foundItem);
        }
      });
    }

    return resultArray;
  };

  useEffect(() => {
    apiManager
      .get('/api/v1/public/products/filter-info', { params: { salesType: 'ALL' } })
      .then((response) => {
        const responseData: ProductsFilterInfo = response.data;
        setFilterInfo(responseData);

        const transformedAxisData = responseData.axis.map((item) => ({
          code: item.axis.code,
          desc: item.axis.desc,
          count: item.count,
        }));
        setAxisOptionData(transformedAxisData);

        const transformedTransmissionData = responseData.transmission.map((item) => ({
          code: item.transmission.code,
          desc: item.transmission.desc,
          count: item.count,
        }));
        setTransmissionOptionData(transformedTransmissionData);

        const selectedLoadedItems = filterData(responseData.loaded, searchParams.loaded, true);
        setSelectedLoadeds(selectedLoadedItems);

        const selectedManufacturerItems = filterData(
          responseData.manufacturerCategories,
          searchParams.manufacturerCategoriesId,
          false,
        );
        setSelectedManufacturerCategories(selectedManufacturerItems);
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  }, []);

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => {
      getProductsCount();
    }, 300);

    setDebounceTimer(timer);
  }, [searchParams]);

  const getProductsCount = async () => {
    if (searchParams) {
      const {
        keyword,
        manufacturerCategoriesId,
        minYear,
        maxYear,
        minTons,
        maxTons,
        minDistance,
        maxDistance,
        axis,
        transmission,
        loaded,
        minLoadedInnerLength,
        maxLoadedInnerLength,
        salesType,
      } = searchParams;
      const queryParams = new URLSearchParams({
        keyword: keyword || '',
        manufacturerCategoriesId: Array.from(manufacturerCategoriesId).toString(),
        minYear: minYear || '',
        maxYear: maxYear || '',
        minTons: minTons || '',
        maxTons: maxTons || '',
        minDistance: minDistance !== '' ? String(Number(minDistance) * 10000) : '',
        maxDistance: maxDistance !== '' ? String(Number(maxDistance) * 10000) : '',
        axis: axis || '',
        transmission: transmission || '',
        loaded: Array.from(loaded).toString(),
        minLoadedInnerLength: minLoadedInnerLength || '',
        maxLoadedInnerLength: maxLoadedInnerLength || '',
        salesType: salesType,
      });

      apiManager
        .get('/api/v1/public/products/count?', { params: queryParams })
        .then((response) => {
          if (response) {
            setCount(response.data);
          }
        })
        .catch((error) => {
          setCount(0);
        });
    }
  };

  const onChangeKeywordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber: boolean) => {
    const { name, value } = e.target;
    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(value) && !value.startsWith('-')) {
        const str = value === '' ? null : value;
        setSearchParams({ ...searchParams, [name]: str });

        switch (name) {
          case 'minYear':
            if (Number(value) > Number(searchParams.maxYear)) {
              setMinYearError(true);
              setMinYearErrorMsg(MIN_IS_SMALLER_ERROR);
              return;
            } else if (Number(value) < 2000) {
              setMinYearError(true);
              setMinYearErrorMsg(YEAR_IS_OVER_2000);
              return;
            } else {
              setMinYearError(false);
              setMinYearErrorMsg('');
            }
            break;
          case 'maxYear':
            if (Number(value) < Number(searchParams.minYear)) {
              setMaxYearError(true);
              setMaxYearErrorMsg(MAX_IS_BIGGER_ERROR);
              return;
            } else if (Number(value) > currentYear + 1) {
              setMaxYearError(true);
              setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
              return;
            } else {
              setMaxYearError(false);
              setMaxYearErrorMsg('');
            }
            break;
          case 'minDistance':
            if (!searchParams.maxDistance) return;
            if (Number(value) > Number(searchParams.maxDistance)) {
              setMinDistanceError(true);
              setMinDistanceErrorMsg(MIN_IS_SMALLER_ERROR);
              return;
            } else if (Number(value) > 200) {
              setMinDistanceError(true);
              setMinDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
              return;
            } else {
              setMinDistanceError(false);
              setMinDistanceErrorMsg('');
            }
            break;
          case 'maxDistance':
            if (!searchParams.minDistance) return;
            if (Number(value) < Number(searchParams.minDistance)) {
              setMaxDistanceError(true);
              setMaxDistanceErrorMsg(MAX_IS_BIGGER_ERROR);
              return;
            } else if (Number(value) > 200) {
              setMaxDistanceError(true);
              setMaxDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
              return;
            } else {
              setMaxDistanceError(false);
              setMaxDistanceErrorMsg('');
            }
            break;
        }
      }
    } else if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = value.trim() === '' ? null : value.trim();
      setSearchParams({ ...searchParams, [name]: str });

      switch (name) {
        case 'minTons':
          if (Number(value) > Number(searchParams.maxTons)) {
            setMinTonsError(true);
            setMinTonsErrorMsg(MIN_IS_SMALLER_ERROR);
            return;
          } else if (Number(value) < 1) {
            setMinTonsError(true);
            setMinTonsErrorMsg(TONS_IS_OVER_1);
            return;
          } else {
            setMinTonsError(false);
            setMinTonsErrorMsg('');
          }
          break;
        case 'maxTons':
          if (Number(value) < Number(searchParams.minTons)) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(MAX_IS_BIGGER_ERROR);
            return;
          } else if (Number(value) > 27) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
            return;
          } else {
            setMaxTonsError(false);
            setMaxTonsErrorMsg('');
          }
          break;
        case 'minLoadedInnerLength':
          if (!searchParams.maxLoadedInnerLength) return;

          if (Number(value) > Number(searchParams.maxLoadedInnerLength)) {
            setMinLoadedInnerLengthError(true);
            setMinLoadedInnerLengthErrorMsg(MIN_IS_SMALLER_ERROR);
          }
          break;
        case 'maxLoadedInnerLength':
          if (!searchParams.minLoadedInnerLength) return;

          if (Number(value) < Number(searchParams.minLoadedInnerLength)) {
            setLoadedMaxInnerLengthError(true);
            setLoadedMaxInnerLengthErrorMsg(MAX_IS_BIGGER_ERROR);
          }
          break;
      }
    }
  };

  const onChangeSliderInput = (e: Event, values: number | number[], key: string) => {
    if (!Array.isArray(values)) {
      return;
    }
    setSearchParams({ ...searchParams, [`min${key}`]: values[0].toString(), [`max${key}`]: values[1].toString() });
  };

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setSearchParams({ ...searchParams, [key]: newVal.code });
  };

  const onClickSelectBox = (key: string) => {
    if (!filterInfo) {
      return;
    }

    if (key === 'manufacturerCategories') {
      if (filterInfo.manufacturerCategories) {
        setOptionData(
          filterInfo?.manufacturerCategories.map(
            (item) =>
              ({
                id: item.manufacturerCategories.id,
                code: item.manufacturerCategories.code,
                desc: item.manufacturerCategories.name,
                count: item.count,
              } as OptionType),
          ),
        );
      }
    } else {
      setOptionData(
        filterInfo.loaded.map(
          (item) =>
            ({
              code: item.loaded.code,
              desc: item.loaded.desc,
              count: item.count,
            } as OptionType),
        ),
      );
    }
    setTitle(key);
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    if (key === 'manufacturerCategories' && item.id) {
      if (
        selectedManufacturerCategories.includes(item as OptionDataType) ||
        selectedManufacturerCategories.some((selectedItem) => selectedItem.id === item.id)
      ) {
        onClickDeleteOptionData(setSelectedManufacturerCategories, item);
      } else {
        setSelectedManufacturerCategories((prevItems) => {
          return [...prevItems, item];
        });
      }
    } else {
      if (selectedLoadeds.includes(item) || selectedLoadeds.some((selectedItem) => selectedItem.code === item.code)) {
        onClickDeleteOptionData(setSelectedLoadeds, item);
      } else {
        setSelectedLoadeds((prevItems) => {
          return [...prevItems, item];
        });
      }
    }
  };

  const removeItem = (item: OptionDataType, key: string) => {
    setSearchParams((prev) => {
      if (prev[key]) {
        const newSet = new Set(prev[key]);
        const itemValue = item.id || item.code;
        newSet.delete(itemValue);
        return {
          ...prev,
          [key]: newSet,
        };
      }
      return prev;
    });
  };

  const onClickDeleteOptionData = (
    setData: Dispatch<React.SetStateAction<OptionDataType[]>>,
    unselectedItem: OptionDataType,
  ) => {
    setData((prevItems) => {
      if (!prevItems) {
        return [];
      }

      const updatedItems = prevItems.filter((item) => item.code !== unselectedItem.code);
      return updatedItems;
    });
  };

  const applySelectedPopUp = () => {
    setIsShow(false);
    setSearchParams({
      ...searchParams,
      manufacturerCategoriesId: new Set(selectedManufacturerCategories.map((item) => Number(item.id))),
      loaded: new Set(selectedLoadeds.map((item) => item.code?.toString() || '')),
    });
  };

  const clear = () => {
    setSearchParams({
      keyword: '',
      manufacturerCategoriesId: new Set([]),
      minYear: '2000',
      maxYear: (currentYear + 1).toString(),
      minTons: '1',
      maxTons: '27',
      minDistance: '0',
      maxDistance: '200',
      axis: '',
      transmission: '',
      loaded: new Set([]),
      minLoadedInnerLength: '',
      maxLoadedInnerLength: '',
      salesType: 'ALL',
      productsSortType: { code: 'CREATED_DATE', desc: '기본 정렬순' },
      page: 1,
    });
    setSelectedLoadeds([]);
    setSelectedManufacturerCategories([]);
    setLoadedMaxInnerLengthError(false);
    setMinLoadedInnerLengthError(false);
  };

  const handleClickSearch = () => {
    navigate('/products', { state: { isSearch: true } });
  };

  useEffect(() => {
    const { loaded } = searchParams;
    if (loaded.size > 0) {
      setIsShowToast(count === 0);
    } else {
      setIsShowToast(false);
    }
  }, [searchParams, count]);

  const onClickToastPopup = () => {
    const loadedDescArray = Array.from(searchParams.loaded).map((code) => {
      const matchingItem = filterInfo.loaded.map((item) => item.loaded).filter((item) => item.code === code)[0].desc;
      return matchingItem;
    });

    const loadedInnerLengthRange =
      searchParams?.minLoadedInnerLength && searchParams?.maxLoadedInnerLength
        ? `/${searchParams.minLoadedInnerLength}m ~ ${searchParams.maxLoadedInnerLength}m`
        : '';

    setBasicPopupContent(
      loadedDescArray
        .map((item) => item)
        .join(',')
        .concat(loadedInnerLengthRange),
    );
    setIsShowBasicPopup(true);
  };

  const onClickSetInterest = () => {
    setIsShowToast(false);
    setIsShowBasicPopup(false);

    if (!authRedirect(PRODUCT_SERARCH_PATH)) {
      return;
    } else {
      mutate(searchParams);
    }
  };

  const setInterestProduct = async (searchParams: ProductsSearch): Promise<any> => {
    const {
      axis,
      loaded,
      maxDistance,
      maxLoadedInnerLength,
      maxTons,
      maxYear,
      minDistance,
      minLoadedInnerLength,
      manufacturerCategoriesId,
      minTons,
      minYear,
      transmission,
    } = searchParams;

    const manufacturerCategoriesIdString = Array.from(manufacturerCategoriesId).join(',');
    const loadedString = Array.from(loaded).join(',');

    const requestData = {
      axis,
      loaded: loadedString === '' ? null : loadedString,
      maxDistance: maxDistance === '' || maxDistance === '200' ? null : Number(maxDistance),
      maxLoadedInnerLength: maxLoadedInnerLength === '' ? null : Number(maxLoadedInnerLength),
      maxTons: Number(maxTons) === 27 ? null : Number(maxTons),
      maxYear: Number(maxYear) === currentYear + 1 ? null : Number(maxYear),
      minDistance: minDistance === '' || minDistance === '0' ? '0' : Number(minDistance),
      minLoadedInnerLength: minLoadedInnerLength === '' ? null : Number(minLoadedInnerLength),
      manufacturerCategoriesId: manufacturerCategoriesIdString === '' ? null : manufacturerCategoriesIdString,
      minTons: Number(minTons) === 1 ? null : Number(minTons),
      minYear: Number(minYear) === 2000 ? null : Number(minYear),
      transmission,
    };
    const { data } = await apiManager.post(`/api/v1/interest-product-notification-settings`, requestData);
    return data;
  };

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(setInterestProduct, {
    onSuccess: () => {
      showToast('관심 차량이 등록되었습니다.', 'success', 'bottom');
    },
    onError: (error: AxiosError) => {
      if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      const { updatedParams } = updateSearchParams(searchParams, key, valueNum);

      setSearchParams(updatedParams);
    }
  };

  useEffect(() => {
    if (searchParams.minLoadedInnerLength && searchParams.maxLoadedInnerLength) {
      if (
        Number(searchParams.minLoadedInnerLength) <= Number(searchParams.maxLoadedInnerLength) &&
        Number(searchParams.minLoadedInnerLength) >= 1 &&
        Number(searchParams.maxLoadedInnerLength) >= 1
      ) {
        setMinLoadedInnerLengthError(false);
        setMinLoadedInnerLengthErrorMsg('');
        setLoadedMaxInnerLengthError(false);
        setLoadedMaxInnerLengthErrorMsg('');
      }
    }

    if (searchParams.minLoadedInnerLength && Number(searchParams.minLoadedInnerLength) < 1) {
      setMinLoadedInnerLengthError(true);
      setMinLoadedInnerLengthErrorMsg(LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE);
    }

    if (searchParams.maxLoadedInnerLength && Number(searchParams.maxLoadedInnerLength) < 1) {
      setLoadedMaxInnerLengthError(true);
      setLoadedMaxInnerLengthErrorMsg(LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE);
    }
  }, [searchParams.minLoadedInnerLength, searchParams.maxLoadedInnerLength]);

  useEffect(() => {
    if (searchParams.minYear && searchParams.maxYear) {
      if (Number(searchParams.minYear) <= Number(searchParams.maxYear)) {
        setMinYearError(false);
        setMinYearErrorMsg('');
        setMaxYearError(false);
        setMaxYearErrorMsg('');
      }
      if (Number(searchParams.minYear) < 2000) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(searchParams.minYear) > currentYear + 1) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
      if (Number(searchParams.maxYear) < 2000) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(searchParams.maxYear) > currentYear + 1) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
    }
  }, [searchParams.minYear, searchParams.maxYear]);

  useEffect(() => {
    if (searchParams.minTons && searchParams.maxTons) {
      if (Number(searchParams.minTons) <= Number(searchParams.maxTons)) {
        setMinTonsError(false);
        setMinTonsErrorMsg('');
        setMaxTonsError(false);
        setMaxTonsErrorMsg('');
      }
      if (Number(searchParams.minTons) < 1) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(searchParams.maxTons) < 1) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(searchParams.minTons) > 27) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
      if (Number(searchParams.maxTons) > 27) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
    }
  }, [searchParams.minTons, searchParams.maxTons]);

  useEffect(() => {
    if (searchParams.minDistance && searchParams.maxDistance) {
      if (Number(searchParams.minDistance) <= Number(searchParams.maxDistance)) {
        setMinDistanceError(false);
        setMinDistanceErrorMsg('');
        setMaxDistanceError(false);
        setMaxDistanceErrorMsg('');
      }
      if (Number(searchParams.minDistance) > 200) {
        setMinDistanceError(true);
        setMinDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
      }
      if (Number(searchParams.maxDistance) > 200) {
        setMaxDistanceError(true);
        setMaxDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
      }
    }
  }, [searchParams.minDistance, searchParams.maxDistance]);

  return (
    <>
      <div className={`flex flex-col justify-between w-full`}>
        <div className="flex items-center px-4 py-4 cursor-pointer">
          <div className="mr-5" onClick={handleClickSearch}>
            <ChevronLeftIcon color={colors.gray[9]}></ChevronLeftIcon>
          </div>
          <div className="z-10 flex-1 searchBox flex bg-gray-1 rounded-sm items-center pr-4 text-gray-6 rounded-lg">
            <input
              name="keyword"
              className="w-11/12 bg-gray-1 text-gray-8 mx-4 my-2"
              type="text"
              placeholder="차량을 검색해보세요."
              value={searchParams.keyword || ''}
              onChange={onChangeKeywordInput}
              style={{ outline: 'none' }}
            />
            <SearchOutlineIcon color={colors.gray[6]} strokeWidth={0.1}></SearchOutlineIcon>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">연식</p>
          <SliderComponent
            name="Year"
            value={[Number(searchParams.minYear), Number(searchParams.maxYear)]}
            onChange={(event: Event, value: number | number[]) => onChangeSliderInput(event, value, 'Year')}
            step={1}
            min={2000}
            max={currentYear + 1}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minYear"
              placeholder="최소 연식"
              value={searchParams.minYear || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, true)}
              error={minYearError}
              errorMsg={minYearErrorMsg}
              height={36}
              fontSize={18}
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxYear"
              placeholder="최대 연식"
              value={searchParams.maxYear || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, true)}
              error={maxYearError}
              errorMsg={maxYearErrorMsg}
              height={36}
              fontSize={18}
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">톤수</p>
          <SliderComponent
            name="Tons"
            value={[Number(searchParams.minTons) || 0, Number(searchParams.maxTons) || 27]}
            onChange={(e, value) => onChangeSliderInput(e, value, 'Tons')}
            step={1}
            min={1}
            max={27}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minTons"
              placeholder="최소 톤수"
              value={searchParams.minTons || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, false)}
              error={minTonsError}
              errorMsg={minTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxTons"
              placeholder="최대 톤수"
              value={searchParams.maxTons || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, false)}
              error={maxTonsError}
              errorMsg={maxTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t-8 border-gray-1">
          <div className="flex flex-col justify-center pt-4 pb-4 px-4">
            <p className="font-semibold text-gray-8">제조사</p>
            <MultiSelectBox
              label="제조사 선택"
              placeholder="제조사 선택"
              items={selectedManufacturerCategories}
              setItems={setSelectedManufacturerCategories}
              onClickDeleteBtn={removeItem}
              itemName="manufacturerCategoriesId"
              tagWidth={120}
              onClickSelectBox={onClickSelectBox}
              selectBoxKey="manufacturerCategories"
            />
          </div>
        </div>

        <div className="text-base border-t-8 border-gray-1">
          <div className="flex flex-col justify-center pt-4 pb-4 px-4">
            <p className="font-semibold text-gray-8">적재함 종류</p>
            <MultiSelectBox
              label="적재함 종류 선택"
              placeholder="적재함 종류 선택"
              items={selectedLoadeds}
              setItems={setSelectedLoadeds}
              onClickDeleteBtn={removeItem}
              itemName="loaded"
              tagWidth={170}
              onClickSelectBox={onClickSelectBox}
              selectBoxKey="loaded"
            />
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 py-[30px] relative">
          <p className="font-semibold mb-2 text-gray-8">적재함 길이 (내측 사이즈)</p>
          <div className="w-full flex justify-center items-center mb-2">
            <TextInputLabelOff
              name="minLoadedInnerLength"
              placeholder="최소 길이"
              value={searchParams.minLoadedInnerLength || ''}
              onChange={(e) => onChangeFormInput(e, false)}
              onBlur={(e) => handleInputBlur(e, 'minLoadedInnerLength')}
              error={loadedMinInnerLengthError}
              errorMsg={loadedMinInnerLengthErrorMsg}
              height={36}
              fontSize={18}
              suffix="m"
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxLoadedInnerLength"
              placeholder="최대 길이"
              value={searchParams.maxLoadedInnerLength || ''}
              onChange={(e) => onChangeFormInput(e, false)}
              onBlur={(e) => handleInputBlur(e, 'maxLoadedInnerLength')}
              error={loadedMaxInnerLengthError}
              errorMsg={loadedMaxInnerLengthErrorMsg}
              height={36}
              fontSize={18}
              suffix="m"
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 py-[30px]">
          <p className="font-semibold mb-2 text-gray-8">가변축</p>
          <div className="px-1">
            <RadioList
              name="axis"
              list={axisOptionData}
              value={searchParams.axis || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'axis');
              }}
            ></RadioList>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">주행거리</p>
          <SliderComponent
            name="Distance"
            value={[Number(searchParams?.minDistance) || 0, Number(searchParams?.maxDistance) || 200]}
            onChange={(event, value) => onChangeSliderInput(event, value, 'Distance')}
            step={5}
            min={0}
            max={200}
          ></SliderComponent>
          <div className="flex justify-center pb-3">
            <TextInputLabelOff
              name="minDistance"
              placeholder={`최소 주행거리`}
              value={searchParams.minDistance || ''}
              onChange={(e) => onChangeFormInput(e, true)}
              error={minDistanceError}
              errorMsg={minDistanceErrorMsg}
              inputMode="numeric"
              suffix="만km"
              maxLength={3}
            />
            <p className="text-[#919191] text-sm mx-2">~</p>
            <TextInputLabelOff
              name="maxDistance"
              placeholder={`최대 주행거리`}
              value={searchParams.maxDistance || ''}
              onChange={(e) => onChangeFormInput(e, true)}
              error={maxDistanceError}
              errorMsg={maxDistanceErrorMsg}
              inputMode="numeric"
              suffix="만km"
              maxLength={3}
            />
          </div>
        </div>

        <div className={`text-base border-t border-gray-3 px-4 pt-[30px] ${isShowToast ? 'pb-40' : 'pb-24'}`}>
          <p className="font-semibold mb-2 text-gray-8">변속기</p>
          <div className="px-1">
            <RadioList
              name="transmission"
              list={transmissionOptionData}
              value={searchParams.transmission || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'transmission');
              }}
            ></RadioList>
          </div>
        </div>
      </div>
      <DualFooterButton
        leftButtonText="초기화"
        onClickLeftButton={clear}
        rightButtonText={`차량보기 ${count}대`}
        onClickRightButton={handleClickSearch}
      ></DualFooterButton>

      <ClickedToast
        isShow={isShowToast}
        icon={<BellSolidIcon color={colors.gray[0]} />}
        text="원하는 차량이 없으신가요? 버튼을 누르고 해당 조건으로 신규 알림을 받아보세요."
        onClickBtn={onClickToastPopup}
      ></ClickedToast>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-[82px]">
          <CheckBoxList
            optionData={optionData}
            selectedData={title === 'loaded' ? selectedLoadeds : selectedManufacturerCategories}
            onClickItem={(item) => onClickItem(item, title)}
          ></CheckBoxList>
          <div className="flex items-center justify-center fixed w-full px-3 pb-4 pt-0 mx-auto my-0  bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white">
            <BasicButton
              name="적용"
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={16}
              fontWeight="bold"
              onClick={() => applySelectedPopUp()}
            ></BasicButton>
          </div>
        </div>
      </DownToUpDetailPopup>

      <BasicPopup
        isShow={isShowBasicPopup}
        title="해당 차량이 신규 입고되면<br/>알림을 보내드릴까요?"
        textContent={basicPopupContent}
        textLeftBtn="취소"
        onClickLeftBtn={() => setIsShowBasicPopup(false)}
        textRightBtn="알림 받기"
        onClickRightBtn={() => onClickSetInterest()}
      ></BasicPopup>
    </>
  );
};

export default ProductsSearch;
