import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import {
  ChatOutlineIcon,
  EllipsisHorizontalIcon,
  HomeOutlineIcon,
  SearchOutlineIcon,
  SellOutlineIcon,
} from '@/components/Icon';
import { useChatContext } from '@/contexts/Chat/ChatContext';
import { isExistNotReadChatMessageAtom } from '@/store/chatting';

type FooterProps = {
  isMain?: boolean;
};

const Footer = ({ isMain }: FooterProps) => {
  const { unreadMessages } = useChatContext();
  const [isExistNotReadChatMessage] = useAtom(isExistNotReadChatMessageAtom);
  const setIsExistNotReadChatMessage = useSetAtom(isExistNotReadChatMessageAtom);

  const menuList: Menu[] = [
    {
      name: '홈',
      key: 'home',
      link: '/',
      icon: HomeOutlineIcon,
    },
    {
      name: '내차구매',
      key: 'purchase',
      link: '/products',
      icon: SearchOutlineIcon,
    },
    {
      name: '내차판매',
      key: 'sale',
      link: '/products/sales',
      icon: SellOutlineIcon,
    },
    {
      name: '채팅',
      key: 'chat',
      link: '/chatting',
      icon: ChatOutlineIcon,
    },
    {
      name: '더보기',
      key: 'more',
      link: '/more',
      icon: EllipsisHorizontalIcon,
    },
  ];

  useEffect(() => {
    const isEmpty = (obj: { [chatRoomId: number]: number }) => {
      return Object.keys(obj).length === 0;
    };

    if (!isEmpty(unreadMessages)) {
      setIsExistNotReadChatMessage(Object.values(unreadMessages).some((item) => item > 0));
    }
  }, [unreadMessages]);

  return (
    <>
      <footer className="fixed bottom-0 left-0 right-0 bg-white w-full mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] text-sm">
        {isMain && (
          <nav>
            <ul className="flex shadow-[0_-2px_4px_rgba(0,0,0,0.05)] py-4">
              {menuList.map((menu, index) => (
                <li className="w-1/5 text-center" key={menu.key}>
                  <NavLink
                    to={menu.link}
                    className={`flex flex-col justify-center items-center relative ${
                      menu.link === window.location.pathname ? 'active-link' : ''
                    }`}
                    style={{
                      color: menu.link === window.location.pathname ? '#1e40af' : 'gray',
                      fontWeight: menu.link === window.location.pathname ? '600' : 'normal',
                    }}
                  >
                    <div className="relative">
                      {React.createElement(menu.icon, {
                        color: menu.link === window.location.pathname ? '#1e40af' : '#919191',
                        strokeWidth: 0.1,
                        alt: `${menu.name} 아이콘`,
                        width: '28',
                        height: '28',
                      })}
                      {isExistNotReadChatMessage && menu.key === 'chat' && (
                        <div className="absolute top-0 right-[-4px] w-1 h-1 bg-red rounded"></div>
                      )}
                    </div>
                    {menu.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </footer>
    </>
  );
};

export default Footer;
