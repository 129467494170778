import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Error from '@/components/Error/Error';
import Footer from '@/components/Footer';
import MenuHeader from '@/components/Header/MenuHeader';
import { ErrorCircleIcon } from '@/components/Icon';
import MyCarNumberInput from '@/components/Products/Sale/MyCarNumberInput';
import MyListing from '@/components/Products/Sale/MyListing';
import OneStopServiceRequestButton from '@/components/Products/Sale/OneStopServiceRequestButton';
import OwnerNameInput from '@/components/Products/Sale/OwnerNameInput';
import { colors } from '@/const/colors/index';
import { useResetProductsForm } from '@/store/products';

interface ErrorInfo {
  licenseNumberError: boolean;
  licenseNumberErrorMessage: string;
  ownerNameError: boolean;
  ownerNameErrorMessage: string;
}

const MyCarSale = () => {
  const navigate = useNavigate();
  const [ownerInfo, setOwnerInfo] = useState<OwnerInfo>({
    licenseNumber: '',
    name: '',
  });

  const resetProductsForm = useResetProductsForm();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>();
  const [pageNum, setPageNum] = useState(1);
  const location = useLocation();

  const updatePage = (pageNum: number) => {
    setPageNum(pageNum);
  };
  const goPrev = () => {
    if (pageNum === 1) {
      navigate(-1);
    } else {
      setPageNum((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (location.state?.pageNum) {
      updatePage(location.state.pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    resetProductsForm();
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between w-full">
        <MenuHeader
          title="내차판매"
          isMain={pageNum === 1}
          onClickPrevBtn={() => {
            goPrev();
          }}
        ></MenuHeader>
        <div>
          {pageNum === 1 && (
            <div className="pt-[60px] pb-20">
              <MyCarNumberInput
                updatePage={updatePage}
                ownerInfo={ownerInfo}
                setOwnerInfo={setOwnerInfo}
                errorInfo={errorInfo}
                setErrorInfo={setErrorInfo}
              ></MyCarNumberInput>
              <OneStopServiceRequestButton></OneStopServiceRequestButton>
              <MyListing></MyListing>
            </div>
          )}
          {pageNum === 2 && (
            <OwnerNameInput
              updatePage={updatePage}
              ownerInfo={ownerInfo}
              setOwnerInfo={setOwnerInfo}
              errorInfo={errorInfo}
              setErrorInfo={setErrorInfo}
            ></OwnerNameInput>
          )}
          {pageNum === 3 && (
            <div className="h-screen">
              <Error
                icon={<ErrorCircleIcon color={colors.gray[5]} width={144} height={144}></ErrorCircleIcon>}
                title="오류가 발생했습니다"
                description="현재 일시적인 시스템 오류로 <br/> 차량 정보를 불러올 수 없습니다. <br/>잠시 후 다시 시도해주세요."
              ></Error>
            </div>
          )}
        </div>
        <Footer isMain={pageNum === 1}></Footer>
      </div>
    </>
  );
};

export default MyCarSale;
