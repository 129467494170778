import React from 'react';

import { colors } from '@/const/colors';
import { Button } from '@mui/material';

export interface ButtonProps {
  name: string;
  icon?: React.ReactNode;
  bgColor: string;
  borderColor: string;
  textColor: string;
  fontSize: number;
  fontWeight: 'normal' | 'medium' | 'semibold' | 'bold';
  onClick: () => void;
  width?: number | string;
  height?: number;
  border?: string;
  borderRadius?: number;
  isDisabled?: boolean;
  className?: string;
  shadow?: boolean;
}

function BasicButton({
  name,
  icon,
  bgColor,
  border,
  borderColor,
  textColor,
  fontSize,
  fontWeight,
  onClick,
  width,
  height,
  borderRadius,
  isDisabled,
  className,
  shadow = false,
}: ButtonProps) {
  return (
    <Button
      className="w-full"
      onClick={() => onClick()}
      sx={{
        fontSize: fontSize,
        lineHeight: `${fontSize + 4}px`,
        color: textColor,
        bgcolor: bgColor,
        borderRadius: `${borderRadius ? borderRadius + 'px' : '10px'}`,
        border: `${border ? border : '1px solid'} ${borderColor}`,
        '&:hover': {
          backgroundColor: bgColor,
        },
        width: width ? width : '100%',
        height: height ? height : '50',
        '&.Mui-disabled': {
          color: colors.gray[6],
          backgroundColor: colors.gray[4],
          border: 'none',
        },
        boxShadow: shadow ? '0 4px 6px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.3)' : '',
      }}
      disableRipple
      disabled={isDisabled}
    >
      <div className={`flex justify-center items-center font-${fontWeight} ${className}`}>
        {icon && <span className="mr-2">{icon}</span>}
        <span className="break-keep">{name}</span>
      </div>
    </Button>
  );
}

export default BasicButton;
