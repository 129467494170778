// 관심차량 이미 등록된 차량의 경우
export const ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS = 'ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS';

//판매 대행 서비스를 이미 신청한 경우
export const ALREADY_ONE_STOP_SERVICE = 'ALREADY_ONE_STOP_SERVICE';

export const ALREADY_REQUEST_TRANSFER_AGENCY_SERVICE = 'ALREADY_REQUEST_TRANSFER_AGENCY_SERVICE';

export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';
export const ALREADY_SALE_PRODUCTS = 'ALREADY_SALE_PRODUCTS';

// axios 공통 에러
export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS';
