import React from 'react';
import { Link } from 'react-router-dom';

const PreSaleCheckList = () => {
  return (
    <div className="min-w-[320px]">
      <div className="w-full flex justify-center items-center">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_pre_sale_checklist_cover.png"
          alt="판매 전 체크사항 커버"
          className="w-full"
        />
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">판매 전 차량 시세를 확인하세요</h3>
        <p className="pb-8 leading-8">
          직트럭에서는 다년간 쌓아온 내부 데이터베이스를 활용해 약 2만건의 적절한 중고화물차 시세를 제공하고 있어요.
        </p>
        <p className="pb-8 leading-8">[내차판매], [시세검색] 탭에서 시세를 확인 할 수 있어요.</p>
        <div className="text-xs pb-8">
          <p className="text-gray-500 mb-4">관련 서비스</p>
          <Link to="/products/sales">
            <span className="bg-gray-100 py-2 px-4 rounded-lg mr-2">내차 판매</span>
          </Link>
          <Link to="/price-trend">
            <span className="bg-gray-100 py-2 px-4 rounded-lg mr-2">시세 검색</span>
          </Link>
        </div>
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">시세에 영향을 주는 요인은?</h3>
        <p className="pb-8 leading-8">
          화물차 시세에 영향을 주는 요인은 사고 유무, 차량의 주행 거리, 적재함의 종류와 길이, 차량 옵션, 차량 수리
          유무가 있어요.
        </p>
        <ol className="leading-8">
          <li className="pb-8">
            1. 사고 유무
            <p>사고 후에 수리를 했더라도 사고 범위에 따라 시세 차이가 커질 수 있어요.</p>
          </li>

          <li className="pb-8">
            2. 주행 거리
            <p>
              주행 거리는 차량 가격에 큰 영향을 주는 부분입니다. 같은 연식의 차량이라도 주행 거리에 따라서 시세 차이가
              커집니다.
              <br />
              예) 5톤 차량 기준 평균 1년치 주행 거리는 8만km 내외입니다.
            </p>
          </li>
          <li className="pb-8">
            3. 적재함 종류/길이
            <p>
              영업용 화물차는 일반 승용차와는 다르게 동일한 차종이라도 특장의 종류, 길이에 따라 선호도에 차이가 있어
              수요와 공급이 다르기 때문에 이러한 부분도 시세에 영향을 줄 수 있습니다.
            </p>
          </li>
          <li className="pb-8">
            4. 옵션
            <p>
              추가로 장착된 옵션들은 상황에따라 차량가격외 추가비용이 발생하는 경우도 있겠습니다.
              <br /> Ex) 어라운드뷰,무시동에어컨,안산철배터리 등
            </p>
          </li>
          <li className="pb-8">
            5. 차량 수리 상태
            <p>
              오래된 연식이거나 주행 거리가 많은 차량이라도 정비와 수리를 꾸준하게 했다면 최신 연식 못지 않은 차량일 수
              있어요. 하지만 관리를 소홀히 했다면 원하는 가격에 판매하긴 어렵겠죠?
              <br />
              또한 그동안 수리했던 내역을 확인할 수 있는 내역서를 꼭 가지고 계시는 걸 추천합니다.
            </p>
          </li>
        </ol>
        <div className="text-xs pb-8">
          <p className="text-gray-500 mb-4">관련 서비스</p>
          <Link to="/price-trend">
            <span className="bg-gray-100 py-2 px-4 rounded-lg mr-2">시세 검색</span>
          </Link>
        </div>
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">압류와 저당을 확인하세요</h3>
        <p className="pb-8 leading-8">압류나 저당이 있을 시 명의 이전이 불가능하므로 반드시 확인 후 해지해야 합니다.</p>
        <p className="pb-8 leading-8">
          압류와 저당 유무는 각 관공서 및 캐피탈 사에 연락하여 등록원부 조회 후 확인할 수 있어요.
          <br />
          이후, 해지 서류까지 확인하시면 압류, 저당 문제는 해결됩니다.
        </p>
        <p className="pb-8 leading-8 break-all">
          아래 민원24시 에서 원부 무료 발급(인터넷 기준)이 가능해요.
          <br />
          <a
            className="underline cursor-pointer break-all text-[#5578F0]"
            href=" https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A03007&CappBizCD=15000000334&tp_seq=02"
          >
            https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A03007&CappBizCD=15000000334&tp_seq=02
          </a>
        </p>
      </div>

      <div className="text-gray-700 border-b-4 border-gray-100 pt-4 pb-28 px-4">
        <h3 className="font-semibold text-xl py-4">차량에 할부가 남은 경우 처리 방법은?</h3>
        <p className="pb-8 leading-8">할부가 남아 있는 경우 처리하는 방법은 두 가지 입니다.</p>
        <ol className="leading-8">
          <li className="pb-8">
            1. 판매자가 남은 할부금을 캐피탈 사에 확인하고 일시 납부하거나, 잔금 입금 시에 구매자가 입금 후 근저당설정을
            해지 하는방법.
            <p>
              * 차량 금액보다 할부금이 더 많이 남아있는 경우라면 차액금액을 판매자가 선 입금 처리여부가 가능한지
              확인하세요.
            </p>
          </li>

          <li className="pb-8">
            2. 판매자가 구매자에게 할부를 승계하는 방법
            <p>
              * 구매자의 신용, 소득요건에 따라 가능/불가능 여부가 달라지므로 구매자의 승계 적격 여부를 캐피탈사에
              문의해야 해요.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};
export default PreSaleCheckList;
