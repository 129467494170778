import React from 'react';

import FilledBadge from '../Common/Badge/FilledBadge';
import BasicButton from '../Common/Button/BasicButton';
import { CheckOutlineIcon } from '../Icon';
import { colors } from '@/const/colors';
import { formatNumber } from '@/utils/common';

interface TransportInfoProps {
  transportInfo: TransportInfos;
  onClickPatchOutstandingAmount: (e: React.MouseEvent<HTMLDivElement>, transportInfo: TransportInfos) => void;
  isLoadingBtn: { [key: number]: boolean };
}

const TransportInfo = ({ transportInfo, onClickPatchOutstandingAmount, isLoadingBtn }: TransportInfoProps) => {
  const receivedReceiptLabel = transportInfo.isReceivedReceipt ? '인수증 수령' : '인수증 미수령';
  const receivedBtnBgColor = transportInfo.isReceivedCost ? colors.blue[7] : colors.gray[2];
  const transportCostTextColor =
    transportInfo.isReceivedCost && !transportInfo.isCancel ? 'text-blue-7' : 'text-gray-8';

  const handleClickReceiveBtn = (e: React.MouseEvent<HTMLDivElement>, transportInfo: TransportInfos) => {
    if (!!isLoadingBtn[transportInfo.id]) {
      return;
    }
    onClickPatchOutstandingAmount(e, transportInfo);
  };

  return (
    <>
      <div className="border border-gray-3 rounded-lg flex flex-col justify-between p-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex flex-1 items-center min-w-fit">
            <span className="text-sm text-gray-7 mr-[6px] text-ellipsis break-all line-clamp-1">
              {transportInfo.transportCompany}
            </span>
            <div className="flex items-center">
              {transportInfo.transportItem && (
                <>
                  <div className="border-l border-gray-5 h-[12px] pl-[6px]"></div>
                  <span className="text-sm text-gray-7 mr-2 text-ellipsis break-all line-clamp-1">
                    {transportInfo.transportItem}
                  </span>
                </>
              )}
              {transportInfo.isCancel && (
                <FilledBadge
                  text="회차"
                  textColor="text-green"
                  bgColor="bg-gray-1"
                  fontWeight="font-semibold"
                  rounded="rounded-lg"
                ></FilledBadge>
              )}
            </div>
          </div>
          <div
            className={`flex-2 ${transportCostTextColor} text-[15px] text-right font-medium text-ellipsis break-all line-clamp-1 min-w-fit`}
          >
            {formatNumber(
              transportInfo.isCancel ? transportInfo.cancelCost.toString() : transportInfo.transportCost.toString(),
            )}
            원
          </div>
        </div>
        {!transportInfo.isCancel && (
          <div className="flex justify-between items-end pt-[10px]">
            <span className="text-gray-7 text-xs font-semibold bg-gray-1 rounded-lg px-2 py-1">
              {receivedReceiptLabel}
            </span>
            <div onClick={(e) => handleClickReceiveBtn(e, transportInfo)} className="drive-item-checkbox">
              <BasicButton
                name={transportInfo.isReceivedCost ? '수금완료' : '수금처리'}
                icon={transportInfo.isReceivedCost ? <CheckOutlineIcon color={colors.gray[0]}></CheckOutlineIcon> : ''}
                bgColor={receivedBtnBgColor}
                borderColor={receivedBtnBgColor}
                textColor={transportInfo.isReceivedCost ? colors.gray[0] : colors.gray[7]}
                fontSize={14}
                width={transportInfo.isReceivedCost ? '96' : '74'}
                height={37}
                fontWeight="semibold"
                shadow={false}
                borderRadius={8}
                isDisabled={isLoadingBtn[transportInfo.id]}
                onClick={() => {}}
              ></BasicButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TransportInfo;
