import React, { ReactNode, useEffect, useState } from 'react';

import CustomTabPanel from '@/components/Common/Tabs/CustomTabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface BasicTabsProps {
  children?: ReactNode;
  labelList: TabType[];
  value: number;
  onChange?: (newValue: number) => void | boolean;
}

const BasicTabs = ({ children, labelList, value, onChange }: BasicTabsProps) => {
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onChange && !!onChange(newValue)) {
      setInternalValue(newValue);
      onChange(newValue);
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        minWidth: '70px',
      },
    };
  };

  useEffect(() => {
    // 외부에서 value가 변경될 때 내부 state도 업데이트
    setInternalValue(value);
  }, [value]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ display: 'flex' }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#000000',
                fontSize: '16px',
              },
            }}
            textColor="inherit"
            variant="scrollable"
          >
            {labelList.map((item, index) =>
              item.icon ? (
                <Tab
                  key={index}
                  icon={<item.icon />}
                  label={item.title}
                  {...a11yProps(index)}
                  sx={{ flex: '1', whiteSpace: 'nowrap' }}
                />
              ) : (
                <Tab
                  key={index}
                  label={item.title}
                  {...a11yProps(index)}
                  sx={{ flex: '1', whiteSpace: 'nowrap', fontSize: '16px' }}
                />
              ),
            )}
          </Tabs>
        </Box>
        {children &&
          React.Children.map(children, (child, index) => (
            <CustomTabPanel key={index} value={internalValue} index={index}>
              {child}
            </CustomTabPanel>
          ))}
      </Box>
    </>
  );
};

export default BasicTabs;
