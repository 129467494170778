import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon, HeadphoneIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import Button from '@mui/material/Button';

const OneStopServiceRequestButton = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center w-full px-4 py-2 h-18 max-w-[720px] min-w-[280px]">
      <Button
        component="label"
        variant="contained"
        className="w-full flex justify-center items-center"
        sx={{
          background: colors.gray[0],
          border: `1px solid ${colors.primary}`,
          color: colors.primary,
          borderRadius: '8px',
          height: '72px',
          '&:hover': {
            backgroundColor: colors.gray[0],
          },
        }}
        onClick={() => navigate('/one-stop-service')}
      >
        <div className="flex items-center justify-between w-full my-4">
          <div className="ml-1 flex items-center justify-between">
            <div className="mr-3">
              <HeadphoneIcon color={colors.primary} width={28} height={28} />
            </div>
            <div className="">
              <div className="font-semibold text-[11px] xxs:text-[10.5px]">차량 판매에 어려움이 있으신가요?</div>
              <div className="font-bold text-sm xxs:text-[13px]">판매 대행 서비스 이용하기</div>
            </div>
          </div>
          <div>
            <ChevronRightIcon color={colors.primary}></ChevronRightIcon>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default OneStopServiceRequestButton;
