import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { createTransferAgencyServices } from '@/api/AdditionalServices/createAdditionalServices';
import { fetchChatRoomList } from '@/api/chat/getChat';
import FloatingIconButton from '@/components/Common/Button/FloatingIconButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ScrollToTop from '@/components/Common/ScrollToTop';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { PhoneIcon } from '@/components/Icon';
import SelectVehicleFromChatList from '@/components/More/PurchaseAccompanyingService/SelectVehicleFromChatList';
import ApplyTransferAgencyServiceForm from '@/components/More/TransferAgencyService/ApplyTransferAgencyServiceForm';
import TransferGuideView from '@/components/More/TransferAgencyService/TransferGuideView';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { phoneCall } from '@/utils/common';

export interface SelectedVehicleType {
  chatRoomId: number;
  productId: number;
  truckName: string;
}

const TransferAgencyService = () => {
  const { showToast } = useToastContext();
  const { member } = useMemberContext();
  const navigate = useNavigate();

  const PurchaseAccompanyingServiceRef = useRef<HTMLDivElement>(null);

  const [step, setStep] = useState(1);
  const updateStep = (step: number) => {
    setStep(step);

    if (step === 1) {
      scrollBottom();
    }
  };

  const [isPopupShow, setIsPopupShow] = useState(false);
  const [requestForm, setRequestForm] = useState<PurchaseAccompanyingServicesForm>();

  const updateRequestForm = (formData: PurchaseAccompanyingServicesForm) => {
    setRequestForm(formData);
  };

  const {
    data: chattingList,
    isLoading,
    isFetching,
  } = useQuery<ChattingListResponse[]>(['get-chat-room-list'], () => fetchChatRoomList(), {
    onSuccess: () => {},
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (member) {
      setRequestForm({
        ...requestForm,
        name: member?.name,
        phoneNumber: member?.phoneNumber,
      });
    }
  }, [member]);

  const scrollBottom = () => {
    setTimeout(() => {
      if (PurchaseAccompanyingServiceRef.current) {
        window.scroll({
          top: PurchaseAccompanyingServiceRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 10);
  };

  const handleClickApplyService = () => {
    setIsPopupShow(true);
  };

  const handleClickClosePopup = () => {
    setIsPopupShow(false);
  };

  const handleClickOkBtn = () => {
    if (requestForm) {
      const requestData = {
        name: requestForm.name,
        phoneNumber: requestForm.phoneNumber,
        chatRoomId: requestForm.chatRoomId,
      };
      postPurchaseAccompanyingServices.mutate(requestData);
      setIsPopupShow(false);
    }
  };

  const postPurchaseAccompanyingServices = useMutation(
    (request: PurchaseAccompanyingServicesForm) => createTransferAgencyServices(request),
    {
      onSuccess: () => {
        showToast('서류 이전 대행 서비스를 신청했어요.', 'success', 'bottom');
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickPrev = () => {
    if (step === 1) {
      navigate(-1);
    } else if (step === 2) {
      updateStep(1);
    }
  };

  return (
    <>
      <MenuHeader title="서류 이전 대행 서비스" onClickPrevBtn={handleClickPrev}></MenuHeader>
      {step === 1 && (
        <div className="w-full pt-[60px] bg-gray-1" ref={PurchaseAccompanyingServiceRef}>
          <TransferGuideView></TransferGuideView>
          <ApplyTransferAgencyServiceForm
            isShowVehicleSelector={(chattingList && chattingList?.length > 0) || false}
            updateStep={(step: number) => updateStep(step)}
            formData={requestForm || {}}
            updateFormData={(data: PurchaseAccompanyingServicesForm) => updateRequestForm(data)}
          ></ApplyTransferAgencyServiceForm>

          <div className="pt-6 px-4">
            <p className="text-gray-7 pb-5 text-pretty font-medium text-[13px]">
              * 서류 이전 대행 서비스는 판매 대행 서비스 내에 포함된 서비스입니다. 직거래 판매 대행 서비스 이용 시 이전
              대행 서비스도 함께 제공되며 이전 대행 서비스 단독 이용 시 판매 대행 서비스는 제공되지 않습니다.
            </p>
          </div>

          <FloatingIconButton
            bgColor={colors.gray[0]}
            icon={<PhoneIcon color={colors.primary}></PhoneIcon>}
            width={52}
            height={52}
            onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
          ></FloatingIconButton>
          <div className="bg-white px-3 pt-2 pb-4">
            <ButtonFooter
              title="서비스 신청하기"
              isDisabled={!requestForm?.name || !requestForm?.phoneNumber}
              onClick={handleClickApplyService}
            ></ButtonFooter>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="w-full pt-[60px] pb-20">
          <ScrollToTop />
          <SelectVehicleFromChatList
            updateStep={(step: number) => updateStep(step)}
            formData={requestForm || {}}
            updateFormData={(data: PurchaseAccompanyingServicesForm) => updateRequestForm(data)}
            isTransferAgencyService
            chatList={chattingList}
          ></SelectVehicleFromChatList>
        </div>
      )}

      <BasicPopup
        isShow={isPopupShow}
        title={`${!!requestForm?.productId ? requestForm?.truckName : ''}`}
        textContent={`${
          requestForm?.productId
            ? '선택한 차량으로 서류 이전 대행<br/>서비스를 신청할까요?'
            : '서류 이전 대행 서비스를<br>신청하시겠어요?'
        }`}
        textLeftBtn="취소"
        onClickLeftBtn={() => handleClickClosePopup()}
        textRightBtn="신청하기"
        onClickRightBtn={() => handleClickOkBtn()}
      ></BasicPopup>
    </>
  );
};

export default TransferAgencyService;
