import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom';

import DualFooterButton from '../Common/Button/DualFooterButton';
import RadioList, { OptionType } from '../Common/Input/Radio/RadioList';
import TextInputLabelUp from '../Common/Input/TextInputLabelUp';
import DownToUpDetailPopup from '../Common/Popup/DownToUpDetailPopup';
import { ChevronDownIcon } from '../Icon';
import { colors } from '@/const/colors';
import {
  LOADED_INNER_LENGTH_CHECK_AGAIN,
  LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE,
  TONS_IS_LESS_THAN_27,
  TONS_IS_OVER_1,
  YEAR_IS_OVER_2000,
} from '@/const/errorMessage';
import { positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { usePriceSearchFormContext } from '@/contexts/Price/PriceSearchFormContext';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import { formatValueToDecimalPlaces, getFormatDateOnlyNum } from '@/utils/common';

interface ProductProps {
  ownerInfo?: OwnerInfo;
  productOriginData?: ProductDetailResponse | undefined;
  productSearchParams: ProductSearchParams;
  setProductSearchParams: React.Dispatch<React.SetStateAction<ProductSearchParams>>;
}

const PriceTrendSearchForm: React.FC<ProductProps> = ({
  ownerInfo,
  productOriginData,
  productSearchParams,
  setProductSearchParams,
}) => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const { priceProductSearchParams, setPriceProductSearchParams } = usePriceSearchFormContext();
  const { productEnum } = useProductEnumContext();
  const yearRef = useRef<HTMLInputElement>(null);
  const tonsRef = useRef<HTMLInputElement>(null);
  const loadedInnerLengthRef = useRef<HTMLInputElement>(null);

  const [yearError, setYearError] = useState(false);
  const [tonsError, setTonsError] = useState(false);
  const [loadedInnerLengthError, setLoadedInnerLengthError] = useState(false);
  const [yearErrorMsg, setYearErrorMsg] = useState('');
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');
  const [loadedInnerLengthErrorMsg, setLoadedInnerLengthErrorMsg] = useState('');

  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');

  const keyValueList: KeyValueListType = {
    manufacturerCategories: '제조사',
    model: '모델',
    loaded: '적재함 종류',
  };

  const [axisOptionData, setAxisOptionData] = useState<OptionType[]>([]);

  useEffect(() => {
    if (productOriginData) {
      setProductSearchParams({
        ...productSearchParams,
        year: getFormatDateOnlyNum(productOriginData?.firstRegistrationDate, ['year']),
        manufacturerCategories: productOriginData?.manufacturerCategories,
      });
    }
  }, [productOriginData]);

  const onClickSelectBox = (key: string) => {
    if (!productEnum) return;
    const arr: OptionDataType[] = [];
    setTitle(key);

    if (key === 'manufacturerCategories') {
      const arr = productEnum['manufacturerAndModel'].map((item) => item.manufacturerCategories);
      if (arr.length > 0) {
        setOptionData(
          arr.map(
            (item) =>
              ({
                id: item?.id,
                code: item?.code,
                desc: item?.name,
              } as OptionType),
          ),
        );
      }
    } else if (key === 'model') {
      if (productOriginData?.manufacturerCategories || productSearchParams.manufacturerCategories) {
        const arr = productOriginData?.manufacturerCategories
          ? productEnum['manufacturerAndModel']
              .filter((item) => item.manufacturerCategories.id === productOriginData?.manufacturerCategories?.id)
              .map((item) => item.model)
          : productEnum['manufacturerAndModel']
              .filter((item) => item.manufacturerCategories.id === productSearchParams?.manufacturerCategories?.id)
              .map((item) => item.model);

        if (arr.length > 0) {
          const flattenedArr = arr.reduce<Model[]>((acc, val) => acc.concat(val), []);
          setOptionData(
            flattenedArr.map((item) => ({
              id: item?.id,
              code: String(item.id),
              desc: item?.name,
            })),
          );
        }
      }
    } else {
      setOptionData(productEnum[key] as FilterDataType[]);
    }
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    let { code, id, desc } = item;
    switch (key) {
      case 'manufacturerCategories':
        setProductSearchParams({
          ...productSearchParams,
          manufacturerCategories: { code, id, name: desc } as ManufacturerCategories,
        });
        setTimeout(() => {
          setIsShow(false);
        }, 200);
        break;

      case 'model':
        setProductSearchParams({
          ...productSearchParams,
          model: { id, name: desc } as Model,
        });
        setTimeout(() => {
          setIsShow(false);
        }, 200);

        break;

      case 'loaded':
        setProductSearchParams({
          ...productSearchParams,
          loaded: item as EnumPresenter,
        });
        setTimeout(() => {
          setIsShow(false);
        }, 200);
        break;
    }
  };

  const handleChangeYearInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (positiveIntegerAndNullRegex.test(value) && !value.startsWith('-')) {
      const str = value === '' ? null : value;
      setProductSearchParams({ ...productSearchParams, [name]: str });

      if (Number(value) < 2000) {
        setYearError(true);
        setYearErrorMsg(YEAR_IS_OVER_2000);
        return;
      } else {
        setYearError(false);
        setYearErrorMsg('');
        return;
      }
    }
  };

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    const decimalPlaces = 1;
    const formattedValue = formatValueToDecimalPlaces(value, decimalPlaces);

    if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = formattedValue.trim() === '' ? null : formattedValue.trim();
      setProductSearchParams({ ...productSearchParams, [name]: str });

      if (Number(value) < 1) {
        setTonsError(true);
        setTonsErrorMsg(TONS_IS_OVER_1);
        return;
      } else if (Number(value) > 27) {
        setTonsError(true);
        setTonsErrorMsg(TONS_IS_LESS_THAN_27);
        return;
      } else {
        setTonsError(false);
        setTonsErrorMsg('');
        return;
      }
    }
  };

  const handleChangeLoadedLengthInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target;

    const decimalPlaces = 2;
    const formattedValue = formatValueToDecimalPlaces(value, decimalPlaces);

    if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = formattedValue.trim() === '' ? null : formattedValue.trim();
      setProductSearchParams({ ...productSearchParams, [name]: str });
    }
  };

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      setProductSearchParams({ ...productSearchParams, loadedInnerLength: parseFloat((valueNum / 1000).toFixed(2)) });
    }
  };

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setProductSearchParams({ ...productSearchParams, [key]: newVal });
  };

  useEffect(() => {
    if (productEnum) {
      setAxisOptionData(productEnum.axis);
    }
  }, [productEnum]);

  const getValue = (title: string) => {
    if (title === 'manufacturerCategories') {
      return productSearchParams.manufacturerCategories?.code;
    } else if (title === 'model') {
      return String(productSearchParams.model?.id);
    } else {
      return productSearchParams.loaded?.code;
    }
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'year') {
        yearRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'tons') {
        tonsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerLength') {
        loadedInnerLengthRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  const isNotEtcProduct = () => {
    return (
      !(
        productSearchParams?.manufacturerCategories?.code === 'ETC' ||
        productOriginData?.manufacturerCategories.code === 'ETC'
      ) && productSearchParams?.model?.id !== 45
    );
  };

  useEffect(() => {
    if (productSearchParams.loadedInnerLength) {
      if (isNotEtcProduct() && Number(productSearchParams.loadedInnerLength) > 10.5) {
        setLoadedInnerLengthError(true);
        setLoadedInnerLengthErrorMsg('적재함 길이는 10.3이하의 값을 입력해주세요.');
      } else if (Number(productSearchParams.loadedInnerLength) < 1) {
        setLoadedInnerLengthError(true);
        setLoadedInnerLengthErrorMsg(LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE);
      } else if (Number(productSearchParams?.tons) >= 4.5) {
        setLoadedInnerLengthError(productSearchParams.loadedInnerLength < 5);
        setLoadedInnerLengthErrorMsg(LOADED_INNER_LENGTH_CHECK_AGAIN);
      } else if (Number(productSearchParams?.tons) >= 2) {
        setLoadedInnerLengthError(productSearchParams.loadedInnerLength < 3);
        setLoadedInnerLengthErrorMsg(LOADED_INNER_LENGTH_CHECK_AGAIN);
      } else {
        setLoadedInnerLengthError(productSearchParams.loadedInnerLength < 2);
        setLoadedInnerLengthErrorMsg(LOADED_INNER_LENGTH_CHECK_AGAIN);
      }
    }
  }, [productSearchParams.loadedInnerLength, isNotEtcProduct]);

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onClickSearch = () => {
    navigate('/price-trend/result', { state: { productSearchParams } });
  };

  const clear = () => {
    setProductSearchParams({
      tons: '',
      year: '',
      model: { id: 0, name: '' },
      manufacturerCategories: { id: 0, name: '', code: '' },
      loaded: { code: '', desc: '' },
      loadedInnerLength: 0,
      axis: { code: '', desc: '' },
    });

    setYearError(false);
    setYearErrorMsg('');
    setTonsError(false);
    setTonsErrorMsg('');
    setLoadedInnerLengthError(false);
    setLoadedInnerLengthErrorMsg('');
  };

  useEffect(() => {
    if (navigationType === NavigationType.Pop && priceProductSearchParams) {
      setProductSearchParams(priceProductSearchParams);
    } else {
      setPriceProductSearchParams({});
    }
  }, []);

  const isDisabledSearchBtn = () => {
    return (
      yearError ||
      tonsError ||
      loadedInnerLengthError ||
      productSearchParams?.axis?.code === '' ||
      productSearchParams?.loaded?.code === '' ||
      productSearchParams?.loadedInnerLength === 0 ||
      productSearchParams?.loadedInnerLength === null ||
      productSearchParams?.model?.name === '' ||
      productSearchParams?.tons === ''
    );
  };

  return (
    <>
      <div>
        {productOriginData && (
          <h2 className="text-2xl font-bold mt-2 mb-6 pt-6 text-gray-8">{ownerInfo?.name}님의 차량정보</h2>
        )}
        <div>
          {productOriginData && (
            <div className="bg-yellow inline-block w-auto rounded-lg border-2 border-black p-3">
              <div className="flex items-center justify-center mb-2 font-semibold text-3xl">
                {productOriginData?.truckNumber}
              </div>
            </div>
          )}

          <div className="rounded-lg bg-blue-0 px-4 py-[19px] mt-4 mb-2 flex flex-col text-gray-8">
            <h4 className="text-lg font-bold mb-2 text-gray-8">
              {productOriginData ? '추가 정보 입력' : '필터로 시세 검색'}
            </h4>
            <p className="text-sm text-gray-8">
              {productOriginData ? '정확한 시세 검색을 위해 추가 ' : '정확한 시세 검색을 위해 '}
              정보를 입력해주세요.
            </p>
          </div>
          <span className="text-red text-sm mb-4">* 모든 항목은 필수 항목입니다.</span>

          <div className="flex flex-col gap-[30px] pt-[30px]">
            <TextInputLabelUp
              ref={yearRef}
              name="year"
              label="연식"
              placeholder="연식 입력 ex) 2024"
              value={productSearchParams?.year || ''}
              onChange={(e) => handleChangeYearInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              error={yearError}
              errorMsg={yearErrorMsg}
              inputMode="numeric"
              maxLength={4}
            ></TextInputLabelUp>

            <div onClick={() => onClickSelectBox('manufacturerCategories')}>
              <TextInputLabelUp
                name="model"
                label="제조사"
                placeholder="제조사 선택"
                value={productSearchParams?.manufacturerCategories?.name || ''}
                type="text"
                readOnly={!productOriginData}
                disabled={!!productOriginData}
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              ></TextInputLabelUp>
            </div>

            <div onClick={() => onClickSelectBox('model')}>
              <TextInputLabelUp
                name="model"
                label="모델"
                placeholder="모델 선택"
                value={productSearchParams?.model?.name || ''}
                type="text"
                disabled={
                  !productOriginData?.manufacturerCategories?.name && !productSearchParams?.manufacturerCategories?.name
                }
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              ></TextInputLabelUp>
            </div>

            <TextInputLabelUp
              ref={tonsRef}
              name="tons"
              label="톤수"
              placeholder="톤수 입력 ex) 8.5"
              value={productSearchParams?.tons || ''}
              onChange={(e) => handleChangeTonsInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              error={tonsError}
              errorMsg={tonsErrorMsg}
              inputMode="numeric"
              suffix="t"
              maxLength={4}
            ></TextInputLabelUp>

            <div onClick={() => onClickSelectBox('loaded')}>
              <TextInputLabelUp
                name="loaded"
                label="적재함 종류"
                placeholder="적재함 종류 선택"
                value={productSearchParams?.loaded?.desc || ''}
                type="text"
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              ></TextInputLabelUp>
            </div>

            <TextInputLabelUp
              ref={loadedInnerLengthRef}
              name="loadedInnerLength"
              label="적재함 길이(내측 사이즈)"
              placeholder="적재함 길이 ex) 10.2"
              value={String(productSearchParams?.loadedInnerLength || '')}
              onChange={(e) => handleChangeLoadedLengthInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              onBlur={(e) => handleInputBlur(e)}
              error={loadedInnerLengthError}
              errorMsg={loadedInnerLengthErrorMsg}
              type="text"
              suffix="m"
              maxLength={5}
            ></TextInputLabelUp>

            <div>
              <p className="font-semibold mb-2 text-gray-8">가변축</p>
              <div className="px-1">
                <RadioList
                  name="axis"
                  list={axisOptionData}
                  value={productSearchParams?.axis?.code || ''}
                  onChange={(val) => {
                    onChangeRadioInput(val, 'axis');
                  }}
                ></RadioList>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DualFooterButton
        leftButtonText="초기화"
        onClickLeftButton={() => {
          clear();
        }}
        rightButtonText="검색"
        onClickRightButton={() => {
          onClickSearch();
          setPriceProductSearchParams(productSearchParams);
        }}
        disabledRight={isDisabledSearchBtn()}
      ></DualFooterButton>
      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={getValue(title) || ''}
            onChange={(val) => {
              onClickItem(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default PriceTrendSearchForm;
