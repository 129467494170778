import React from 'react';
import { Link } from 'react-router-dom';

const GuideSection = ({}) => {
  return (
    <div>
      <div className="px-4">
        <p className="text-xl text-gray-8 font-semibold mb-3">중고 화물차 똑똑하게 사고 파는 법</p>
      </div>

      <div className="flex mx-4 gap-3 grid grid-cols-2">
        <Link to={'/guide/sale'}>
          <div className="w-full bg-[#00ACFD] rounded-lg flex justify-center mr-1 pl-3 min-w-[120px]">
            <p className="font-bold text-sm text-white min-w-[70px] mt-3 break-keep">
              중고 화물차
              <br />
              판매 가이드
            </p>
            <div className="pb-2 h-[100px] overflow-hidden rounded-lg">
              <div className="w-[163px] overflow-hidden">
                <img
                  src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/thumbnail_purchase_guide.png"
                  alt="thumbnail_purchase_guide"
                  className="w-full h-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <p className="pt-2 font-semibold text-gray-8">판매 가이드 보러가기</p>
        </Link>
        <Link to={'/guide/perchase'}>
          <div className="w-full bg-[#1A72F5] rounded-lg flex justify-center mr-1 pl-3 min-w-[120px]">
            <p className="font-bold text-sm text-white min-w-[70px] mt-3 break-keep">
              중고 화물차
              <br />
              구매 가이드
            </p>
            <div className="pb-2 h-[100px] overflow-hidden rounded-lg">
              <div className="w-[163px] overflow-hidden">
                <img
                  src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/thumbnail_sale_guide.png"
                  alt="thumbnail_sale_guide"
                  className="w-full h-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <p className="pt-2 font-semibold text-gray-8">구매 가이드 보러가기</p>
        </Link>
      </div>
    </div>
  );
};

export default GuideSection;
