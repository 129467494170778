// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* globalStyles.css */
* {
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never; */
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.hidden-arrows input::-webkit-outer-spin-button,
.hidden-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
input:focus {
  outline: none;
}

.hidden-arrows input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

th,
td {
  font-weight: 400;
  border: 1px solid #000000;
  padding: 8px;
}

div.carousel .control-dots .dot {
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/globalStyle.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE;;iCAE+B;;EAE/B,yBAAyB;EACzB,sBAAsB;EAEtB,iBAAiB;EACjB,mDAAmD;EACnD;;uBAEqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;;EAEE,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* globalStyles.css */\n* {\n  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',\n    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',\n    'Segoe UI Symbol', sans-serif;\n\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);\n  /* -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-smooth: never; */\n}\n\nimg {\n  -webkit-user-drag: none;\n  -khtml-user-drag: none;\n  -moz-user-drag: none;\n  -o-user-drag: none;\n}\n\n.hidden-arrows input::-webkit-outer-spin-button,\n.hidden-arrows input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput,\ninput:focus {\n  outline: none;\n}\n\n.hidden-arrows input[type='number'] {\n  -moz-appearance: textfield;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\nth,\ntd {\n  font-weight: 400;\n  border: 1px solid #000000;\n  padding: 8px;\n}\n\ndiv.carousel .control-dots .dot {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
