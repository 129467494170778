import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import apiManager from '@/api/AxiosInstance';
import BannerCarousel from '@/components/Common/Banner/BannerCarousel';
import TopBanner from '@/components/Common/Banner/TopBanner';
import SearchBox from '@/components/Common/Input/SearchBox';
import HomePopup from '@/components/Common/Popup/HomePopup';
import ProductFlicking from '@/components/Common/ProductFlicking';
import Footer from '@/components/Footer';
import RecommendProducts from '@/components/Home/RecommendProducts';
import SubCards from '@/components/Home/SubCards';
import {
  BellIcon,
  CalendarCheckColorIcon,
  CalendarCheckOutlineIcon,
  DeliveryTruckIcon,
  IncreasingTrendGraphColorIcon,
  JobBoldColorIcon,
  LicensePlateBoldColorIcon,
  SearchOutlineBoldIcon,
} from '@/components/Icon';
import SvgSearchQuestionBoldIcon from '@/components/Icon/SearchQuestionBoldIcon';
import OnBoading from '@/components/Login/OnBoading';
import { HOME, HOME_POP_UP } from '@/const/banner';
import { colors } from '@/const/colors';
import { PRODUCT_PURCHASE_INQUIRY_PATH, REPRESENTATIVE_NUMBER } from '@/const/common';
import { SALES_TYPE_ASSURANCE } from '@/const/products';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { isExistNotReadNotificationAtom } from '@/store/notification';
import { productsSearchParamsAtom } from '@/store/products';
import { Button } from '@mui/material';

interface ResponseData {
  totalElement: number;
  totalPage: number;
  data: ProductsList[];
}

const businessInfo = [
  { title: '상호', content: '주식회사 직트럭' },
  { title: '대표', content: '양요한' },
  { title: '주소', content: '경기도 평택시 월곡길 9-9' },
  { title: '대표 전화', content: REPRESENTATIVE_NUMBER },
  { title: '사업자 번호', content: '721-87-01975' },
  { title: '통신판매업 신고번호', content: '제 2023-경기평택-1174호' },
];

const Home = () => {
  const [testGroup, setTestGroup] = useState<'A' | 'B'>();

  // 세션 ID 생성
  const createSessionId = () => {
    return uuidv4();
  };

  const hashSessionId = (sessionId: string) => {
    let hash = 0;
    for (let i = 0; i < sessionId.length; i++) {
      const char = sessionId.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
    return hash;
  };

  const assignGroupBasedOnSession = (sessionId: string) => {
    const hashValue = hashSessionId(sessionId);
    return hashValue % 2 === 0 ? 'A' : 'B';
  };

  useEffect(() => {
    let sessionId = localStorage.getItem('session_id') || '';

    if (!sessionId) {
      sessionId = createSessionId();
      localStorage.setItem('session_id', sessionId);
    }

    const userGroup = assignGroupBasedOnSession(sessionId);

    setTestGroup(userGroup);

    if (window.hj) {
      window.hj('trigger', `group_${userGroup}`);
    }
  }, []);

  const [isExistNotReadNotification] = useAtom(isExistNotReadNotificationAtom);

  const userAgent = navigator.userAgent;

  const isNaverInApp = /NAVER/.test(userAgent);
  const isKakaoInApp = /KAKAOTALK/.test(userAgent);

  const isAndroidWeb =
    /Android/i.test(userAgent) && /WebKit/i.test(userAgent) && (!/wv/i.test(userAgent) || isNaverInApp || isKakaoInApp);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceToken = searchParams.get('deviceToken');

  const authRedirect = useAuthRedirect();
  const setProductSearchParams = useSetAtom(productsSearchParamsAtom);
  const [assuranceProducts, setAssuranceProducts] = useState<ProductsList[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [recommendProducts, setRecommendProducts] = useState<ProductsList[]>([]);
  const [isShowOnBoading, setIsShowOnBoading] = useState<boolean>();
  const [isShow, setIsShow] = useState<boolean>(true);
  const [isShowTopBanner, setIsShowTopBanner] = useState(false);
  const [popupImage, setPopupImage] = useState<Banner>();

  const subFunctionCardsA: ButtonCard[] = [
    {
      title: '시세 검색',
      path: '/price-trend',
      icon: (
        <IncreasingTrendGraphColorIcon color={colors.gray[6]} width={38} height={38}></IncreasingTrendGraphColorIcon>
      ),
    },
    {
      title: '번호판 거래',
      path: '/license',
      icon: <LicensePlateBoldColorIcon color={colors.gray[6]} width={38} height={38}></LicensePlateBoldColorIcon>,
    },
    {
      title: '운행일지',
      path: '/drive',
      icon: <CalendarCheckColorIcon color={colors.gray[6]} width={38} height={38}></CalendarCheckColorIcon>,
    },
    {
      title: '일자리 구하기',
      path: '/job',
      icon: <JobBoldColorIcon color={colors.gray[6]} width={38} height={38}></JobBoldColorIcon>,
    },
  ];

  const subFunctionCardsB: ButtonCard[] = [
    {
      title: '시세 검색',
      path: '/price-trend',
      icon: (
        <IncreasingTrendGraphColorIcon color={colors.gray[6]} width={38} height={38}></IncreasingTrendGraphColorIcon>
      ),
    },
    {
      title: '번호판 거래',
      path: '/license',
      icon: <LicensePlateBoldColorIcon color={colors.gray[6]} width={38} height={38}></LicensePlateBoldColorIcon>,
    },
    {
      title: '일자리 구하기',
      path: '/job',
      icon: <JobBoldColorIcon color={colors.gray[6]} width={38} height={38}></JobBoldColorIcon>,
    },
  ];

  const LOCALSTORAGE_KEY = 'homePopupTime';

  useEffect(() => {
    if (isAndroidWeb) {
      setIsShowTopBanner(true);
    }

    if (deviceToken) {
      localStorage.setItem('deviceToken', deviceToken);
    }

    setIsShowOnBoading(localStorage.getItem('isFirst') !== 'false');

    checkPopupClose() ? setIsShow(false) : setIsShow(true);

    apiManager
      .get('/api/v1/public/banners')
      .then((response) => {
        const banners: Banner[] = response.data;

        const filteredBanners: Banner[] = banners.filter((banner) => {
          return banner.bannerLocation.code === HOME;
        });

        const filterPopup: Banner[] = banners.filter((banner) => {
          return banner.bannerLocation.code === HOME_POP_UP;
        });

        setBanners(filteredBanners);
        const randomPopupImage = getRandomPopupImage(filterPopup);
        setPopupImage(randomPopupImage);
      })
      .catch((error) => {});

    apiManager
      .get(`/api/v1/public/products?salesType=${SALES_TYPE_ASSURANCE}`)
      .then((response) => {
        const responseData: ResponseData = response.data;
        setAssuranceProducts(responseData.data);
      })
      .catch((error) => {
        console.log('에러');
      });

    apiManager
      .get(`/api/v1/public/products/recommend`)
      .then((response) => {
        const responseData: ProductsList[] = response.data;
        setRecommendProducts(responseData);
      })
      .catch((error) => {});
  }, []);

  const closePopup = (expireDays: number) => {
    let expire = new Date();
    expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
    localStorage.setItem(LOCALSTORAGE_KEY, expire.getTime().toString());
  };

  const checkPopupClose = () => {
    const expireDay = localStorage.getItem(LOCALSTORAGE_KEY);
    let today = new Date();

    return today.getTime() <= Number(expireDay) || sessionStorage.getItem('isHomePopup') === 'false';
  };

  const onClickCloseToday = () => {
    closePopup(1);
    setIsShow(false);
  };

  const onClickClose = () => {
    setIsShow(false);
  };

  useEffect(() => {
    sessionStorage.setItem('isHomePopup', `${isShow}`);
  }, [isShow]);

  const getRandomPopupImage = (popupImages: Banner[]): Banner => {
    const length = popupImages.length;
    const randomIndex = Math.floor(Math.random() * length);
    return popupImages[randomIndex];
  };

  const onClickGoHome = () => {
    setIsShowOnBoading(() => false);
  };

  const onClickMore = () => {
    navigate('/products', { state: { salesType: SALES_TYPE_ASSURANCE } });
  };

  const onClickPurchaseInquiry = () => {
    if (!authRedirect(PRODUCT_PURCHASE_INQUIRY_PATH)) {
      return;
    }
    navigate('/products/purchase');
  };

  const handleClickSearchBox = () => {
    navigate('/products');
  };

  return (
    <>
      {isShowOnBoading ? (
        <OnBoading onClickGoHome={onClickGoHome}></OnBoading>
      ) : (
        <div className={`w-full ${isShowTopBanner ? 'pt-[70px]' : ''}`}>
          <div className="pb-20">
            <div className="flex flex-col p-4">
              <div className="flex justify-between">
                <img
                  src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/logo_primary.png"
                  alt="직트럭 로고"
                  className="mb-4"
                  width={50}
                  height={24}
                ></img>
                {testGroup === 'A' && (
                  <div className="flex gap-2">
                    <Link to="/notification/#activity" className="cursor-pointer flex">
                      <div className="relative w-[26px] h-[26px] flex justify-center items-center">
                        <BellIcon color={colors.gray[8]} width={26} height={26}></BellIcon>
                        {isExistNotReadNotification && (
                          <div className="absolute top-0 right-0 w-1 h-1 bg-red rounded"></div>
                        )}
                      </div>
                    </Link>
                  </div>
                )}
                {testGroup === 'B' && (
                  <div className="flex gap-2">
                    <Link to="/drive" className="cursor-pointer flex">
                      <CalendarCheckOutlineIcon
                        color={colors.gray[8]}
                        width={26}
                        height={26}
                      ></CalendarCheckOutlineIcon>
                      <span className="pl-1 text-gray-8 text-base h-[26px]">운행일지</span>
                    </Link>
                    <span className="text-gray-4"> | </span>
                    <Link to="/notification/#activity" className="cursor-pointer flex">
                      <div className="relative w-[26px] h-[26px] flex justify-center items-center">
                        <BellIcon color={colors.gray[8]} width={26} height={26}></BellIcon>
                        {isExistNotReadNotification && (
                          <div className="absolute top-0 right-0 w-1 h-1 bg-red rounded"></div>
                        )}
                      </div>
                      <span className="pl-1 text-gray-8 text-base h-[26px]">알림</span>
                    </Link>
                  </div>
                )}
              </div>
              {testGroup === 'A' && (
                <div className="mb-4 rounded-lg shadow-[0_0px_3px_3px_rgba(0,0,0,0.1)]">
                  <SearchBox
                    name="truckName"
                    placeholder="차량을 검색해보세요."
                    value=""
                    onClick={handleClickSearchBox}
                  ></SearchBox>
                </div>
              )}
              <BannerCarousel banners={banners}></BannerCarousel>
              <div className="mt-6 mb-4">
                {testGroup === 'A' && (
                  <>
                    <Link
                      to="/products/sales"
                      className="flex justify-between items-center bg-blue-1 py-[19px] px-4 rounded-[10px]"
                    >
                      <div className="flex flex-col">
                        <span className="text-gray-8 font-bold text-[20px]">내 차 간편등록</span>
                        <span className="text-gray-7 font-regular text-[14px]">
                          1분만에 차량을 등록하고 판매해보세요.
                        </span>
                      </div>
                      <div>
                        <DeliveryTruckIcon color={colors.blue[2]} width={40} height={40}></DeliveryTruckIcon>
                      </div>
                    </Link>
                  </>
                )}
                {testGroup === 'B' && (
                  <div className="flex sm280:flex-col gap-[12px]">
                    <Link
                      to="/products/sales"
                      className="flex-1 flex flex-col justify-between bg-blue-1 px-[18px] py-5 rounded-[10px] w-full"
                    >
                      <div className="flex justify-between">
                        <span className="text-gray-8 font-bold text-[20px] leading-[24px] break-keep">
                          내 차 판매하기
                        </span>
                        <div>
                          <DeliveryTruckIcon color={colors.blue[2]} width={40} height={40}></DeliveryTruckIcon>
                        </div>
                      </div>
                      <span className="text-gray-7 font-regular text-[14px] leading-[18px] mt-[10px]">
                        1분만에 차량을 등록
                        <br className="block sm550:hidden sm280:hidden" />
                        하고 판매해보세요.
                      </span>
                    </Link>
                    <Link
                      to="/products"
                      className="flex-1 flex flex-col justify-between bg-blue-1 px-[18px] py-5 rounded-[10px] w-full"
                    >
                      <div className="flex justify-between">
                        <span className="text-gray-8 font-bold text-[20px] leading-[24px]">매물 보기</span>
                        <div>
                          <SearchOutlineBoldIcon color={colors.blue[2]} width={24} height={24}></SearchOutlineBoldIcon>
                        </div>
                      </div>
                      <span className="text-gray-7 font-regular text-[14px] leading-[18px] mt-[10px]">
                        내가 찾던 차량을 <br className="block sm550:hidden sm280:hidden" />
                        빠르게 검색해보세요.
                      </span>
                    </Link>
                  </div>
                )}
              </div>
              {testGroup === 'A' && <SubCards cards={subFunctionCardsA}></SubCards>}
              {testGroup === 'B' && <SubCards cards={subFunctionCardsB}></SubCards>}

              <div className="mt-4" onClick={onClickPurchaseInquiry}>
                <div className="flex flex-row items-center justify-center w-full bg-gray-1 p-4 rounded-[12px]">
                  <SvgSearchQuestionBoldIcon color={colors.gray[7]} width={40} height={40}></SvgSearchQuestionBoldIcon>

                  <div className="flex flex-col flex-1 ml-3">
                    <span className="font-semibold text-lg text-gray-8">구매 문의 남기기</span>
                    <span className="text-[14px] text-gray-7 sm280:text-[13px] break-keep">
                      원하는 차량이 없다면 <br className="hidden sm280:block" />
                      문의를 남겨주세요
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {!!assuranceProducts?.length && (
              <div className="flex flex-col pb-6 border-t-8 border-gray-1">
                <div className="p-6">
                  <h2 className="text-lg font-semibold text-gray-700">직트럭 상품용</h2>
                  <span className="text-base xxs:text-sm text-gray-400 pb-2 break-keep">
                    직트럭에서 직접 검수를 거친 차량이에요.
                  </span>
                </div>
                <ProductFlicking products={assuranceProducts}></ProductFlicking>
                <div className="p-3 pt-0">
                  <Button
                    className="w-full"
                    onClick={() => onClickMore()}
                    sx={{
                      fontSize: '18px',
                      color: colors.gray[8],
                      bgcolor: colors.gray[0],
                      borderRadius: '8px',
                      border: `1px solid ${colors.gray[3]}`,
                      paddingRight: '6px',
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    더보기
                  </Button>
                </div>
              </div>
            )}

            {recommendProducts?.length > 0 && <RecommendProducts products={recommendProducts}></RecommendProducts>}
            <div className="py-5 px-4 bg-gray-1 mt-[37px]">
              <h3 className="text-gray-7 text-[13px] mb-2">직트럭 사업자 정보</h3>
              <div className="text-gray-6 text-[10px] mb-3">
                <dl>
                  {businessInfo.map((item, index) => (
                    <div key={index} className="table-row">
                      <dt className="table-cell min-w-[140px]">{item.title}</dt>
                      <dd>{item.content}</dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div className="text-gray-6 text-[10px]">
                <p>ⓒ 2020 주식회사 직트럭 All Rights Reserved</p>
                <p>
                  주식회사 직트럭은 통신판매중개자로서 통신판매의 당사자가 아니며, 상품 및 거래정보, 거래에 대해 책임을
                  지지않습니다.
                </p>
              </div>
            </div>
          </div>

          <Footer isMain></Footer>
          {popupImage && (
            <HomePopup
              isShow={isShow}
              image={popupImage}
              textRightBtn="닫기"
              onClickRightBtn={onClickClose}
              textLeftBtn={'오늘 하루 보지 않기'}
              onClickLeftBtn={onClickCloseToday}
            ></HomePopup>
          )}

          <TopBanner isShow={isShowTopBanner}></TopBanner>
        </div>
      )}
    </>
  );
};

export default Home;
