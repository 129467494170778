import React from 'react';
import { getFormatDate } from '@/utils/common';
interface ProductOriginProps {
  productOriginData: ProductDetailResponse | undefined;
}
const ChangeHistory: React.FC<ProductOriginProps> = ({ productOriginData }) => {
  return (
    <>
      <div className="py-4 my-2 px-4 text-gray-8">
        {!productOriginData && <p className="text-sm">원부데이터 조회에 실패했습니다.</p>}
        <div className="p-4 bg-gray-1 rounded-lg">
          {productOriginData?.tuningHistory && productOriginData?.tuningHistory.length > 0 ? (
            productOriginData?.tuningHistory?.map((item, index) => (
              <dl key={index} className="text-base">
                <dt className={`font-semibold ${index === 0 ? '' : 'mt-5'}`}>{item.date}</dt>
                <dd>
                  <ul>
                    <li>・ 구조변경 : {item.before}</li>
                    <li>・ 구조변경 후 내역 : {item.after}</li>
                  </ul>
                </dd>
              </dl>
            ))
          ) : (
            <p className="text-gray-6">변경 이력 없음</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ChangeHistory;
