import React from 'react';

import '@/css/basicPopupStyle.css';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const BasicPopup: React.FC<BasicPopupProps> = ({
  isShow,
  title,
  textContent,
  componentContent,
  textRightBtn,
  onClickRightBtn,
  textLeftBtn,
  onClickLeftBtn,
  minWidth = 280,
}) => {
  return (
    <>
      {isShow && (
        <Dialog
          open={isShow}
          onClose={() => onClickLeftBtn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={`min-w-[${minWidth}px]`}
        >
          {title && (
            <DialogTitle
              id="alert-dialog-title"
              className="text-center font-semibold break-keep"
              sx={{ paddingTop: '32px', minWidth: `${minWidth - 64}px`, maxWidth: '360px' }}
            >
              {title && (
                <span
                  className="break-keep text-[22px] xxs:text-[20px] text-gray-8"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              )}
            </DialogTitle>
          )}
          <DialogContent
            sx={{
              margin: 0,
              textAlign: 'center',
              padding: '16px',
              paddingTop: '30px',
              fontSize: '18px',
              minWidth: `${minWidth - 64}px`,
              maxWidth: '360px',
            }}
          >
            {textContent && (
              <DialogContentText id="alert-dialog-description" sx={{ paddingBottom: '16px' }}>
                <span
                  className="break-keep text-base xxs:text-sm text-gray-8"
                  dangerouslySetInnerHTML={{ __html: textContent }}
                ></span>
              </DialogContentText>
            )}
            {componentContent && componentContent}
          </DialogContent>

          <div className="border-t w-full flex justify-bewteen items-center text-center">
            {textLeftBtn && onClickLeftBtn && (
              <div
                className="w-full cursor-pointer"
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  color: '#919191',
                  paddingTop: '12px',
                  paddingBottom: '12px',
                }}
                onClick={onClickLeftBtn}
              >
                {textLeftBtn}
              </div>
            )}
            <div
              className="w-full border-l cursor-pointer"
              style={{
                fontSize: '18px',
                fontWeight: '500',
                color: '#1E42A6',
                paddingTop: '12px',
                paddingBottom: '12px',
              }}
              onClick={onClickRightBtn}
            >
              {textRightBtn}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default BasicPopup;
