import React, { useState } from 'react';

import CapitalInfo from './DetailTab/CapitalInfo';
import ProductHistory from './DetailTab/ProductHistory';
import ProductInfo from './DetailTab/ProductInfo';
import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import '@/css/tailwind.css';

type ProductDetailProps = {
  product: ProductDetailResponse;
};

export default function ProductDetailTab({ product, ...props }: ProductDetailProps) {
  const labelList = [
    { icon: null, title: '차량 상세 정보' },
    { icon: null, title: '차량 이력' },
    { icon: null, title: '할부 계산기' },
  ];
  const [tabNum, setTabNum] = useState(0);
  return (
    <BasicTabs labelList={labelList} value={tabNum} onChange={setTabNum}>
      <ProductInfo product={product}></ProductInfo>
      <ProductHistory product={product}></ProductHistory>
      <CapitalInfo price={String(product.price)}></CapitalInfo>
    </BasicTabs>
  );
}
