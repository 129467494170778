import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { fetchChatRoomList } from '@/api/chat/getChat';
import ChattingListSkeleton from '@/components/Common/Skeleton/ChattingListSkeleton';
import Footer from '@/components/Footer';
import MenuHeader from '@/components/Header/MenuHeader';
import { EmptyChatMessageIcon, ProfileNoneColorIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useChatContext } from '@/contexts/Chat/ChatContext';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { calculateTimeAgo, truncateText } from '@/utils/common';

const ChattingList = () => {
  const navigate = useNavigate();
  const { unreadMessages, setUnreadMessages, setChattingList, chattingList, newChatMessage } = useChatContext();
  const { showToast } = useToastContext();

  const onClickEnterChatRoom = (chatRoomId: number) => {
    navigate(`/chatting/room/${chatRoomId}`);
    setUnreadMessages((prev) => ({ ...prev, [chatRoomId]: 0 }));
  };

  const { data, isError, isLoading, isFetching, isSuccess, refetch } = useQuery(
    ['get-chat-room-list'],
    () => fetchChatRoomList(),
    {
      onSuccess: (response) => {
        const res: ChattingListResponse[] = response;
        setChattingList(res);
        const unreadMessages: { [chatRoomId: number]: number } = {};
        res.forEach((item) => {
          unreadMessages[item.chatRoomId] = item.notReadChatMessageCount ?? 0;
        });
        setUnreadMessages(unreadMessages);
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const getMessageText = (message: ChattingListResponse) => {
    const lastMessage = JSON.parse(message?.lastMessage);
    return !!message && lastMessage?.text
      ? lastMessage?.text
      : !!message && lastMessage?.images
      ? '사진을 보냈습니다.'
      : '';
  };

  useEffect(() => {
    setChattingList((prevList) =>
      [...prevList].sort((a, b) => new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime()),
    );
  }, [newChatMessage]);

  useEffect(() => {}, [chattingList]);
  useEffect(() => {}, [newChatMessage]);
  useEffect(() => {}, [unreadMessages]);

  return (
    <>
      <MenuHeader title="채팅" isMain={true}></MenuHeader>

      <div className="w-full px-2 pt-[60px]">
        {isLoading || isFetching ? (
          <>
            <ChattingListSkeleton></ChattingListSkeleton>
          </>
        ) : chattingList.length > 0 ? (
          <div className="flex flex-col justify-between w-full h-full">
            <div className="min-w-[248px] pb-24">
              {chattingList?.map((item, index) => (
                <div
                  key={item.chatRoomId}
                  className={`relative flex item-center py-3 cursor-pointer ${index !== 0 ? 'my-5' : ''}`}
                  onClick={() => onClickEnterChatRoom(item.chatRoomId)}
                >
                  <div className="rounded-full w-12 h-12 min-w-[48px] mx-2 my-1">
                    {item.profileImageUrl ? (
                      <img
                        src={item?.profileImageUrl}
                        alt="profile-img"
                        className="rounded-full w-full h-full object-cover"
                      />
                    ) : (
                      <ProfileNoneColorIcon width={48} height={48}></ProfileNoneColorIcon>
                    )}
                  </div>
                  <div className="flex-1 flex flex-col justify-center">
                    <dl>
                      <dt className="leading-3 flex items-center">
                        <span className="font-semibold py-1 text-gray-8">
                          {item.memberName ? truncateText(item?.memberName, 3) : ''}
                        </span>
                        <span className="text-gray-7 inline-block text-sm block xxs:hidden xs:hidden">
                          [{item.truckNumber}]
                        </span>
                        <span className="text-gray-5 text-xs">・{calculateTimeAgo(item.lastMessageTime)}</span>
                      </dt>
                      <dd
                        className={`flex-1 max-w-[500px] text-gray-6 text-medium text-sm line-clamp-2 text-ellipsis break-all pr-8 ${
                          unreadMessages[item.chatRoomId] > 0 ? 'text-gray-8' : ''
                        }`}
                      >
                        {getMessageText(item)}
                      </dd>

                      {unreadMessages[item.chatRoomId] > 0 && (
                        <span className="absolute right-16 xxs:right-3 top-10 bg-red rounded-[14px] w-auto h-auto px-1.5 py-0.5 text-center text-xs text-white">
                          {unreadMessages[item.chatRoomId]}
                        </span>
                      )}
                    </dl>
                  </div>
                  <div className="rounded-[12px] w-12 h-12 min-w-[48px] mx-2 my-1 xxs:hidden">
                    {item.productRepresentImageUrl && (
                      <img
                        src={item?.productRepresentImageUrl}
                        alt="profile-img"
                        className="rounded-lg w-full h-full object-cover"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center w-full min-h-[calc(100vh-141px)]">
            <EmptyChatMessageIcon color={colors.gray[4]} width={120} height={120}></EmptyChatMessageIcon>
            <span className="text-gray-7 text-lg mt-8">채팅 내역이 없습니다.</span>
          </div>
        )}
      </div>
      <Footer isMain={true}></Footer>
    </>
  );
};

export default ChattingList;
