import React from 'react';
const Online = () => {
  return (
    <div className="min-w-[320px]">
      <div className="w-full flex justify-center items-center">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_online_cover.png"
          alt="온라인 차량 확인 커버"
          className="w-full"
        />
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">허위 매물인지 확인하세요</h3>
        <p className="pb-8 leading-8">
          허위 매물이란 구매자를 유인할 목적으로 실제로는 존재하지 않는 매물이거나 판매의사 없이 터무니없이 낮은 가격에
          매물을 게시하는 것을 의미해요.
        </p>
        <p className="pb-8 leading-8">
          허위 매물 구매 방지를 하려면 구매하려는 차량에 대해서 차량등록증 및 등록원부를 꼼꼼히 확인하시고 판매자가 실제
          차주 인지 확인해야 해요.
        </p>
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">온라인에서 확인해야 하는 정보</h3>
        <p className="pb-8 leading-8">
          온라인으로 차량을 확인할 때, 아래와 같은 항목이 입력되어 있는지 꼭 확인하고 구매해야 해요.
        </p>
        <ol className="pb-8 leading-8">
          <li>1. 자동차의 압류 및 저당에 관한 정보</li>
          <li>2. 판매자가 자동차 매매업자의 경우, 상호, 주소 및 전화번호에 관한 사항</li>
          <li>3. 자동차 등록번호, 주요 제원 및 옵션에 관한 사항</li>
        </ol>
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">실제 적재함 길이를 확인하세요</h3>
        <p className="pb-8 leading-8">
          영업용 화물차는 화물 운송 목적을 가진 차량이기 때문에 특장의 종류와 특장의 길이가 가장 중요합니다.
        </p>
        <p className="pb-8 leading-8">
          중고 화물차 거래를 진행할 땐, 적재함의 정확한 길이를 체크하고 특이사항이 없는지 확인해야 해요.
        </p>
        <p className="pb-8 leading-8">
          적재함 종류 및 크기를 임의로 변경한 이력이 있거나 구조변경된 사이즈와 실제 사이즈가 다르면 자동차 검사 시
          문제가 생길 수 있어요.
        </p>
        <p className="pb-8 leading-8">
          이러한 사항을 명확히 확인하고 문제시 구조 변경에 대한 협의나 거래자 간 거래 취소를 고려해야 합니다.
        </p>
        <p className="pb-8">적재함 사이즈의 정확한 정보는 아래 URL로 접속하여 확인할 수 있습니다.</p>
        <p className="pb-8">
          한국교통안전공단 사이버검사소 [튜닝고객지원]
          <br />
          <a
            className="underline cursor-pointer break-all text-[#5578F0]"
            href="https://www.cyberts.kr/ts/tcs/csm/readTsTcsCstmrSportMainView.do"
          >
            https://www.cyberts.kr/ts/tcs/csm/readTsTcsCstmrSportMainView.do
          </a>
        </p>
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_online_1.png"
          alt="guide_online_1"
          className="w-full h-full object-cover"
        ></img>
        <p>
          <span className="text-red">① 하중계산</span>을 클릭한 후 <span className="text-red">② 차량 번호</span>를
          입력하세요.
        </p>
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_online_2.png"
          alt="guide_online_2"
          className="w-full h-full object-cover"
        ></img>
        <p className="pb-8">
          결과값이 표시되면 <span className="text-red">③ 전체 길이와 하대 길이</span>를 확인하세요.
        </p>
        <p className="pb-8">꼭! 차량 확인 시 실제 길이와 일치하는지 확인하세요.</p>
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 pt-4 pb-28 px-4">
        <h3 className="font-semibold text-xl py-4">실 차주를 확인하세요</h3>
        <p className="pb-8 leading-8">
          영업용 화물차는 업체(운수사)로 부터 번호판을 임대 받아 사용하는 경우가 많아요.
          <br />
          이러한 경우 등록증 상의 소유자가 운수사(법인)으로 되어있기 때문에 중고화물차 거래시 꼭 실제 차주를 확인해야
          합니다.
        </p>
        <p className="pb-8 leading-8">
          자동차 등록 원부 검토 시 <span className="font-semibold">[현물출자]</span>란에{' '}
          <span className="font-semibold">[홍길동이 현물출자한 차량임]</span>이라는 내용과 현재 차량 소유자가 누구인지
          확인이 가능해요.
        </p>
        <p className="pb-8 leading-8">금전 거래 시 이와 같은 내용을 꼭 확인하시고 진행하시는 걸 추천드려요.</p>
        <p className="pb-8 leading-8">
          * 이 부분은 직트럭 어플에서 차량 확인 시
          <span className="font-semibold">
            [내차판매] {'>'} [차량조회] {'>'}[실소유자 정보]
          </span>{' '}
          사항에서 쉽게 확인이 가능합니다.
        </p>
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_online_3.png"
          alt="guide_online_3"
          className="w-full"
        />
      </div>
    </div>
  );
};
export default Online;
