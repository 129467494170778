import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { nonNumericOrDecimalPattern, positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import { formatNumber, updateSearchParams } from '@/utils/common';

interface ProductProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
  updateDisabledBtn: (value: boolean) => void;
  isEtc: boolean;
}

const CarInfo: React.FC<ProductProps> = ({ productInfo, setProductInfo, updateDisabledBtn, isEtc }) => {
  const keyValueList: KeyValueListType = {
    manufacturerCategories: '제조사',
    model: '모델',
    color: '색상',
    loaded: '적재함 종류',
    transmission: '변속기',
    fuel: '연료',
    garage: '차고지',
  };
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const { productEnum, setProductEnum } = useProductEnumContext();
  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');
  const [disabledModel, setDisabledModel] = useState<boolean>(false);
  const [tonsError, setTonsError] = useState<boolean>(false);
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');
  const [loadedInnerLengthError, setLoadedInnerLengthError] = useState<boolean>(false);
  const [loadedInnerLengthErrorMsg, setLoadedInnerLengthErrorMsg] = useState('');
  const [loadedInnerAreaError, setLoadedInnerAreaError] = useState<boolean>(false);
  const [loadedInnerAreaErrorMsg, setLoadedInnerAreaErrorMsg] = useState('');
  const [loadedInnerHeightError, setLoadedInnerHeightError] = useState<boolean>(false);
  const [loadedInnerHeightErrorMsg, setLoadedInnerHeightErrorMsg] = useState('');
  const [distanceError, setDistanceError] = useState<boolean>(false);
  const [powerError, setPowerError] = useState<boolean>(false);

  const loadedLengthInputRef = useRef<HTMLInputElement>(null);
  const loadedAreaInputRef = useRef<HTMLInputElement>(null);
  const loadedHeightInputRef = useRef<HTMLInputElement>(null);
  const distanceRef = useRef<HTMLInputElement>(null);
  const fuelRef = useRef<HTMLInputElement>(null);
  const powerRef = useRef<HTMLInputElement>(null);
  const colorRef = useRef<HTMLInputElement>(null);
  const garageRef = useRef<HTMLInputElement>(null);

  const getFilterManufacturerCategories = () => {
    if (productInfo?.manufacturerCategories && productEnum) {
      const manufacturerCategories = productEnum['manufacturerAndModel'].map((item) => item.manufacturerCategories);
      return manufacturerCategories;
    }
    return [];
  };

  const getFilteredModels = () => {
    if (productInfo?.manufacturerCategories && productEnum) {
      const models = productEnum['manufacturerAndModel']
        .filter((item) => item.manufacturerCategories.id === productInfo.manufacturerCategories?.id)
        .map((item) => item.model);
      return models;
    }
    return [];
  };

  const onClickSelectBox = (key: string) => {
    if (!productEnum) return;

    setTitle(key);

    if (key === 'model') {
      if (disabledModel || !productInfo?.manufacturerCategories) return;
      const filteredModels = getFilteredModels();
      if (filteredModels.length > 0) {
        const flattenedArr = filteredModels.reduce<Model[]>((acc, val) => acc.concat(val), []);
        const option = flattenedArr.map((item, index) => {
          return { code: String(item.id), desc: item.name };
        });
        setOptionData(option);
      }
    } else if (key === 'manufacturerCategories') {
      if (!isEtc) return;
      const filteredManufacturerCategories = getFilterManufacturerCategories();

      if (filteredManufacturerCategories.length > 0) {
        const flattenedArr = filteredManufacturerCategories.reduce<Model[]>((acc, val) => acc.concat(val), []);
        const option = flattenedArr.map((item, index) => {
          return { code: String(item.id), desc: item.name };
        });
        setOptionData(option);
      }
    } else {
      setOptionData(productEnum[key]);
    }
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    let value = { id: Number(item.code), name: item.desc };
    if (key === 'manufacturerCategories') {
      setProductInfo({
        ...productInfo,
        manufacturerCategories: value,
        model: {},
      } as ProductDetailResponse);
    } else if (key === 'model') {
      const value = { id: Number(item.code), name: item.desc };
      setProductInfo({ ...productInfo, [key]: value } as ProductDetailResponse);
    } else {
      setProductInfo({ ...productInfo, [key]: item } as ProductDetailResponse);
    }

    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber?: boolean) => {
    let { name, value } = e.target;
    const convertedValue = String(value).replace(nonNumericOrDecimalPattern, '');

    switch (name) {
      case 'power':
        setPowerError(!!!convertedValue);
        break;
      case 'tons':
        setTonsError(!!!convertedValue);
        setTonsErrorMsg('톤수는 필수값 입니다.');
        break;
      case 'loadedInnerLength':
        setLoadedInnerLengthError(!!!convertedValue);
        setLoadedInnerLengthErrorMsg('적재함 길이는 필수값입니다.');
        if (convertedValue && Number(convertedValue) < 1) {
          setLoadedInnerLengthError(true);
          setLoadedInnerLengthErrorMsg('적재함 길이는 1이상의 값을 입력해주세요.');
        }
        break;
      case 'loadedInnerArea':
        setLoadedInnerAreaError(!!!convertedValue);
        setLoadedInnerAreaErrorMsg('적재함 너비는 필수값입니다.');
        if (convertedValue && Number(convertedValue) < 1) {
          setLoadedInnerAreaError(true);
          setLoadedInnerAreaErrorMsg('적재함 너비는 1이상의 값을 입력해주세요.');
        }
        break;
      case 'loadedInnerHeight':
        setLoadedInnerHeightError(!!!convertedValue);
        setLoadedInnerHeightErrorMsg('적재함 높이는 필수값입니다.');
        if (convertedValue && Number(convertedValue) < 1) {
          setLoadedInnerHeightError(true);
          setLoadedInnerHeightErrorMsg('적재함 높이는 1이상의 값을 입력해주세요.');
        }
        break;
      case 'distance':
        setDistanceError(!!!convertedValue);
        break;
    }

    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(convertedValue) && !convertedValue.startsWith('-')) {
        const str = convertedValue === '' ? null : convertedValue;
        setProductInfo({ ...productInfo, [name]: str } as ProductDetailResponse);
      }
    } else if (isOnlyNumber === false) {
      if (positiveFloatRegex.test(convertedValue.trim()) || convertedValue.trim() === '') {
        const str = convertedValue.trim() === '' ? null : convertedValue.trim();
        const val = Number(str);
        if (name === 'tons') {
          if (!isNaN(val) && val < 30) {
            setProductInfo({ ...productInfo, [name]: str } as ProductDetailResponse);
          } else {
            setTonsError(true);
            setTonsErrorMsg('30톤 미만으로 입력해주세요.');

            setTimeout(() => {
              setTonsError(false);
              setTonsErrorMsg('');
            }, 2000);
          }
        } else {
          setProductInfo({ ...productInfo, [name]: str } as ProductDetailResponse);
        }
      }
    } else {
      const str = convertedValue === '' ? null : convertedValue;
      setProductInfo({ ...productInfo, [name]: str } as ProductDetailResponse);
    }
  };

  useEffect(() => {
    const filteredModels = getFilteredModels();
    if (filteredModels.length === 1) {
      setDisabledModel(true);

      const flattenedArr = filteredModels.reduce<Model[]>((acc, val) => acc.concat(val), []);
      const [firstModel] = flattenedArr;

      if (flattenedArr.length === 1) {
        setProductInfo({
          ...productInfo,
          model: { id: firstModel.id, name: firstModel.name },
        } as ProductDetailResponse);
      } else {
        setDisabledModel(false);
      }
    }
  }, [productInfo.manufacturerCategories]);

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      const { updatedParams, error, errorMsg } = updateSearchParams(productInfo, key, valueNum);
      setProductInfo(updatedParams);
      switch (key) {
        case 'loadedInnerLength':
          setLoadedInnerLengthError(error);
          setLoadedInnerLengthErrorMsg(errorMsg);
          break;
        case 'loadedInnerArea':
          setLoadedInnerAreaError(error);
          setLoadedInnerAreaErrorMsg(errorMsg);
          break;
        case 'loadedInnerHeight':
          setLoadedInnerHeightError(error);
          setLoadedInnerHeightErrorMsg(errorMsg);
          break;
      }
    }
  };

  useEffect(() => {
    if (productInfo) {
      if (!productInfo?.loadedInnerLength) return;
      else if (
        productInfo.manufacturerCategories.code !== 'ETC' &&
        productInfo.model.id !== 45 &&
        Number(productInfo.loadedInnerLength) > 10.5
      ) {
        setLoadedInnerLengthError(true);
        setLoadedInnerLengthErrorMsg('적재함 길이는 10.3이하의 값을 입력해주세요.');
      } else if (Number(productInfo?.tons) >= 4.5) {
        setLoadedInnerLengthError(productInfo.loadedInnerLength < 5);
        setLoadedInnerLengthErrorMsg('적재함 길이를 다시 한번 확인해주세요.');
      } else if (Number(productInfo?.tons) >= 2) {
        setLoadedInnerLengthError(productInfo.loadedInnerLength < 3);
        setLoadedInnerLengthErrorMsg('적재함 길이를 다시 한번 확인해주세요.');
      } else {
        setLoadedInnerLengthError(productInfo.loadedInnerLength < 2);
        setLoadedInnerLengthErrorMsg('적재함 길이를 다시 한번 확인해주세요.');
      }
    }
  }, [productInfo?.loadedInnerLength]);

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'loadedInnerLength') {
        loadedLengthInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerArea') {
        loadedAreaInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerHeight') {
        loadedHeightInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const showOnlyLength = () => {
    return (
      productInfo?.loaded?.code === 'CARGO' ||
      productInfo?.loaded?.code === 'TANKLORRY' ||
      productInfo?.loaded?.code === 'TRAILER' ||
      productInfo?.loaded?.code === 'LADDER' ||
      productInfo?.loaded?.code === 'AUTOLADDER' ||
      productInfo?.loaded?.code === 'TONGS'
    );
  };

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setProductInfo({ ...productInfo, [key]: newVal } as ProductDetailResponse);
  };

  useEffect(() => {
    updateDisabledBtn(
      !productInfo?.manufacturerCategories?.name ||
        !productInfo?.model?.name ||
        tonsError ||
        loadedInnerLengthError ||
        loadedInnerAreaError ||
        loadedInnerHeightError ||
        distanceError ||
        powerError,
    );
  }, [
    productInfo?.manufacturerCategories?.name,
    productInfo?.model?.name,
    tonsError,
    loadedInnerLengthError,
    loadedInnerAreaError,
    loadedInnerHeightError,
    distanceError,
    powerError,
  ]);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[30px]">
        {isEtc && (
          <div className="py-5 px-4 rounded-[10px] bg-gray-1 text-red font-medium text-sm xxs:text-xs leading-[18px]">
            * 기타(쌍용 외) 제조사에서 타 제조사로 변경 시 추후 수정이 불가능하니 유의해주세요.
          </div>
        )}

        <div onClick={() => onClickSelectBox('manufacturerCategories')}>
          <TextInputLabelUp
            name="manufacturerCategories"
            label="제조사"
            placeholder="제조사 선택"
            value={productInfo?.manufacturerCategories?.name}
            type="text"
            disabled={!isEtc || false}
            readOnly
            suffix={isEtc && <ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
            required
          ></TextInputLabelUp>
        </div>

        <div onClick={() => onClickSelectBox('model')}>
          <TextInputLabelUp
            name="model"
            label="모델"
            placeholder="모델 선택"
            value={productInfo?.model?.name || ''}
            type="text"
            readOnly
            required
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>

        <TextInputLabelUp
          name="tons"
          label="톤수"
          placeholder="톤수 입력"
          value={productInfo?.tons || ''}
          onChange={(e) => onChangeFormInput(e, false)}
          error={tonsError}
          errorMsg={tonsErrorMsg}
          inputMode="numeric"
          suffix="t"
          required
          maxLength={4}
        ></TextInputLabelUp>

        <div onClick={() => onClickSelectBox('loaded')}>
          <TextInputLabelUp
            name="loaded"
            label="적재함 종류"
            placeholder="적재함 종류 선택"
            value={productInfo?.loaded?.desc || ''}
            type="text"
            readOnly
            required
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>

        <div>
          <label className="text-base font-medium mb-3 text-gray-8">
            적재함 길이(내측 사이즈)
            <span className="font-normal text-red ml-1">(필수)</span>
          </label>
          <div className="w-full flex justify-center items-center pt-2">
            <label className="text-base font-medium text-gray-8 min-w-[60px]">길이</label>
            <TextInputLabelOff
              ref={loadedLengthInputRef}
              name="loadedInnerLength"
              placeholder="길이 입력"
              value={productInfo?.loadedInnerLength ? String(productInfo?.loadedInnerLength) : ''}
              onChange={(e) => onChangeFormInput(e, false)}
              onBlur={(e) => handleInputBlur(e, 'loadedInnerLength')}
              onFocus={(e) => scrollIntoView(e)}
              error={loadedInnerLengthError}
              errorMsg={loadedInnerLengthErrorMsg}
              fontSize={18}
              height={36}
              type="number"
              inputMode="numeric"
              suffix="m"
            ></TextInputLabelOff>
          </div>
          {productInfo?.loaded && !showOnlyLength() && (
            <>
              <div className="w-full flex justify-center items-center pt-5">
                <label className="text-base font-medium text-gray-8 min-w-[60px]">너비</label>
                <TextInputLabelOff
                  ref={loadedAreaInputRef}
                  name="loadedInnerArea"
                  placeholder="너비 입력"
                  value={productInfo?.loadedInnerArea ? String(productInfo?.loadedInnerArea) : ''}
                  onChange={(e) => onChangeFormInput(e, false)}
                  onBlur={(e) => handleInputBlur(e, 'loadedInnerArea')}
                  onFocus={(e) => scrollIntoView(e)}
                  error={loadedInnerAreaError}
                  errorMsg={loadedInnerAreaErrorMsg}
                  fontSize={18}
                  height={36}
                  type="number"
                  inputMode="numeric"
                  suffix="m"
                ></TextInputLabelOff>
              </div>

              <div className="w-full flex justify-center items-center pt-5">
                <label className="text-base font-medium text-gray-8 min-w-[60px]">높이</label>
                <TextInputLabelOff
                  ref={loadedHeightInputRef}
                  name="loadedInnerHeight"
                  placeholder="높이 입력"
                  value={productInfo?.loadedInnerHeight ? String(productInfo?.loadedInnerHeight) : ''}
                  onChange={(e) => onChangeFormInput(e, false)}
                  onBlur={(e) => handleInputBlur(e, 'loadedInnerHeight')}
                  onFocus={(e) => scrollIntoView(e)}
                  error={loadedInnerHeightError}
                  errorMsg={loadedInnerHeightErrorMsg}
                  fontSize={18}
                  height={36}
                  type="number"
                  inputMode="numeric"
                  suffix="m"
                ></TextInputLabelOff>
              </div>
            </>
          )}
        </div>

        <div>
          <label className="text-base font-medium text-gray-8">
            가변축
            <span className="font-normal text-red ml-1">(필수)</span>
          </label>
          <div className="px-1">
            <RadioList
              name="axis"
              list={productEnum?.axis || []}
              value={productInfo?.axis?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'axis');
              }}
            ></RadioList>
          </div>
        </div>

        <div>
          <label className="text-base font-medium text-gray-8">
            변속기
            <span className="font-normal text-red ml-1">(필수)</span>
          </label>
          <div className="px-1">
            <RadioList
              name="transmission"
              list={(productEnum?.transmission.filter((item) => item.code !== 'BOTH') as OptionType[]) || []}
              value={productInfo?.transmission?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'transmission');
              }}
              justify="equal"
            ></RadioList>
          </div>
        </div>

        <TextInputLabelUp
          ref={distanceRef}
          name="distance"
          label="주행거리"
          placeholder="주행거리 입력"
          value={formatNumber(productInfo?.distance?.toString(), true) || ''}
          onChange={(e) => onChangeFormInput(e, true)}
          onFocus={(e) => scrollIntoView(e)}
          inputMode="numeric"
          required
          error={distanceError}
          errorMsg={'주행거리는 필수값입니다.'}
          suffix="km"
          maxLength={8}
        ></TextInputLabelUp>

        <div onClick={() => onClickSelectBox('fuel')}>
          <TextInputLabelUp
            ref={fuelRef}
            name="fuel"
            label="연료"
            placeholder="연료 선택"
            value={productInfo?.fuel?.desc || ''}
            type="text"
            readOnly
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
            required
          ></TextInputLabelUp>
        </div>

        <TextInputLabelUp
          ref={powerRef}
          name="power"
          label="마력수"
          placeholder="마력수 입력"
          value={productInfo?.power || ''}
          error={powerError}
          errorMsg={'마력수는 필수값입니다.'}
          onChange={(e) => onChangeFormInput(e, true)}
          onFocus={(e) => scrollIntoView(e)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onClickSelectBox('fuel');
            }
          }}
          required
          inputMode="numeric"
        ></TextInputLabelUp>

        <div onClick={() => onClickSelectBox('color')}>
          <TextInputLabelUp
            ref={colorRef}
            name="color"
            label="색상"
            placeholder="색상 선택"
            value={productInfo?.color?.desc || ''}
            type="text"
            readOnly
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>

        <div onClick={() => onClickSelectBox('garage')}>
          <TextInputLabelUp
            ref={garageRef}
            name="garage"
            label="차고지"
            placeholder="차고지 선택"
            value={productInfo?.garage?.desc || ''}
            type="text"
            readOnly
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>
      </div>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={
              (productInfo && productInfo[title]?.id?.toString()) || (productInfo && productInfo[title]?.code) || ''
            }
            onChange={(val) => {
              onClickItem(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </DownToUpDetailPopup>
    </div>
  );
};

export default CarInfo;
