import { PAGE_NAMES } from '@/const/common';
import { BEFORE_SALE } from '@/const/productStatus';

export const getPageName = (data: ProductDetailResponse): keyof typeof PAGE_NAMES => {
  if (data.status.code === BEFORE_SALE) {
    if (!data.model) return 'model';
    if (!data.tons) return 'tons';
    if (!data.loaded || !data.loadedInnerLength) return 'loaded';
    if (!data.axis) return 'axis';
    if (!data.type) return 'price-trend';
    if (!data.transmission || !data.distance || !data.fuel || !data.power || !data.productsImage)
      return 'additional-info';
    if (
      !data?.productsImage?.frontSideImageUrl &&
      !data?.productsImage?.backSideImageUrl &&
      !data?.productsImage?.frontImageUrl
    )
      return 'additional-info';
    if (
      !data?.productsImage?.frontSideImageUrl ||
      !data?.productsImage?.backSideImageUrl ||
      !data?.productsImage?.frontImageUrl
    )
      return 'photo';
    if (!data.price) return 'price';
  }
  return 'price';
};
