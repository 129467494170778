import React from 'react';

interface OutlinedBadgeProps {
  text: string;
  color: string;
}

const OutlinedBadge = ({ text, color }: OutlinedBadgeProps) => {
  const getColor = (color: string) => {
    switch (color) {
      case 'primary':
        return 'border-primary text-primary';
      case 'red':
        return 'border-red text-red';
      case 'green':
        return 'border-green text-green';
    }
  };

  return (
    <div className="py-2">
      <span
        className={`text-center w-auto text-sm px-2 py-1 rounded-[5px] font-bold bg-white border ${getColor(color)}`}
      >
        {text}
      </span>
    </div>
  );
};

export default OutlinedBadge;
