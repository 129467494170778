import React, { ReactNode } from 'react';

interface SquareButton {
  bgColor: string;
  textColor: string;
  icon: ReactNode;
  title: string;
  description: string;
  minWidth: number;
  minHeight: number;
  onClick: () => void;
  isSelected: boolean | undefined;
}

function SquareButton({
  title,
  description,
  bgColor,
  textColor,
  icon,
  minWidth,
  minHeight,
  onClick,
  isSelected,
}: SquareButton) {
  return (
    <div
      className={`text-[${textColor}] w-full h-full rounded-lg text-center flex flex-col items-center justify-center p-5 xxs:px-4 m-2 min-w-[${minWidth}px] min-h-[${minHeight}px] box-border`}
      style={{ border: isSelected ? `1px solid ${textColor}` : '1px solid transparent', backgroundColor: bgColor }}
      onClick={onClick}
    >
      <div className="flex justify-center items-center mb-3 h-[32px]">{icon}</div>
      <p className="font-bold mb-2 text-base xxs:text-sm break-keep w-[105px]">{title}</p>
      <p className="text-xs font-semibold break-keep w-[105px]">{description}</p>
    </div>
  );
}

export default SquareButton;
