import LicensePlateInput from '@/components/Common/LicensePlateInput';
import React from 'react';

interface LicensePlateInputProps {
  updatePage: (pageNum: number) => void;
  ownerInfo: OwnerInfo;
  setOwnerInfo: React.Dispatch<React.SetStateAction<OwnerInfo>>;
}
interface ErrorInfoProps {
  errorInfo: ErrorInfo | undefined;
  setErrorInfo: React.Dispatch<React.SetStateAction<ErrorInfo | undefined>>;
}

const MyCarNumberInput: React.FC<LicensePlateInputProps & ErrorInfoProps> = ({
  updatePage,
  ownerInfo,
  setOwnerInfo,
  errorInfo,
  setErrorInfo,
}) => {
  return (
    <>
      <div className="flex flex-col justify-between w-full p-4">
        <h2 className="text-2xl font-bold mt-2 mb-6 text-gray-8">내 트럭, 팔 때 시세 얼마?</h2>
        <LicensePlateInput
          updatePage={updatePage}
          ownerInfo={ownerInfo}
          setOwnerInfo={setOwnerInfo}
          errorInfo={errorInfo}
          setErrorInfo={setErrorInfo}
        ></LicensePlateInput>
      </div>
    </>
  );
};

export default MyCarNumberInput;
