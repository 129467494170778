import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import CheckBoxWithEtc from '@/components/Common/Input/CheckBox/CheckBoxWithEtcProps';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextAreaLabelOff from '@/components/Common/Input/TextAreaLabelOff';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { phoneNumberPatternRegex } from '@/const/regex';
import { PRODUCT_SALE_URL } from '@/const/url';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom } from '@/store/products';

const keyValueList: KeyValueListType = {
  transportStartLocate: '주요운행구간 시작지점',
  transportEndLocate: '주요운행구간 도착지점',
};

function DetailInfoForm() {
  const { productEnum } = useProductEnumContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);

  const [isOpenOptionModal, setIsOpenOptionModal] = useState(false);
  const [title, setTitle] = useState('');
  const [optionData, setOptionData] = useState<OptionType[]>([]);

  const [isShowWarningPopup, setIsShowWarningPopup] = useState(false);

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  useBlockNavigation(setIsShowExitConfirmPopup);

  const transformedCarOptionData = {
    normalOption: {
      etc: productFormData?.carOption?.normalOption?.etc || '',
      option: productFormData?.carOption?.normalOption?.option.map((item) => item.code) || [],
    },
    additionalOption: {
      etc: productFormData?.carOption?.additionalOption?.etc || '',
      option: productFormData?.carOption?.additionalOption?.option.map((item) => item.code) || [],
    },
    breakOption: {
      etc: productFormData?.carOption?.breakOption?.etc || '',
      option: productFormData?.carOption?.breakOption?.option.map((item) => item.code) || [],
    },
  };

  const transformedMaintenanceData = {
    maintenanceCategories: productFormData?.maintenance?.maintenanceData?.map((item) => item.code) || [],
    etc: productFormData?.maintenance?.etc || '',
  };

  const getChangedData = () => {
    const changedData: Partial<ProductRegisterRequest> = {};

    if (prevData?.accidentsHistory?.accident !== productFormData?.accidentsHistory?.accident) {
      changedData.accident = productFormData?.accidentsHistory?.accident;
    }
    if (prevData?.accidentsHistory?.accidentContents !== productFormData?.accidentsHistory?.accidentContents) {
      changedData.accidentContents = productFormData?.accidentsHistory?.accidentContents || '';
    }
    if (
      prevData?.maintenance?.maintenanceData !== productFormData?.maintenance?.maintenanceData ||
      prevData?.maintenance?.etc !== productFormData?.maintenance?.etc
    ) {
      changedData.maintenanceData = transformedMaintenanceData;
    }
    if (prevData?.transportGoods !== productFormData?.transportGoods) {
      changedData.transportGoods = productFormData?.transportGoods;
    }
    if (prevData?.transportStartLocate?.code !== productFormData?.transportStartLocate?.code) {
      changedData.transportStartLocate = productFormData?.transportStartLocate?.code;
    }
    if (prevData?.transportEndLocate?.code !== productFormData?.transportEndLocate?.code) {
      changedData.transportEndLocate = productFormData?.transportEndLocate?.code;
    }
    if (prevData?.tireStatus?.code !== productFormData?.tireStatus?.code) {
      changedData.tireStatus = productFormData?.tireStatus?.code;
    }
    if (
      prevData?.carOption?.normalOption?.option !== productFormData?.carOption?.normalOption?.option ||
      prevData?.carOption?.normalOption?.etc !== productFormData?.carOption?.normalOption?.etc ||
      prevData?.carOption?.breakOption?.option !== productFormData?.carOption?.breakOption?.option ||
      prevData?.carOption?.breakOption?.etc !== productFormData?.carOption?.breakOption?.etc ||
      prevData?.carOption?.additionalOption?.option !== productFormData?.carOption?.additionalOption?.option ||
      prevData?.carOption?.additionalOption?.etc !== productFormData?.carOption?.additionalOption?.etc
    ) {
      changedData.carOption = transformedCarOptionData;
    }
    if (prevData?.detailContent !== productFormData?.detailContent) {
      changedData.detailContent = productFormData?.detailContent;
    }

    return changedData;
  };

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({
    url: PRODUCT_SALE_URL,
  });

  const handleClickExitBtn = () => {
    setIsShowExitConfirmPopup(false);
    if (isValidPhoneNumber(productFormData?.detailContent || '')) {
      setIsShowWarningPopup(true);
      return;
    }

    const changedData = getChangedData();

    if (Object.keys(changedData).length > 0) {
      const request: ProductRegisterRequest = {
        id: Number(productFormData?.id),
        ...changedData,
      };
      updateProductTemporarySaveMutation.mutate(request);
    } else {
      navigate(PRODUCT_SALE_URL, { replace: true });
    }
  };

  const handleCloseExitPopup = () => {
    setIsShowExitConfirmPopup(false);
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/photo/${id}`,
  });

  const handleClickNext = () => {
    if (isValidPhoneNumber(productFormData?.detailContent || '')) {
      setIsShowWarningPopup(true);
      return;
    }

    const changedData = getChangedData();

    if (Object.keys(changedData).length > 0) {
      const request: ProductRegisterRequest = {
        id: Number(productFormData?.id),
        ...changedData,
      };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/photo/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/additional-info/${id}`, { replace: true });
  };

  const handleClickSelecBox = (key: string) => {
    if (!productEnum) return;
    setTitle(key);
    setOptionData(productEnum['garage']);
    setIsOpenOptionModal(true);
  };

  const handleSelectOption = (item: OptionDataType, key: string) => {
    setProductFormData({ ...productFormData, [key]: item } as ProductDetailResponse);
    setTimeout(() => {
      setIsOpenOptionModal(false);
    }, 200);
  };

  const isValidPhoneNumber = (content: string | undefined) => {
    if (!content) {
      return;
    }
    return phoneNumberPatternRegex.test(content);
  };

  const handleChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target;
    if (name === 'accident') {
      const booleanValue = value === 'true';
      if (!booleanValue) {
        setProductFormData((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue, accidentContents: '' },
        }));
      } else {
        setProductFormData((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue },
        }));
      }
    } else if (name === 'accidentContents') {
      setProductFormData((prevProductInfo) => ({
        ...prevProductInfo,
        accidentsHistory: { ...prevProductInfo.accidentsHistory, accidentContents: value },
      }));
    } else {
      setProductFormData({ ...productFormData, [name]: value } as ProductDetailResponse);
    }
  };

  const handleChangeRadioInput = (newVal: OptionType, key?: string) => {
    if (key === 'accident') {
      const booleanValue = newVal.code === 'true';
      if (!booleanValue) {
        setProductFormData((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue, accidentContents: '' },
        }));
      } else {
        setProductFormData((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue },
        }));
      }
    } else if (key === 'tireStatus') {
      setProductFormData({ ...productFormData, [key]: newVal } as ProductDetailResponse);
    }
  };

  const isDisabledNextBtn = () => {
    return productFormData?.accidentsHistory?.accident === true && !productFormData?.accidentsHistory.accidentContents;
  };

  return (
    <>
      <MenuHeader title="내차판매" />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="py-4 my-2 pb-24">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">상세 정보를 입력해주세요.</h3>
            <RoundBadge text="7/9" />
          </div>

          <div className="flex flex-col gap-[30px] pt-[30px]">
            <div>
              <label className="text-base font-medium mb-3 text-gray-8">사고유무</label>
              <div className="px-1">
                <RadioList
                  name="accident"
                  list={[
                    { code: 'false', desc: '없음' },
                    { code: 'true', desc: '있음' },
                  ]}
                  value={`${!!productFormData?.accidentsHistory?.accident}`}
                  onChange={(val) => {
                    handleChangeRadioInput(val, 'accident');
                  }}
                  justify="equal"
                />
              </div>
            </div>

            <div>
              <label className="text-base font-medium mb-3 text-gray-8">
                사고 상세내용
                <span
                  className={`${
                    productFormData?.accidentsHistory?.accident === false ? 'hidden' : ''
                  } font-normal text-red ml-1`}
                >
                  (필수)
                </span>
              </label>
              <TextAreaLabelOff
                name="accidentContents"
                placeholder="상세내용 입력"
                value={productFormData?.accidentsHistory?.accidentContents || ''}
                fontSize={18}
                minRows={1}
                maxRows={5}
                onChange={(e) => handleChangeFormInput(e)}
                error={isDisabledNextBtn()}
                errorMsg="사고 상세내용을 입력해주세요."
                disabled={productFormData?.accidentsHistory?.accident === false}
              />
            </div>

            <div>
              <label className="text-base font-medium mb-3 text-gray-8">차량 정비 이력</label>
              <p className="text-gray-6 text-sm">※ 최근 1년 이내로 정비한 항목을 선택해주세요.</p>
              <div className="pt-2">
                <CheckBoxWithEtc
                  title="maintenance"
                  name=""
                  optionList={productEnum?.maintenanceCategories}
                  productInfo={productFormData}
                  setProductInfo={setProductFormData}
                />
              </div>
            </div>

            <TextInputLabelUp
              name="transportGoods"
              label="운송물품 입력"
              placeholder="운송물품 입력"
              value={productFormData?.transportGoods || ''}
              onChange={(e) => handleChangeFormInput(e)}
            />

            <div>
              <label className="text-base font-medium mb-3 text-gray-8">주요운행구간</label>
              <div className="w-full flex justify-center items-center mb-2">
                <div className="w-full" onClick={() => handleClickSelecBox('transportStartLocate')}>
                  <TextInputLabelOff
                    name="transportStartLocate"
                    placeholder="상차지 선택"
                    value={productFormData?.transportStartLocate?.desc || ''}
                    height={36}
                    fontSize={18}
                    suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                    readOnly
                  />
                </div>
                <p className="text-gray-6 text-base mx-2">~</p>
                <div className="w-full" onClick={() => handleClickSelecBox('transportEndLocate')}>
                  <TextInputLabelOff
                    name="transportEndLocate"
                    placeholder="하차지 선택"
                    value={productFormData?.transportEndLocate?.desc || ''}
                    height={36}
                    fontSize={18}
                    suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="text-base font-medium mb-3 text-gray-8">차량 타이어 상태</label>
              <div className="px-1">
                <RadioList
                  name="tireStatus"
                  list={productEnum?.tireStatus || []}
                  value={productFormData?.tireStatus?.code || ''}
                  onChange={(val) => {
                    handleChangeRadioInput(val, 'tireStatus');
                  }}
                  justify="equal"
                />
              </div>
            </div>

            <div>
              <label className="text-base font-medium mb-3 text-gray-8">차량 옵션</label>
              <div className="flex flex-col gap-3">
                <CheckBoxWithEtc
                  title="normalOption"
                  name="일반 옵션"
                  optionList={productEnum?.normalOption}
                  productInfo={productFormData}
                  setProductInfo={setProductFormData}
                />
                <CheckBoxWithEtc
                  title="additionalOption"
                  name="추가 옵션"
                  optionList={productEnum?.additionalOption}
                  productInfo={productFormData}
                  setProductInfo={setProductFormData}
                />
                <CheckBoxWithEtc
                  title="breakOption"
                  name="브레이크 옵션"
                  optionList={productEnum?.breakOption}
                  productInfo={productFormData}
                  setProductInfo={setProductFormData}
                />
              </div>
            </div>
            <div>
              <label className="text-base font-medium mb-3 text-gray-8">차량 상세설명</label>
              <div className="rounded-lg bg-blue-0 p-4 mt-2 mb-4">
                <p className="text-gray-8 text-sm">
                  기타 옵션 및 수리내역 등 차량의 상세 정보를 남겨주세요. 예) 네고 가능/네고 불가능 등<br></br>
                  <br></br>
                  <span className="text-red">* 개인 정보 보호를 위해 현재 전화번호 입력은 제한되어있습니다. </span>
                </p>
              </div>

              <TextAreaLabelOff
                name="detailContent"
                placeholder="상세설명 입력"
                value={productFormData?.detailContent || ''}
                fontSize={18}
                minRows={1}
                maxRows={5}
                onChange={(e) => handleChangeFormInput(e)}
              />
            </div>
          </div>
        </div>
        <DownToUpDetailPopup
          isShow={isOpenOptionModal}
          onClosePopup={() => setIsOpenOptionModal(false)}
          title={keyValueList[title]}
        >
          <div className="px-4 pb-8">
            <RadioList
              name={title}
              list={optionData}
              value={productFormData?.[title as keyof ProductDetailResponse]?.code || ''}
              onChange={(val) => {
                handleSelectOption(val, title);
              }}
              horizontal={false}
            />
          </div>
        </DownToUpDetailPopup>

        <BasicPopup
          isShow={isShowWarningPopup}
          title=""
          textContent={
            '<span class="font-medium text-lg text-gray-8">개인 정보 보호를 위해 현재<br/>휴대폰 번호 입력은 제한되어 있습니다.</span>'
          }
          textRightBtn="확인"
          onClickRightBtn={() => setIsShowWarningPopup(false)}
        />
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="다음(사진등록)"
          disabledRight={isDisabledNextBtn()}
          onClickRightButton={handleClickNext}
        />

        <BasicPopup
          isShow={isShowExitConfirmPopup}
          title="정말 나가시겠어요?"
          textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
          textLeftBtn="취소"
          onClickLeftBtn={handleCloseExitPopup}
          textRightBtn="나가기"
          onClickRightBtn={handleClickExitBtn}
        />
      </div>
    </>
  );
}

export default DetailInfoForm;
