import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { nonNumericOrDecimalPattern, positiveFloatRegex } from '@/const/regex';
import { PRODUCT_SALE_URL } from '@/const/url';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom } from '@/store/products';
import { formatValueToDecimalPlaces } from '@/utils/common';

function TonsForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);
  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);

  const [tonsError, setTonsError] = useState(false);
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  useBlockNavigation(setIsShowExitConfirmPopup);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({
    url: PRODUCT_SALE_URL,
  });

  const handleClickExitBtn = () => {
    setIsShowExitConfirmPopup(false);

    const hasChanges = productFormData?.tons && prevData?.tons !== productFormData?.tons;

    if (hasChanges) {
      const request = { id: Number(productFormData?.id), tons: Number(productFormData?.tons) };
      updateProductTemporarySaveMutation.mutate(request);
    } else {
      navigate(PRODUCT_SALE_URL, { replace: true });
    }
  };

  const handleCloseExitPopup = () => {
    setIsShowExitConfirmPopup(false);
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/loaded/${id}`,
  });

  const handleClickNext = () => {
    const hasChanges = prevData?.tons !== productFormData?.tons;

    if (hasChanges) {
      const request = { id: Number(productFormData?.id), tons: Number(productFormData?.tons) };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/loaded/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/model/${id}`, { replace: true });
  };

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target;
    const convertedValue = String(value).replace(nonNumericOrDecimalPattern, '');

    const decimalPlaces = 1;
    const formattedValue = formatValueToDecimalPlaces(convertedValue, decimalPlaces);

    setTonsError(!!!formattedValue);
    setTonsErrorMsg('톤수는 필수값 입니다.');

    if (positiveFloatRegex.test(formattedValue.trim()) || formattedValue.trim() === '') {
      const str = formattedValue.trim() === '' ? null : formattedValue.trim();
      const val = Number(str);

      if (!isNaN(val) && val < 30) {
        setProductFormData({ ...productFormData, [name]: str } as ProductDetailResponse);
      } else {
        setProductFormData({ ...productFormData, [name]: str } as ProductDetailResponse);
        setTonsError(true);
        setTonsErrorMsg('30톤 미만으로 입력해주세요.');
      }
    }
  };

  return (
    <>
      <MenuHeader title="내차판매" />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="pt-6 text-gray-8">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold break-keep">톤수를 입력해주세요.</h3>
            <RoundBadge text="2/9" />
          </div>
          <div className="text-base pt-[30px] pb-24">
            <TextInputLabelOff
              name="tons"
              placeholder="톤수 입력"
              value={productFormData?.tons || ''}
              onChange={(e) => handleChangeTonsInput(e)}
              error={tonsError}
              errorMsg={tonsErrorMsg}
              fontSize={18}
              height={36}
              inputMode="numeric"
              suffix="t"
              maxLength={4}
            />
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="다음(적재함)"
          onClickRightButton={handleClickNext}
          disabledRight={!productFormData?.tons || tonsError}
        />
      </div>
      <BasicPopup
        isShow={isShowExitConfirmPopup}
        title="정말 나가시겠어요?"
        textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleCloseExitPopup}
        textRightBtn="나가기"
        onClickRightBtn={handleClickExitBtn}
      />
    </>
  );
}

export default TonsForm;
