import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon, PencilIcon } from '@/components/Icon';
import ProfileImage from '@/components/More/ProfileImage';
import { colors } from '@/const/colors';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';

const SettingPage = () => {
  const navigate = useNavigate();
  const { member, removeMember } = useMemberContext();
  const { showToast } = useToastContext();

  const [isShow, setIsShow] = useState(false);
  const [popupProps, setPopupProps] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textLeftBtn: '',
    onClickLeftBtn: () => {},
    textRightBtn: '',
    onClickRightBtn: () => {},
  });

  const close = () => {
    setIsShow(false);
  };

  const logout = () => {
    const deviceToken = localStorage.getItem('deviceToken');

    apiManager
      .post('/auth/logout', { memberId: member?.id, deviceToken: deviceToken })
      .then((response) => {
        localStorage.removeItem('token');
        localStorage.removeItem('driveTutorial');
        localStorage.removeItem('driveTutorialReceivedCheck');
        removeMember();
        navigate('/more');
        window.location.reload();
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const onClickLogout = () => {
    setIsShow(true);
    setPopupProps({
      ...popupProps,
      isShow: true,
      title: '로그아웃',
      textContent: '정말 로그아웃 하시겠어요?',
      textLeftBtn: '닫기',
      onClickLeftBtn: close,
      textRightBtn: '로그아웃',
      onClickRightBtn: () => logout(),
    });
  };

  return (
    <>
      <MenuHeader title="설정" path="/more"></MenuHeader>
      <div className="w-full">
        <div className="pt-[60px]">
          <div className="flex items-center bg-gray-2 jusify-between p-4 mx-4 rounded-lg z-10">
            <div className="flex-1">
              <Link to="/member/name">
                <span className="flex items-center w-full">
                  <p className="overflow-hidden text-gray-9 font-semibold text-[24px] mr-2 leading-[28px]">
                    {member?.name}
                  </p>
                  <PencilIcon color={colors.gray[8]} width={16} height={16}></PencilIcon>
                </span>
              </Link>
              <p className="text-base text-gray-6 leading-[20px]">
                {member?.email ? member.email : member?.phoneNumber}
              </p>
            </div>
            <div className="flex-initial">
              <ProfileImage></ProfileImage>
            </div>
          </div>
          <hr className="border-4 border-gray-1 mt-6 mb-3"></hr>

          <ul className="px-4">
            <li className="list-none py-5">
              <Link to="/member/phone-number" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">휴대폰 번호 변경</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>

            {!member?.email && (
              <li className="list-none py-5">
                <Link to="/member/password" className="flex items-center cursor-pointer">
                  <p className="flex-1 font-semibold text-gray-8">비밀번호 변경</p>
                  <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
                </Link>
              </li>
            )}

            <li className="list-none py-5">
              <Link to="/setting/notification" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">알림 설정</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>
          </ul>

          <hr className="border-4 border-gray-1 my-3"></hr>
          <ul className="px-4">
            <li className="list-none py-5">
              <Link to="/terms" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">서비스 이용약관 확인</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>
          </ul>
          <hr className="border-4 border-gray-1 my-3"></hr>
          <ul className="px-4">
            <li className="list-none cursor-pointer" onClick={onClickLogout}>
              <p className="leading-[52px] font-semibold">로그아웃</p>
            </li>
          </ul>
          <div className="px-4 py-6">
            <p className="text-xl font-semibold">기타</p>
            <div className="pt-2 font-base">
              현재 버전 <span className="text-gray-6">1.0.0</span>
            </div>
            <div />
          </div>
        </div>

        <BasicPopup
          isShow={isShow}
          title={popupProps.title}
          textContent={popupProps.textContent}
          textLeftBtn={popupProps.textLeftBtn}
          onClickLeftBtn={popupProps.onClickLeftBtn}
          textRightBtn={popupProps.textRightBtn}
          onClickRightBtn={popupProps.onClickRightBtn}
        ></BasicPopup>
      </div>
    </>
  );
};

export default SettingPage;
