import React from 'react';

import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import SimilarProductList from '@/components/Products/SimilarProductList';

interface ProductProps {
  productOriginData?: ProductDetailResponse | undefined;
  productSearchParams: ProductSearchParams;
}

const PriceTrendResult: React.FC<ProductProps> = ({ productSearchParams, productOriginData }) => {
  return (
    <>
      <div className="rounded-lg bg-blue-0 p-4 mt-6">
        <p className="text-lg font-bold mb-2">
          {productOriginData ? productOriginData.year : productSearchParams.year}년{' '}
          {productOriginData
            ? productOriginData.manufacturerCategories?.name
            : productSearchParams.manufacturerCategories?.name}{' '}
          {productSearchParams?.model?.name} {productSearchParams?.tons}톤 {productSearchParams?.axis?.desc}{' '}
          {productSearchParams?.loaded?.desc}
        </p>
        <span className="text-gray-8 text-sm">해당 차종의 평균 시세 정보를 불러왔어요.</span>
      </div>
      <div className="mt-3">
        <p className="text-xs text-gray-6">* 차량의 주행거리, 차량의 상태, 옵션등에 따라 시세가 변동될 수 있습니다.</p>
        <CarPriceTrendInfo
          priceSearchParams={productSearchParams}
          showPriceComparison={false}
          isSearchPage={true}
        ></CarPriceTrendInfo>
        <SimilarProductList similarSearchParams={productSearchParams}></SimilarProductList>
      </div>
      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-4 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white shadow-[0_-2px_4px_rgba(0,0,0,0.05)] z-40">
        <ButtonFooter title="내 차 판매하러가기" path="/products/sales"></ButtonFooter>
      </div>
    </>
  );
};

export default PriceTrendResult;
