import React from 'react';

interface FilledBadgeProps {
  text: string;
  textColor: string;
  bgColor: string;
  rounded: string;
  fontWeight?: string;
}

const FilledBadge = ({ text, textColor, bgColor, rounded, fontWeight }: FilledBadgeProps) => {
  const getBgColor = () => {
    switch (bgColor) {
      case 'primary':
        return 'bg-primary';
      case 'red':
        return 'bg-red';
      case 'green':
        return 'bg-green';
      default:
        return bgColor;
    }
  };

  return (
    <span
      className={`text-center w-auto text-sm px-2 py-1 break-keep ${rounded} ${fontWeight} ${textColor} ${getBgColor()}`}
    >
      {text}
    </span>
  );
};

export default FilledBadge;
