import { useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import SplashComponent from '@/components/Common/SplashComponent';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useChatContext } from '@/contexts/Chat/ChatContext';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useWebSocket from '@/hooks/useWebSocket';
import { isExistNotReadChatMessageAtom } from '@/store/chatting';
import { isExistNotReadNotificationAtom } from '@/store/notification';

const MainLayout = () => {
  const setIsExistNotReadNotification = useSetAtom(isExistNotReadNotificationAtom);
  const setIsExistNotReadChatMessage = useSetAtom(isExistNotReadChatMessageAtom);

  const [showSplash, setShowSplash] = useState<boolean>();
  const { member, login, getMember } = useMemberContext();
  const id = member?.id;
  const { setProductEnum } = useProductEnumContext();
  const { setLicenseEnum } = useLicenseEnumContext();
  const { setUnreadMessages, setChattingList, setNewChatMessage } = useChatContext();
  const { showToast } = useToastContext();

  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isNaverInApp = /NAVER/.test(userAgent);
    const isKakaoInApp = /KAKAOTALK/.test(userAgent);

    const isAndroidWeb =
      /Android/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      (!/wv/i.test(userAgent) || isNaverInApp || isKakaoInApp);

    if (isAndroidWeb && sessionStorage.getItem('isFirst') !== 'false') {
      window.location.replace('intent://www.zigtruck.io/#Intent;package=com.zigtruck.android;scheme=https;end');
    }
    sessionStorage.setItem('isFirst', String(false));
  }, []);

  const getProductEnum = () => {
    apiManager
      .get('/api/v1/public/products/enum')
      .then((response) => {
        const responseData: ProductEnum = response.data;
        setProductEnum(response.data);
      })
      .catch(() => showToast(COMMON_TOAST_ERROR, 'error', 'bottom'));
  };

  const getLicenseEnum = () => {
    apiManager
      .get('/api/v1/public/license/filter-info')
      .then((response) => {
        const responseData: LicenseEnum = response.data;
        setLicenseEnum(response.data);
      })
      .catch(() => showToast(COMMON_TOAST_ERROR, 'error', 'bottom'));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const hasSessionStarted = sessionStorage.getItem('hasSessionStarted');
    if (token && getMember) {
      const decodedToken: Token = jwtDecode(token);
      const parse = JSON.parse(decodedToken.details.toString());
      getMember(parse['memberId']);
    }

    if (!hasSessionStarted) {
      setShowSplash(true);
      sessionStorage.setItem('hasSessionStarted', 'true');
      setTimeout(() => {
        setShowSplash(false);
      }, 1000);
    }

    getProductEnum();
    getLicenseEnum();
  }, []);

  const socket = useWebSocket(`/sub/members/${id}`, {
    onMessage: (message) => {
      try {
        const body = JSON.parse(message?.body);
        if (body.changeType === 'NEW_CHAT_MESSAGES') {
          const messageData: NewChatMessage = body.data;
          setNewChatMessage(messageData);
          updateChattingList(messageData);
          setUnreadMessages((prev) => ({
            ...prev,
            [body.data.chatRoomId]: (prev[body.data.chatRoomId] || 0) + 1,
          }));
          setIsExistNotReadChatMessage(true);
        } else if (body.changeType === 'INTEREST_PRODUCT_NOTIFICATION' || body.changeType === 'ACTIVITY_NOTIFICATION') {
          setIsExistNotReadNotification(true);
        }
      } catch (error) {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const updateChattingList = (data: NewChatMessage) => {
    setChattingList((prevList) => {
      // chatRoomId가 같은 항목이 이미 존재하는지 확인
      const existingItemIndex = prevList.findIndex((item) => item.chatRoomId === data.chatRoomId);

      if (existingItemIndex !== -1) {
        // chatRoomId가 같은 항목이 존재하면 업데이트
        return prevList.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, lastMessage: data.contents, lastMessageTime: data.createdDate };
          } else {
            return item;
          }
        });
      } else {
        // chatRoomId가 같은 항목이 존재하지 않으면 추가
        const newData: ChattingListResponse = {
          chatRoomId: data.chatRoomId,
          lastMessage: data.contents,
          lastMessageTime: data.createdDate,
          memberName: data.memberName,
          profileImageUrl: data.profileImageUrl,
          productRepresentImageUrl: data.frontSideImageUrl,
          truckNumber: data.truckNumber,
          productId: 0,
          truckName: '',
        };
        return [...prevList, newData];
      }
    });
  };

  return (
    <div className="flex mx-auto max-w-[720px] min-w-[280px]">
      {showSplash ? <SplashComponent></SplashComponent> : <Outlet />}
    </div>
  );
};

export default MainLayout;
