import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/Common/ScrollToTop';
import MainLayout from './components/Layout/MainLayout';
import LicenseForm from './components/License/LicenseForm';
import OneStopServiceRequest from './components/Products/Sale/OneStopServiceRequest';
import { ChatProvider } from './contexts/Chat/ChatContext';
import { ToastProvider } from './contexts/Common/ToastContext';
import { LicenseEnumProvider } from './contexts/License/LicenseEnumContext';
import { MemberProvider } from './contexts/Member/MemberContext';
import { PriceSearchFormProvider } from './contexts/Price/PriceSearchFormContext';
import { ProductEnumProvider } from './contexts/Products/ProductEnumContext';
import { initializeFirebase } from './firebase/firebase';
import ChattingList from './pages/Chat/ChattingList';
import ChattingRoom from './pages/Chat/ChattingRoom';
import Contract from './pages/Contract/Contract';
import DriveHome from './pages/Drive/DriveHome';
import DriveVehicleSetting from './pages/Drive/DriveVehicleSetting';
import FuelForm from './pages/Drive/FuelForm';
import FuelList from './pages/Drive/FuelList';
import OutstandingAmountList from './pages/Drive/OutstandingAmountList';
import RepairForm from './pages/Drive/RepairForm';
import RepairList from './pages/Drive/RepairList';
import NotFoundPage from './pages/Error/NotFoundPage';
import CarGuide from './pages/Guide/CarGuide';
import PerchaseGuide from './pages/Guide/PerchaseGuide';
import SaleGuide from './pages/Guide/SaleGuide';
import Home from './pages/Home/Home';
import JobDetail from './pages/Job/JobDetail';
import JobList from './pages/Job/JobList';
import JobSearch from './pages/Job/JobSearch';
import License from './pages/License/License';
import LicensePurchasingInquiry from './pages/License/LicensePurchasingInquiry';
import LicenseSearch from './pages/License/LicenseSearch';
import ForgotPassword from './pages/Login/ForgotPassword';
import Login from './pages/Login/Login';
import LoginHandler from './pages/Login/LoginHandler';
import MorePageMain from './pages/More/More';
import InterestProductsList from './pages/More/My/InterestProducts';
import MyLicense from './pages/More/My/MyLicense';
import MyProducts from './pages/More/My/MyProducts';
import NameChange from './pages/More/NameChange';
import NotificationSettings from './pages/More/NotificationSettings';
import PasswordChange from './pages/More/PasswordChange';
import PhoneNumberChange from './pages/More/PhoneNumberChange';
import PurchaseAccompanyingService from './pages/More/PurchaseAccompanyingService';
import SettingPage from './pages/More/Settings';
import Terms from './pages/More/Terms';
import TermsDetail from './pages/More/TermsDetail';
import TransferAgencyService from './pages/More/TransferAgencyService';
import NotificationList from './pages/Notification/NotificationList';
import NotificationProductList from './pages/Notification/NotificationProductList';
import NotificationProductsSettings from './pages/Notification/NotificationProductsSettings';
import PriceTrendSearch from './pages/PriceTrend/PriceTrendSearch';
import PriceTrendSearchFormPage from './pages/PriceTrend/PriceTrendSearchFormPage';
import PriceTrendSearchResultPage from './pages/PriceTrend/PriceTrendSearchResultPage';
import ProductDetail from './pages/Products/Purchase/ProductDetail';
import ProductsPurchasingInquiry from './pages/Products/Purchase/ProductPurchasingInquiry';
import ProductsList from './pages/Products/Purchase/ProductsList';
import ProductsSearch from './pages/Products/Purchase/ProductsSearch';
import MyCarDetailForm from './pages/Products/Sale/MyCarDetailForm';
import MyCarSale from './pages/Products/Sale/MyCarSale';
import AdditionalInfoForm from './pages/Products/Sale/Register/AdditionalForm';
import AxisForm from './pages/Products/Sale/Register/AxisForm';
import DetailInfoForm from './pages/Products/Sale/Register/DetailForm';
import LoadedForm from './pages/Products/Sale/Register/LoadedForm';
import ModelForm from './pages/Products/Sale/Register/ModelForm';
import PhotoUploadForm from './pages/Products/Sale/Register/PhotoUploadForm';
import PriceInputForm from './pages/Products/Sale/Register/PriceForm';
import PriceTrendResultPage from './pages/Products/Sale/Register/PriceTrendResult';
import ProductsOriginDataInfo from './pages/Products/Sale/Register/ProductsOriginDataInfo';
import TonsForm from './pages/Products/Sale/Register/TonsForm';
import SimilarProducts from './pages/Products/SimilarProducts';
import SignUp from './pages/SignUp/SignUp';
import './utils/removeConsole';
import { HJID, HJSV } from '@/const/hotjar';
import '@/css/globalStyle.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

// APP
const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: '42px',
          borderRadius: 8,
          padding: '10px 16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: '#1e40af',
          height: '50px',
          color: '#fff',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#1e40af',
            boxShadow: 'none',
          },
          '&:active': { boxShadow: 'none' },
        },
      },
    },
  },
});

type ProvidersProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ToastProvider>
      <MemberProvider>
        <ProductEnumProvider>
          <LicenseEnumProvider>
            <PriceSearchFormProvider>
              <ChatProvider>{children}</ChatProvider>
            </PriceSearchFormProvider>
          </LicenseEnumProvider>
        </ProductEnumProvider>
      </MemberProvider>
    </ToastProvider>
  </QueryClientProvider>
);

const ProtectedRoute: React.FC<ProvidersProps> = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};
const environment = process.env.NODE_ENV || 'production';
Sentry.init({
  dsn: 'https://f2d5ff6b6acbf833e482a87e5a466075@o4506674661425152.ingest.sentry.io/4506674663129088',
  release: 'release version',
  environment: environment,
  normalizeDepth: 6,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({ console: true }),
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://dev.zigtruck.io', 'https://www.zigtruck.io'],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0, // 전체 이벤트 중 추적할 비율 (0.0 ~ 1.0)
});

// firebase setting
initializeFirebase();

const App = () => {
  const token = localStorage.getItem('token');

  // useEffect(() => {
  //   function preventGlobalDragAndRightClick(event: React.DragEvent | MouseEvent) {
  //     event.preventDefault();
  //   }
  //   document.addEventListener('contextmenu', preventGlobalDragAndRightClick, false);
  //   return () => {
  //     document.removeEventListener('contextmenu', preventGlobalDragAndRightClick, false);
  //   };
  // }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize({ id: HJID, sv: HJSV });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Providers>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/login" element={token ? <Home /> : <Login />} />
              <Route path="/login/callback" element={<LoginHandler />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Home />} />
              <Route path="/products/purchase" element={<ProductsPurchasingInquiry />} />
              <Route
                path="/notification"
                element={
                  <ProtectedRoute>
                    <NotificationList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notification/products"
                element={
                  <ProtectedRoute>
                    <NotificationProductList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notification/products/form"
                element={
                  <ProtectedRoute>
                    <NotificationProductsSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notification/products/form/:id"
                element={
                  <ProtectedRoute>
                    <NotificationProductsSettings />
                  </ProtectedRoute>
                }
              />
              <Route path="/products" element={<ProductsList />} />
              <Route path="/products/search" element={<ProductsSearch />} />
              <Route path="/products/:id" element={<ProductDetail />} />
              <Route path="/products/similar" element={<SimilarProducts />} />
              <Route
                path="/products/sales"
                element={
                  <ProtectedRoute>
                    <MyCarSale />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/info/:id"
                element={
                  <ProtectedRoute>
                    <ProductsOriginDataInfo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/model/:id"
                element={
                  <ProtectedRoute>
                    <ModelForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/tons/:id"
                element={
                  <ProtectedRoute>
                    <TonsForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/loaded/:id"
                element={
                  <ProtectedRoute>
                    <LoadedForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/axis/:id"
                element={
                  <ProtectedRoute>
                    <AxisForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/price-trend/:id"
                element={
                  <ProtectedRoute>
                    <PriceTrendResultPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/additional-info/:id"
                element={
                  <ProtectedRoute>
                    <AdditionalInfoForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/detail-info/:id"
                element={
                  <ProtectedRoute>
                    <DetailInfoForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/photo/:id"
                element={
                  <ProtectedRoute>
                    <PhotoUploadForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/price/:id"
                element={
                  <ProtectedRoute>
                    <PriceInputForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/sales/edit/:id"
                element={
                  <ProtectedRoute>
                    <MyCarDetailForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/one-stop-service"
                element={
                  <ProtectedRoute>
                    <OneStopServiceRequest />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/purchase-accompanying-service"
                element={
                  <ProtectedRoute>
                    <PurchaseAccompanyingService></PurchaseAccompanyingService>
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/transfer-agency-service"
                element={
                  <ProtectedRoute>
                    <TransferAgencyService></TransferAgencyService>
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/price-trend"
                element={
                  <ProtectedRoute>
                    <PriceTrendSearch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/price-trend/form"
                element={
                  <ProtectedRoute>
                    <PriceTrendSearchFormPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/price-trend/result"
                element={
                  <ProtectedRoute>
                    <PriceTrendSearchResultPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chatting"
                element={
                  <ProtectedRoute>
                    <ChattingList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chatting/room/:chatRoomId"
                element={
                  <ProtectedRoute>
                    <ChattingRoom />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chatting/room"
                element={
                  <ProtectedRoute>
                    <ChattingRoom />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contract"
                element={
                  <ProtectedRoute>
                    <Contract />
                  </ProtectedRoute>
                }
              />
              <Route path="/guide" element={<CarGuide />} />
              <Route path="/guide/perchase" element={<PerchaseGuide />} />
              <Route path="/guide/sale" element={<SaleGuide />} />
              <Route path="/license" element={<License />} />
              <Route path="/license/search" element={<LicenseSearch />} />
              <Route
                path="/license/purchase"
                element={
                  <ProtectedRoute>
                    <LicensePurchasingInquiry />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/license/form/:id"
                element={
                  <ProtectedRoute>
                    <LicenseForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/license/form"
                element={
                  <ProtectedRoute>
                    <LicenseForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products/my"
                element={
                  <ProtectedRoute>
                    <MyProducts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/license/my"
                element={
                  <ProtectedRoute>
                    <MyLicense />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/interest"
                element={
                  <ProtectedRoute>
                    <InterestProductsList />
                  </ProtectedRoute>
                }
              />
              <Route path="/more" element={<MorePageMain />} />
              <Route path="/setting" element={<SettingPage />} />
              <Route
                path="/member/phone-number"
                element={
                  <ProtectedRoute>
                    <PhoneNumberChange />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/member/name"
                element={
                  <ProtectedRoute>
                    <NameChange />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/member/password"
                element={
                  <ProtectedRoute>
                    <PasswordChange />
                  </ProtectedRoute>
                }
              />
              <Route path="/terms" element={<Terms />} />
              <Route path="/terms/:type" element={<TermsDetail />} />
              <Route
                path="/setting/notification"
                element={
                  <ProtectedRoute>
                    <NotificationSettings />
                  </ProtectedRoute>
                }
              />
              <Route path="/job" element={<JobList />}></Route>
              <Route path="/job/:id" element={<JobDetail />}></Route>
              <Route path="/job/search" element={<JobSearch />}></Route>

              <Route
                path="/drive"
                element={
                  <ProtectedRoute>
                    <DriveHome />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/vehicle"
                element={
                  <ProtectedRoute>
                    <DriveVehicleSetting />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/fuel"
                element={
                  <ProtectedRoute>
                    <FuelList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/fuel/:id"
                element={
                  <ProtectedRoute>
                    <FuelForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/fuel/form"
                element={
                  <ProtectedRoute>
                    <FuelForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/repair"
                element={
                  <ProtectedRoute>
                    <RepairList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/repair/:id"
                element={
                  <ProtectedRoute>
                    <RepairForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/repair/form"
                element={
                  <ProtectedRoute>
                    <RepairForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drive/outstanding-amount"
                element={
                  <ProtectedRoute>
                    <OutstandingAmountList />
                  </ProtectedRoute>
                }
              />
              <Route path="/*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Providers>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement || document.createElement('div'));
root.render(<App />);
