import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductStatus from '@/components/More/ProductStatus';
import ProductType from '@/components/More/ProductType';
import ProductTypeChangeButton from '@/components/More/ProductTypeChangeButton';
import MyProductPrice from '@/components/Products/MyProductPrice';
import { colors } from '@/const/colors';
import { PAGE_NAMES } from '@/const/common';
import { getPageName } from '@/utils/Products/productUtils';
import ClearIcon from '@mui/icons-material/Clear';

interface DraftListingProps {
  data: ProductDetailResponse;
  onClickDelete: (item: ProductDetailResponse) => void;
  updateList: () => void;
}

const DraftListingBox = ({ data, onClickDelete, updateList }: DraftListingProps) => {
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(0);

  const pageNames = {
    model: 1,
    tons: 2,
    loaded: 3,
    axis: 4,
    'price-trend': 5,
    'additional-info': 6,
    'detail-info': 7,
    photo: 8,
    price: 9,
  };

  useEffect(() => {
    const pageName = getPageName(data);
    setPageNum(PAGE_NAMES[pageName]);
  }, [data]);

  const handleContinueClick = () => {
    const pageName = getPageName(data);
    const prefixPath = '/products/sales';
    navigate(`${prefixPath}/${pageName}/${data.id}`);
  };

  return (
    <>
      <li key={data.id} className="flex flex-col px-4 py-4 bg-gray-3 rounded-lg my-2 relative">
        <div className="flex justify-between flex-1 mb-3">
          <div className="flex w-[100px] h-[100px] rounded-lg">
            <img
              className="rounded-lg w-full h-full object-cover bg-cover"
              src={
                data.productsImage?.frontSideImageUrl
                  ? data.productsImage.frontSideImageUrl
                  : 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/car_none.png'
              }
              alt="트럭 사진"
            />
          </div>
          <div className="flex-1 ml-3">
            <div className="flex flex-wrap gap-[6px] mr-6">
              <ProductStatus status={data.status}></ProductStatus>
              <ProductType type={data.type?.code === 'SPEED' ? data.statusOfSpeedProduct : data.type}></ProductType>
            </div>
            <dd className="text-base pt-2 text-gray-8">{data.truckNumber}</dd>
            <dd className="flex items-center">
              <span className="font-semibold text-gray-9 text-lg">
                <MyProductPrice product={data}></MyProductPrice>
              </span>
            </dd>
            <ProductTypeChangeButton
              id={data.id}
              type={data.type}
              status={data.status}
              setProductList={updateList}
              statusOfSpeedProduct={data.statusOfSpeedProduct}
            ></ProductTypeChangeButton>
          </div>
        </div>
        <div className="flex justify-between pt-2 border-t border-gray-4 text-gray-7 text-sm">
          <p>{pageNum - 1}/9 완료</p>
          <p className="cursor-pointer" onClick={handleContinueClick}>
            이어서 등록하기
          </p>
        </div>
        <ClearIcon
          className="absolute top-4 right-4 cursor-pointer"
          sx={{ color: colors.gray[7] }}
          onClick={() => onClickDelete(data)}
        ></ClearIcon>
      </li>
    </>
  );
};

export default DraftListingBox;
