import React, { useEffect, useState } from 'react';

import { COUNSELING, COUNSEL_COMPLTED, REQUEST } from '@/const/license';
import { BEFORE_SALE, COMPLETED, DELETED, ORIGIN_DATA_REGISTER, PAUSE, SALE } from '@/const/productStatus';

interface ProductStatusProps {
  status: EnumPresenter | undefined;
}

export default function ProductStatus({ status }: ProductStatusProps) {
  const [color, setColor] = useState<string | undefined>('gray');
  const [bg, setBg] = useState<string | undefined>('bg-gray-1');
  const productStatus = [
    { code: [ORIGIN_DATA_REGISTER, BEFORE_SALE], desc: '임시저장', color: 'text-gray-8', bg: 'bg-gray-1' },
    { code: [COMPLETED], desc: '판매완료', color: 'text-gray-6', bg: 'bg-gray-1' },
    { code: [COUNSEL_COMPLTED], desc: '상담완료', color: 'text-gray-6', bg: 'bg-gray-3' },
    { code: [SALE], desc: '판매중', color: 'text-blue-3', bg: 'bg-gray-1' },
    { code: [PAUSE], desc: '판매중지', color: 'text-red', bg: 'bg-gray-1' },
    { code: [REQUEST], desc: '상담요청', color: 'text-green', bg: 'bg-gray-1' },
    { code: [COUNSELING], desc: '상담중', color: 'text-green', bg: 'bg-gray-1' },
    { code: [DELETED], desc: '삭제됨', color: 'text-gray-6', bg: 'bg-gray-1' },
  ];

  useEffect(() => {
    if (status) {
      const statusData = productStatus.find((item) => item.code.includes(status?.code));
      setColor(statusData?.color);
      setBg(statusData?.bg);
    }
  }, [status]);

  return (
    <span className={`text-[14px] leading-[18px] rounded-[8px] bg-gray-1 font-semibold py-1 px-2 ${bg} ${color}`}>
      {status?.desc}
    </span>
  );
}
