import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicPopup from '@/components/Common/Popup/BasicPopup';

export default function LoginHandler() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const deviceToken = localStorage.getItem('deviceToken');
  const [cookies, setCookie, removeCookie] = useCookies(['redirect_url']);

  const [isShow, setIsShow] = useState(false);
  const onClickPopupBtn = () => {
    setIsShow(false);
    navigate('/login');
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);

      if (deviceToken) {
        apiManager
          .post('/api/v1/fire-base/device-token', { deviceToken: deviceToken })
          .then((response) => {})
          .catch((error) => {});
      }

      if (cookies && cookies.redirect_url) {
        navigate(cookies.redirect_url);
        removeCookie(cookies.redirect_url);
      } else {
        navigate('/');
      }
    } else {
      setIsShow(true);
    }
  }, []);

  return (
    <>
      <BasicPopup
        isShow={isShow}
        title="문제가 생겼어요."
        textContent={
          '현재 선택하신 SNS 계정으로 <br>로그인에 실패했습니다.<br>다른 계정으로 시도해보세요.<br>계속해서 문제가 발생하면<br>고객센터로 문의해주세요.'
        }
        textRightBtn="확인"
        onClickRightBtn={onClickPopupBtn}
      ></BasicPopup>
    </>
  );
}
