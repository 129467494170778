import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { patchProductsStatus } from '@/api/public';
import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import Footer from '@/components/Footer';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import ChangeHistory from '@/components/Products/Sale/DetailTab/ChangeHistory';
import TradingHistory from '@/components/Products/Sale/DetailTab/TradingHistory';
import VehicleInspectionHistory from '@/components/Products/Sale/DetailTab/VehicleInspectionHistory';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { productsFormAtom } from '@/store/products';
import { asYYYYMMDD, formatShortYear, getFormatDate } from '@/utils/common';

const ProductsOriginDataInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabNum, setTabNum] = useState(0);
  const { id } = useParams();
  const { showToast } = useToastContext();
  const productOriginData = location.state.productOriginData;
  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const labelList: TabType[] = [
    { icon: null, title: '거래이력' },
    { icon: null, title: '검사이력' },
    { icon: null, title: '변경이력' },
  ];
  const buttonList: Button[] = [
    {
      title: '필수 정보 입력하기',
      isDisabled: false,
      onClick: () => onClickMovePage(),
      description: '차량 시세를 확인하기 위한 정보를 입력해주세요.',
    },
  ];

  const goPrev = () => {
    navigate('/products/sales');
  };

  const patchProductsStatusMutation = useMutation(
    (request: { productId: number; status: string }) => patchProductsStatus(request),
    {
      onSuccess: () => {
        navigate(`/products/sales/model/${id}`, {
          state: { productOriginData: productOriginData },
          replace: true,
        });
      },
      onError: () => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      },
    },
  );

  const onClickMovePage = () => {
    const request = {
      productId: Number(productOriginData?.id),
      status: 'BEFORE_SALE',
    };
    patchProductsStatusMutation.mutate(request);
  };

  useEffect(() => {
    if (productFormData.id === 0) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          if (responseData.manufacturerCategories.id === 10) {
            setProductFormData({ ...responseData, model: { id: 45, name: '기타(쌍용 외)' } });
          } else {
            setProductFormData(responseData);
          }
        })
        .catch(() => {});
    }
  }, [productFormData]);

  return (
    <>
      <div className="flex flex-col justify-between w-full">
        <MenuHeader title="내차판매" onClickPrevBtn={goPrev}></MenuHeader>
        <div className="">
          <div className="pb-24 pt-[60px]">
            <h2 className="text-2xl font-bold text-gray-8 px-4">{productOriginData?.ownerName}님의 차량정보</h2>
            <div className="pb-5 px-4">
              <div className="bg-yellow inline-block w-auto rounded-lg border-2 border-black p-3 my-6">
                <div className="flex items-center justify-center font-semibold text-3xl">
                  {productOriginData?.truckNumber}
                </div>
              </div>
              <div className="table w-full bg-gray-1 rounded-lg p-4">
                <h4 className="text-xl font-semibold mb-4 text-gray-8">차량 기본 정보</h4>
                <dl className="text-lg xxs:text-sm xs:text-base">
                  {productOriginData?.truckName && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">차량명</dt>
                      <dd className="table-cell font-semibold text-gray-8">{productOriginData.truckName}</dd>
                    </div>
                  )}
                  {productOriginData?.carType && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">차종</dt>
                      <dd className="table-cell font-semibold text-gray-8">{productOriginData.carType}</dd>
                    </div>
                  )}
                  {productOriginData?.manufacturerCategories && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">제조사</dt>
                      <dd className="table-cell font-semibold text-gray-8">
                        {productOriginData.manufacturerCategories?.name}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.year && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">형식</dt>
                      <dd className="table-cell font-semibold text-gray-8">
                        {formatShortYear(productOriginData.year)}년형
                      </dd>
                    </div>
                  )}
                  {productOriginData?.firstRegistrationDate && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-5 pb-4 xxs:pr-0">연식</dt>
                      <dd className="table-cell font-semibold text-gray-8">
                        {getFormatDate(productOriginData.firstRegistrationDate, ['year', 'month'], true)}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.color && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-4 pb-5 xxs:pr-0">색상</dt>
                      <dd className="table-cell font-semibold text-gray-8">{productOriginData.color.desc}</dd>
                    </div>
                  )}
                  {productOriginData?.inspectionInvalidStartDate && productOriginData?.inspectionInvalidEndDate && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">
                        검사
                        <br />
                        유효기간
                      </dt>
                      <dd className="table-cell font-semibold text-gray-8">
                        {asYYYYMMDD(productOriginData?.inspectionInvalidStartDate)} ~<br />
                        {asYYYYMMDD(productOriginData?.inspectionInvalidEndDate)}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.identificationNumber && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">차대번호</dt>
                      <dd className="table-cell font-semibold text-gray-8">
                        {productOriginData?.identificationNumber}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.carUse && (
                    <div className="table-row">
                      <dt className="table-cell text-gray-7 pr-3 pb-4 xxs:pr-0">차량용도</dt>
                      <dd className="table-cell font-semibold text-gray-8">{productOriginData?.carUse}</dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
            {productOriginData?.lastOwnerInfo && productOriginData?.lastOwnerInfo.date && (
              <section className="pt-2 pb-4 my-2 text-gray-8 px-4">
                <h3 className="text-lg font-semibold pb-3">소유자 정보 (현출물자 이력)</h3>
                <dl className="text-base bg-gray-1 rounded-lg p-4">
                  <dt className="font-semibold mb-1">{productOriginData?.lastOwnerInfo.date}</dt>
                  <dd>{productOriginData?.lastOwnerInfo.content}</dd>
                </dl>
              </section>
            )}
            <BasicTabs labelList={labelList} value={tabNum} onChange={setTabNum}>
              <TradingHistory productOriginData={productOriginData}></TradingHistory>
              <VehicleInspectionHistory productOriginData={productOriginData}></VehicleInspectionHistory>
              <ChangeHistory productOriginData={productOriginData}></ChangeHistory>
            </BasicTabs>

            <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-2 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-gray-0">
              <ButtonFooter buttons={buttonList}></ButtonFooter>
            </div>
          </div>
        </div>
        <Footer isMain={false}></Footer>
      </div>
    </>
  );
};

export default ProductsOriginDataInfo;
