import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { colors } from '@/const/colors';
import RadioCheckIcon from '@/components/Icon/RadioCheckIcon';
import RadioIcon from '@/components/Icon/RadioIcon';

export interface OptionType {
  code: string;
  desc: string;
  count?: number;
}

interface RadioListProps {
  name: string;
  list: OptionType[];
  value: string;
  onChange: (val: OptionType) => void;
  horizontal?: boolean;
  justify?: 'between' | 'equal';
}

const RadioList = ({ name, list, value, onChange, horizontal = true, justify = 'between' }: RadioListProps) => {
  return (
    <RadioGroup
      value={value}
      row={horizontal}
      name={`radio-buttons-group ${name}`}
      sx={{ justifyContent: justify === 'between' ? 'space-between' : 'normal' }}
    >
      {list.map((item, index) => (
        <FormControlLabel
          key={index}
          value={item.code}
          control={
            <Radio
              icon={<RadioIcon color={colors.gray[3]} />}
              checkedIcon={<RadioCheckIcon color={colors.primary} />}
              sx={{
                borderWidth: '4px',
                fontSize: '14px',
                borderRadius: '10px',
                paddingRight: '6px',
              }}
            />
          }
          checked={item.code === value}
          label={
            <>
              <span className="text-gray-9">{item.desc}</span>
              {!!item.count && (
                <span>
                  <span className="text-gray-5">({item.count})</span>
                </span>
              )}
            </>
          }
          sx={{ flex: justify === 'equal' ? '1' : 'none' }}
          onChange={() => onChange(item)}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioList;
